import { Avatar } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { XButton } from "../../../../../components/xemelgo-button";
import { pluralizeWord } from "../../../../../common/Utilities";
import "./style.css";

export const PartnerGroupHeader = ({ groupName, resourceCount, onAddClick }) => {
  const onClickCallback = (event) => {
    if (!event) {
      return;
    }

    event.stopPropagation();
    onAddClick();
  };

  const AddAvatar = (
    <Avatar className="partner-add-avatar">
      <Add />
    </Avatar>
  );

  return (
    <div className="group-header">
      <div className="group-header-label">
        <div className="group-name">{groupName}</div>
        <div className="resource-count">
          {`${resourceCount} ${resourceCount === 1 ? "resource" : pluralizeWord("resource")}`}
        </div>
      </div>
      <div className="group-operation">
        <XButton
          label={AddAvatar}
          onClick={onClickCallback}
        />
      </div>
    </div>
  );
};

PartnerGroupHeader.propTypes = {
  groupName: PropTypes.string.isRequired,
  resourceCount: PropTypes.number.isRequired,
  onAddClick: PropTypes.func.isRequired
};
