import AddButton from "../features/add-button";
import BulkUpdateButton from "../features/bulk-update-button";
import ResetItemsButton from "../features/reset-items-button";
import DeleteItemTypesButton from "../features/delete-item-types-button";
import UpdateTransferOrdersButton from "../features/update-transfer-orders-button";
import AddTransferOrderButton from "../features/add-transfer-order-button";
import BulkUpdateItemAssociationButton from "../features/bulk-update-item-association-button";

export const FEATURE_BUTTON_ID_MAP = {
  addItem: "add-item",
  bulkUpdate: "bulk-update",
  resetItems: "reset-items",
  deleteItemTypes: "delete-item-types",
  updateTransferOrders: "update-transfer-orders",
  addTransferOrder: "add-transfer-order",
  updateItemAssociation: "update-item-association"
};

export const FEATURE_BUTTON_COMPONENT_MAP = {
  [FEATURE_BUTTON_ID_MAP.addItem]: AddButton,
  [FEATURE_BUTTON_ID_MAP.bulkUpdate]: BulkUpdateButton,
  [FEATURE_BUTTON_ID_MAP.resetItems]: ResetItemsButton,
  [FEATURE_BUTTON_ID_MAP.deleteItemTypes]: DeleteItemTypesButton,
  [FEATURE_BUTTON_ID_MAP.updateTransferOrders]: UpdateTransferOrdersButton,
  [FEATURE_BUTTON_ID_MAP.addTransferOrder]: AddTransferOrderButton,
  [FEATURE_BUTTON_ID_MAP.updateItemAssociation]: BulkUpdateItemAssociationButton
};
