import { useState } from "react";
import { useStore } from "react-hookstore";
import Amplify from "aws-amplify";
import { ClientProvider, XemelgoClient } from "@xemelgo/x-client";
import { userProfileStore } from "../state-managements/stores/user-profile-store";
import CONFIGURATION from "../configuration.json";
import { configStore } from "../state-managements/stores/config-store";

export const useXemelgoClient = () => {
  const [client, setClient] = useState<XemelgoClient | null>(null);
  const [userConfig] = useStore(configStore);
  const [userProfile] = useStore(userProfileStore);

  // TODO: apiGateway is the name of service from old customer configuration. Keeping it here for backward compatibility support
  const services = ["xGraph", "xMain", "apiGateway"];
  // returns cached client if exists
  if (client) {
    return client;
  }

  let apiVersion = null;
  const endpoints = services
    .filter((serviceName) => {
      return userConfig?.getServiceConfiguration(serviceName);
    })
    .map((serviceName) => {
      const config = userConfig?.getServiceConfiguration(serviceName);
      const { name, endpoint, region, apiVersion: version } = config;

      if (version) {
        apiVersion = version;
      }

      return {
        name,
        endpoint,
        region
      };
    });

  const clientConfig = {
    endpoints,
    apiVersion: apiVersion || "v2",
    auth: CONFIGURATION.AuthConfiguration,
    customerId: userProfile?.getTenantId(),
    partnerId: userProfile?.getPartnerId()
  };
  const xemelgoClient = ClientProvider.getClient(Amplify.API, clientConfig);
  setClient(xemelgoClient);
  return xemelgoClient;
};
