const NO_END_TIME = 32503680000000;
const INITIAL_PACKAGE_STATUS = "created";

const getItemsFromTransferOrder = (order) => {
  const { hasItemsEntry = [] } = order || {};
  const { hasTransferItemState = [] } = hasItemsEntry[0] || {};
  return hasTransferItemState.reduce((accumulator, eachState) => {
    const { ofItem } = eachState;
    const result = [...accumulator, ...ofItem];
    return result;
  }, []);
};

export const getDetailScreenData = (orderData, orderAttributes) => {
  const { hasSensorProfile, ofType, ...restAttributes } = orderData;
  const {
    edge_end_time,
    edge_start_time,
    "associatedWithSession#node": associatedWithSession = []
  } = orderData?.["associatedWithSession#edge"]?.sort((a, b) => {
    return b.edge_end_time - a.edge_end_time;
  })[0] || {};

  const { vid = "" } = hasSensorProfile?.[0] || {};
  const { id: itemTypeId = "", itemTypeIdentifier = "" } = ofType?.[0] || {};
  const {
    lastDetectedLocation = [],
    node_end_time: lastDetectedTime,
    node_start_time: firstDetectedTime
  } = associatedWithSession?.[0] || {};

  const { id: lastDetectedLocationId = "", name: lastDetectedLocationName = "" } = lastDetectedLocation?.[0] || {};
  const itemData = {
    ...restAttributes,
    vid,
    itemTypeId,
    itemTypeIdentifier,
    lastDetectedLocationId,
    lastDetectedLocationName,
    lastDetectedTime,
    firstDetectedTime,
    packageCount: getItemsFromTransferOrder(orderData).length,
    edge_end_time: edge_end_time === NO_END_TIME ? null : edge_end_time,
    edge_start_time
  };

  return orderAttributes.reduce((accumulator, current) => {
    const { id, isHeader, subText } = current;
    accumulator[id] = {
      ...current,
      primary: isHeader,
      value: itemData[id],
      subText: itemData[subText]
    };

    return accumulator;
  }, {});
};

export const getPackageData = (orderData, packageStateToDisplayedStatusMap) => {
  const items = getItemsFromTransferOrder(orderData);
  const result = items.reduce((accumulator, eachItem) => {
    const { hasSensorProfile, hasProcessState, ofType, ...restAttributes } = eachItem;
    const { state = INITIAL_PACKAGE_STATUS } = hasProcessState[0] || {};
    const {
      edge_end_time,
      edge_start_time,
      "associatedWithSession#node": associatedWithSession = []
    } = eachItem?.["associatedWithSession#edge"]?.sort((a, b) => {
      return b.edge_end_time - a.edge_end_time;
    })[0] || {};

    const { vid = "" } = hasSensorProfile?.[0] || {};
    const { id: itemTypeId = "", itemTypeIdentifier = "" } = ofType?.[0] || {};
    const {
      node_end_time: lastDetectedTime,
      node_start_time: firstDetectedTime,
      node_last_updated_time: lastUpdatedTime
    } = associatedWithSession?.[0] || {};

    const itemData = {
      ...restAttributes,
      vid,
      itemTypeId,
      itemTypeIdentifier,
      lastDetectedTime,
      firstDetectedTime,
      lastUpdatedTime,
      status: packageStateToDisplayedStatusMap[state],
      edge_end_time: edge_end_time === NO_END_TIME ? null : edge_end_time,
      edge_start_time
    };

    accumulator.push(itemData);
    return accumulator;
  }, []);
  return result;
};

export const getOverallOrderStatusData = (orderData, packageStateToDisplayedStatusMap) => {
  const items = getItemsFromTransferOrder(orderData);
  const packageStateToItemCountMap = items.reduce((accumulator, eachItem) => {
    const { hasProcessState = [] } = eachItem;
    const { state = INITIAL_PACKAGE_STATUS } = hasProcessState[0] || [];
    const { label = "" } = packageStateToDisplayedStatusMap[state] || {};
    if (!accumulator[label]) {
      accumulator[label] = 0;
    }
    accumulator[label] += 1;
    return accumulator;
  }, {});

  const result = Object.keys(packageStateToItemCountMap).reduce((accumulator, eachState) => {
    accumulator.push({
      status: eachState,
      packageCount: packageStateToItemCountMap[eachState]
    });
    return accumulator;
  }, []);

  return result;
};

export const getItemKeyForS3 = (url) => {
  if (!url) {
    return "";
  }

  const index = url.search(/.com/);
  return url
    .substr(index + 5)
    .replaceAll("+", " ")
    .replaceAll("%20", " ");
};
