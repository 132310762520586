import React, { useState, useMemo } from "react";
import Style from "./DisplayColumnControl.module.css";
import ColumnSelectModal from "../../../../../../../../components/column-select-modal";
import useTransferOrderTrackPageConfigContext from "../../../../../../contexts/transfer-order-track-page-config-context";
import useTransferOrderTrackPageStateContext from "../../../../../../contexts/transfer-order-track-page-state-context";
import { ReactComponent as FilterIcon } from "../../../../../../../../assets/icons/filter.svg";

export const DisplayColumnControl = () => {
  const [showColumnsSettingModal, setShowColumnsSettingModal] = useState(false);

  const { displayedTableHeadersMap, setDisplayedTableHeadersMap, displayedTableHeadersMapDefault } =
    useTransferOrderTrackPageStateContext();

  const { listTableControl } = useTransferOrderTrackPageConfigContext();

  const { selectedViewTypeId } = useTransferOrderTrackPageStateContext();

  const availableHeaders = useMemo(() => {
    return (displayedTableHeadersMapDefault?.transferOrder?.[selectedViewTypeId] || []).map((eachHeaderId) => {
      return listTableControl.transferOrder.headers.find(({ id }) => {
        return id === eachHeaderId;
      });
    });
  }, [listTableControl, selectedViewTypeId, displayedTableHeadersMapDefault]);

  const selectedHeaders = useMemo(() => {
    return (displayedTableHeadersMap?.transferOrder?.[selectedViewTypeId] || []).map((eachHeaderId) => {
      return listTableControl.transferOrder.headers.find(({ id }) => {
        return id === eachHeaderId;
      });
    });
  }, [listTableControl, selectedViewTypeId, displayedTableHeadersMap]);

  return (
    <div className={Style.container}>
      <button
        type="button"
        className={Style.button}
        onClick={() => {
          setShowColumnsSettingModal((currentValue) => {
            return !currentValue;
          });
        }}
      >
        <FilterIcon className={Style.button_icon} />
        Columns
      </button>
      <ColumnSelectModal
        currentColumns={selectedHeaders}
        columnOptions={availableHeaders}
        show={showColumnsSettingModal}
        onCancel={() => {
          setShowColumnsSettingModal(false);
        }}
        onConfirm={(newHeaders) => {
          const currentValue = displayedTableHeadersMap || displayedTableHeadersMapDefault;
          const newDisplayedTableHeadersMap = {
            ...currentValue,
            transferOrder: {
              ...currentValue.transferOrder,
              [selectedViewTypeId]: newHeaders.map(({ id }) => {
                return id;
              })
            }
          };

          setDisplayedTableHeadersMap(newDisplayedTableHeadersMap);
          setShowColumnsSettingModal(false);
        }}
      />
    </div>
  );
};
