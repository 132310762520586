import { MOUNTED_READER_ACTION, MOUNTED_READER_ACTION_STEPS } from "../../constants/mixpanel-constant/mountedReader";
import { RETRY_DELAY_MS } from "./data/constants";

export const callEdgeOperation = async (params) => {
  const {
    edgeApiUrl,
    deviceId,
    operation,
    abortSignal,
    tenantId,
    maxRetries = 0,
    sendLogEvent = () => {},
    payload
  } = params;

  const { operationName, method, isTenantSpecific, pathSegment } = operation;

  if (!operationName || !method || !pathSegment) {
    throw new Error(`Invalid operation configuration: ${operation}`);
  }

  const requestOptions = {
    method,
    headers: {},
    body: payload ? JSON.stringify(payload) : undefined,
    signal: abortSignal
  };

  let url = edgeApiUrl;

  if (isTenantSpecific) {
    url += `/poc/tenants/${tenantId}`;
  }

  url += `/devices/${deviceId}/${pathSegment}/${operationName}`;

  let tryCount = 0;
  let response;

  do {
    if (tryCount > 0) {
      await new Promise((resolve) => {
        return setTimeout(resolve, RETRY_DELAY_MS);
      });
    }
    response = await fetch(url, requestOptions);
    tryCount++;
  } while (tryCount <= maxRetries && response.status !== 200);

  sendLogEvent(
    MOUNTED_READER_ACTION,
    response?.status === 200
      ? MOUNTED_READER_ACTION_STEPS.ACTION_SUCCESSFUL
      : MOUNTED_READER_ACTION_STEPS.ACTION_FAILED,
    {
      operation: operation.operationName,
      url,
      deviceId
    }
  );

  return response;
};
