import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import useDetailsPageConfigContext from "../details-page-config-context";
import { DetailsCardData } from "../../features/details-card/data/types";
import { STATUS_OPTIONS } from "../../../../components/status-popup-component";
import useDetailsPageSearchParams from "../../hooks/use-details-page-search-params";
import { DetailsPageStateContextProviderProps, DetailsPageStateContextType } from "./data/types";
import useMixpanelContext from "../../../../context/mixpanel-context";
import { DETAILS_PAGE_EVENT, DETAILS_PAGE_EVENT_STEPS } from "../../../../constants/mixpanel-constant/detailsPage";

const defaultState: DetailsPageStateContextType = {
  id: "",
  isLoading: true,
  setIsLoading: () => {},
  featureMap: {},
  detailsCardData: null,
  refreshDetails: () => {
    return Promise.resolve();
  },
  popupStatus: STATUS_OPTIONS.NONE,
  setPopupStatus: () => {},
  popupMessage: "",
  setPopupMessage: () => {},
  selectedTabLabel: "",
  setSelectedTabLabel: () => {},
  showErrorScreen: false
};

const DetailsPageStateContext = createContext<DetailsPageStateContextType>(defaultState);

export const useDetailsPageStateContext = () => {
  return useContext(DetailsPageStateContext);
};

export const DetailsPageStateContextProvider = ({
  children,
  solutionFeatureMap,
  onQueryDetails,
  path
}: DetailsPageStateContextProviderProps) => {
  const { sendMixPanelEvent } = useMixpanelContext();
  const { additionalAttributes, detailsCard } = useDetailsPageConfigContext();
  const { id, areSearchParamsReady, selectedTabLabel, setSelectedTabLabel } = useDetailsPageSearchParams(path);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [detailsCardData, setDetailsCardData] = useState<DetailsCardData | null>(defaultState.detailsCardData);
  const [popupStatus, setPopupStatus] = useState<string>(defaultState.popupStatus);
  const [popupMessage, setPopupMessage] = useState<string>(defaultState.popupMessage);
  const [showErrorScreen, setShowErrorScreen] = useState<boolean>(false);

  const featureMap = useMemo(() => {
    return {
      ...solutionFeatureMap
      // TODO: add default features
    };
  }, [solutionFeatureMap]);

  useEffect(() => {
    if (id && areSearchParamsReady) {
      queryDetails();
    }
    if (!id && areSearchParamsReady) {
      sendMixPanelEvent(DETAILS_PAGE_EVENT, DETAILS_PAGE_EVENT_STEPS.MISSING_ID);
      setShowErrorScreen(true);
    }
  }, [id, areSearchParamsReady]);

  const queryDetails = async () => {
    setIsLoading(true);

    try {
      setDetailsCardData(await onQueryDetails(id, additionalAttributes, detailsCard));
      setIsLoading(false);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : typeof error === "string" ? error : "Unknown error";
      sendMixPanelEvent(DETAILS_PAGE_EVENT, DETAILS_PAGE_EVENT_STEPS.QUERY_ERROR, {
        error_message: errorMessage
      });
      setShowErrorScreen(true);
      setIsLoading(false);
    }
  };

  return (
    <DetailsPageStateContext.Provider
      value={{
        id,
        isLoading,
        setIsLoading,
        featureMap,
        detailsCardData,
        refreshDetails: queryDetails,
        popupStatus,
        setPopupStatus,
        popupMessage,
        setPopupMessage,
        selectedTabLabel,
        setSelectedTabLabel,
        showErrorScreen
      }}
    >
      {children}
    </DetailsPageStateContext.Provider>
  );
};
