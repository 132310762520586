import { API, graphqlOperation } from "aws-amplify";
import { ClientProvider } from "@xemelgo/x-client";
import CONFIGURATION from "../configuration.json";
import { LocalCacheService } from "./local-cache-service";

export const XemelgoService = {
  getClient() {
    const userConfig = LocalCacheService.loadUserConfig();
    const userProfile = LocalCacheService.loadUserProfile();
    // TODO: apiGateway is the name of service from old customer configuration. Keeping it here for backward compatibility support
    const services = ["xGraph", "xMain", "apiGateway", "appSync"];
    let apiVersion = null;
    const endpoints = services
      .filter((serviceName) => {
        return userConfig.getServiceConfiguration(serviceName);
      })
      .map((serviceName) => {
        const config = userConfig.getServiceConfiguration(serviceName);
        const { name, endpoint, region, apiVersion: version } = config;

        if (version) {
          apiVersion = version;
        }

        return {
          name,
          endpoint,
          region
        };
      });

    const clientConfig = {
      endpoints,
      apiVersion: apiVersion || "v2",
      auth: CONFIGURATION.AuthConfiguration,
      customerId: userProfile.tenantId,
      partnerId: userProfile.partnerId
    };
    return ClientProvider.getClient(API, clientConfig);
  },

  getAppsyncClient() {
    const userConfig = LocalCacheService.loadUserConfig();
    const userProfile = LocalCacheService.loadUserProfile();

    const services = ["appSync"];
    let apiVersion = null;
    const endpoints = services
      .filter((serviceName) => {
        return userConfig.getServiceConfiguration(serviceName);
      })
      .map((serviceName) => {
        const config = userConfig.getServiceConfiguration(serviceName);
        const { name, endpoint, region, apiVersion: version } = config;

        if (version) {
          apiVersion = version;
        }

        return {
          name,
          endpoint,
          region
        };
      });

    const clientConfig = {
      endpoints,
      apiVersion: apiVersion || "v2",
      auth: CONFIGURATION.AuthConfiguration,
      customerId: userProfile.tenantId,
      partnerId: userProfile.partnerId
    };
    return ClientProvider.getClient(
      API,
      clientConfig,
      userProfile?.getIdToken(),
      () => {
        return userProfile?.refreshIdToken();
      },
      graphqlOperation
    );
  }
};
