import type { Header } from "../../../../../components/paginated-list-table-with-types";
import { VALUE_TYPE_MAP } from "../../../../../components/paginated-list-table-with-types/data/constants";

export const DEFAULT_TRANSFER_ORDER_ITEMS_TABLE_HEADERS: Header[] = [
  {
    id: "identifier",
    label: "Serial #",
    type: VALUE_TYPE_MAP.text,
    isNavLink: true
  },
  {
    id: "itemType_identifier",
    label: "Part #",
    type: VALUE_TYPE_MAP.text,
    isNavLink: true
  },
  {
    id: "location_identifier",
    label: "Location",
    type: VALUE_TYPE_MAP.text
  },
  {
    id: "status",
    label: "Status",
    type: VALUE_TYPE_MAP.status
  },
  {
    id: "node_last_updated_time",
    label: "Last Updated",
    type: VALUE_TYPE_MAP.date,
    timeFormat: "MM/DD/YYYY"
  }
];
