import { XemelgoService } from "../../../../services/XemelgoService";
import { OnboardedKioskItem } from "../../data/types";

export const queryItemsFromBasicTags = async (
  newTags: { vid: string }[],
  additionalQueryAttributes: Record<string, string[]> = {},
  itemClassFilters: string[] = []
): Promise<Record<string, OnboardedKioskItem>> => {
  const inventoryClient = XemelgoService.getClient().getInventoryClient();
  const { itemType: itemTypeFields = [], item: itemFields = [] } = additionalQueryAttributes;
  const tags = [
    ...new Set(
      newTags.map((tag) => {
        return tag.vid;
      })
    )
  ];
  if (!newTags.length) {
    return Promise.resolve({});
  }

  const results = await inventoryClient.getInventoryVerificationDataByVids(
    tags,
    itemFields,
    itemTypeFields,
    itemClassFilters
  );

  if (!results) {
    return Promise.resolve({});
  }

  const firstSeenTimestamp = Date.now();
  const newItemByTag: Record<string, OnboardedKioskItem> = results
    .map((raw) => {
      const trackingSession = raw.associatedWithTrackingSession?.[0];
      const item = trackingSession?.tracksItem?.[0] || {};
      const { ofType = [], associatedWithTransferItemState = [] } = item || {};

      const { id: itemTypeId, identifier = "", description, ...tenantProperties } = ofType[0];
      const type = {
        id: itemTypeId,
        identifier,
        description,
        ...tenantProperties
      };

      const transferOrder = associatedWithTransferItemState?.[0]?.ofItemsEntry?.[0]?.ofTransferOrder?.[0];

      return {
        ...item,
        itemTypeId,
        vid: raw.vid,
        sensorProfileId: raw.id,
        itemId: item?.id,
        trackingSessionId: trackingSession?.id,
        associatedWithTransferOrder: transferOrder,
        type,
        firstSeenTimestamp
      };
    })
    .filter((item) => {
      return item.itemId && item.type;
    })
    .reduce((map, item) => {
      const clonedMap = { ...map };
      clonedMap[item.vid] = item;
      return clonedMap;
    }, {});

  return newItemByTag;
};
