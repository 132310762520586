import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useStore } from "react-hookstore";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import { ListLocations } from "../../features/list-locations";
import { RoutableView } from "../../components/routable-view";
import { TwoColumnsPaneView } from "../../components/two-columns-pane-view";
import { ListDetectors } from "../../features/list-detectors";
import SideNavigationBar from "../../features/side-navigation-bar/SideNavigationBar";
import { userProfileStore } from "../../state-managements/stores/user-profile-store";
import { LocalCacheService } from "../../services/local-cache-service";
import { ListPartners } from "../../features/list-partners/ListPartners";
import { ReactComponent as FacilityIcon } from "../../assets/icons/my-facility.svg";
import ScreenFrame from "../../components/screen-frame";
import xemelgoStyle from "../../styles/variable";
import "./style.css";
import useMixpanelContext from "../../context/mixpanel-context";
import { MY_FACILITY_APP_EVENT, MY_FACILITY_APP_EVENT_STEPS } from "../../constants/mixpanel-constant/myFacilityApp";

const AppId = "myFacility";
const TabMap = {
  partners: {
    displayName: "Customers",
    href: "/my-facility/partners",
    component: <ListPartners appId={AppId} />
  },
  locations: {
    displayName: "Locations",
    href: "/my-facility/locations",
    component: <ListLocations appId={AppId} />
  },
  detectors: {
    displayName: "Detectors",
    href: "/my-facility/detectors",
    component: <ListDetectors appId={AppId} />
  }
};

export const MyFacility = withRouter(({ history }) => {
  const [tabs, setTabs] = useState([]);
  const [defaultTabId, setDefaultTabId] = useState(history.location.pathname.split("/")[2]);
  const configProvider = useAppConfigProvider(AppId);
  const { sendMixPanelEvent } = useMixpanelContext();

  const [userProfile] = useStore(userProfileStore);
  useEffect(() => {
    sendMixPanelEvent(MY_FACILITY_APP_EVENT, MY_FACILITY_APP_EVENT_STEPS.ENTRY);
  }, []);

  useEffect(() => {
    if (!history.location.pathname.split("/")[2] && tabs.length) {
      history.push(tabs[0].href);
    }
  });

  useEffect(() => {
    const tabsFromConfig = configProvider.getValue("tabs", "array", ["partners", "locations", "detectors"]);

    let isCancelled = false;
    const tabInfoList = tabsFromConfig
      .filter((tabId) => {
        return TabMap[tabId];
      })
      .map((tabId) => {
        const tabInfo = TabMap[tabId];
        const { href, displayName, component } = tabInfo;
        return {
          id: tabId,
          href,
          action: () => {
            history.push(`${href}`);
          },
          title: displayName,
          component
        };
      });

    if (!isCancelled) {
      setTabs(tabInfoList);
      if (!defaultTabId) {
        setDefaultTabId(tabInfoList[0].id);

        if (!history.location.pathname.split("/")[2]) {
          history.replace(`/my-facility/${tabInfoList[0].id}`);
        }
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [history, defaultTabId, configProvider]);

  if (!userProfile.isUserSuperAdmin()) {
    const landingPage = LocalCacheService.loadUserConfig().getLandingPage();
    history.push(landingPage);
  }

  return (
    <ScreenFrame
      title="My Facility"
      color={xemelgoStyle.theme.XEMELGO_BLUE}
      secondaryColor={xemelgoStyle.theme.XEMELGO_LIGHTBLUE}
      titleIconComponent={() => {
        return (
          <FacilityIcon
            width={25}
            height={25}
            style={{ color: xemelgoStyle.theme.XEMELGO_BLUE }}
          />
        );
      }}
    >
      <TwoColumnsPaneView
        className="my-facility-app"
        leftPane={
          <SideNavigationBar
            tabStructure={{ groups: [{ items: tabs }] }}
            defaultTab={defaultTabId}
          />
        }
        rightPane={<RoutableView partialViews={tabs} />}
      />
    </ScreenFrame>
  );
});
