import React, { useState } from "react";
import Style from "../../FeatureButtons.module.css";
import BulkUpdateItemAssociationModal from "../../../../../../../item-association-feature/features/bulk-update-item-association-modal/BulkUpdateItemAssociationModal";

type BulkUpdateItemAssociationButtonProps = {
  label?: string;
};

export const BulkUpdateItemAssociationButton: React.FC<BulkUpdateItemAssociationButtonProps> = ({
  label = "Update Item Associations"
}) => {
  const [showBulkUpdateItemAssociationModal, setShowBulkUpdateItemAssociationModal] = useState(false);

  return (
    <div>
      <button
        type="button"
        className={Style.button}
        onClick={() => {
          setShowBulkUpdateItemAssociationModal(true);
        }}
      >
        {label}
      </button>

      {showBulkUpdateItemAssociationModal && (
        <BulkUpdateItemAssociationModal
          solutionId="inventory"
          onClose={() => {
            setShowBulkUpdateItemAssociationModal(false);
          }}
        />
      )}
    </div>
  );
};
