import React, { useState } from "react";
import PropTypes from "prop-types";
import "./style.css";
import { LocationRecord } from "./location-record";
import { ListedItem } from "../../../../components/collapsible-list-view/listed-item";

export const LocationRowsWithOperations = ({
  locations,
  appId,
  featureId,
  onSelectLocation,
  isChildSelected,
  onLocationRecordChanged,
  onFilterInputChange
}) => {
  const [selectedLocationId, setSelectedLocationId] = useState(null);

  const onSelectLocationCallback = (location) => {
    const locationId = location.getId();
    setSelectedLocationId(locationId);
    onSelectLocation(location);
  };

  const handleFilterInputChange = ({ currentTarget }) => {
    const updatedFilterInput = currentTarget.value || "";
    if (onFilterInputChange) {
      onFilterInputChange(updatedFilterInput);
    }
  };

  return (
    <div>
      <div className="filter_bar_container">
        <span className="fa fa-search filter_bar_icon icon_offset" />
        <input
          title="Filter Input Field"
          placeholder="Type to Filter"
          onChange={handleFilterInputChange}
          className="filter_bar"
        />
      </div>
      {locations.length > 0 &&
        locations.map((location) => {
          const locationId = location.getId();
          const styleClassName = `location-row-with-operations ${
            selectedLocationId === locationId ? "location-selected" : ""
          }`;

          return (
            <ListedItem
              className={styleClassName}
              content={
                <LocationRecord
                  location={location}
                  appId={appId}
                  featureId={featureId}
                  onRecordUpdated={() => {
                    return onLocationRecordChanged(locationId);
                  }}
                />
              }
              key={location.getId()}
              onClickCallback={() => {
                return onSelectLocationCallback(location);
              }}
            />
          );
        })}
    </div>
  );
};

LocationRowsWithOperations.defaultProps = {
  locations: [],
  onSelectLocation: () => {},
  isChildSelected: false,
  onLocationRecordChanged: () => {},
  onFilterInputChange: null
};

LocationRowsWithOperations.propTypes = {
  configuration: PropTypes.shape({
    showOperations: PropTypes.bool
  }),
  appId: PropTypes.string.isRequired,
  featureId: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      getName: PropTypes.func,
      getId: PropTypes.func
    }).isRequired
  ),
  onSelectLocation: PropTypes.func,
  isChildSelected: PropTypes.bool,
  onLocationRecordChanged: PropTypes.func,
  onFilterInputChange: PropTypes.func
};
