import React, { useState, useCallback } from "react";
import Modal from "../../components/modal";
import Style from "./MarkAsCompletedActionModal.module.css";
import { useXemelgoClient } from "../../services/xemelgo-service";
import Spinner from "../../components/spinner";

interface MarkAsCompletedActionModalProps {
  title?: string;
  body?: string | React.ReactNode;
  itemIds: string[];
  onCloseModal?: () => void;
  onPreConfirm?: () => void;
  onPostConfirm?: (error?: Error) => void;
  detachSensorProfiles?: boolean;
}

export const MarkAsCompletedActionModal: React.FC<MarkAsCompletedActionModalProps> = ({
  title = "Mark as Completed",
  body = "You are about to complete the selected item(s).",
  itemIds = [],
  onCloseModal = () => {},
  onPostConfirm = () => {},
  onPreConfirm = () => {},
  detachSensorProfiles = false
}) => {
  const xemelgoClient: any = useXemelgoClient();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onConfirm = useCallback(async () => {
    onPreConfirm();
    setIsSubmitting(true);
    const publishClient = xemelgoClient?.getPublishClient();
    try {
      if (!publishClient) {
        throw new Error("Publish client is not available");
      }
      await publishClient.publishUserEvent(itemIds, null, {
        actions: {
          endTrackingSession: {
            detachSensorProfiles
          }
        }
      });
      onPostConfirm();
    } catch (error) {
      onPostConfirm(error as Error);
    }
    setIsSubmitting(false);
  }, [xemelgoClient, itemIds, detachSensorProfiles, onPreConfirm, onPostConfirm]);

  return (
    <Modal
      title={title}
      onCancel={onCloseModal}
      onConfirm={onConfirm}
      confirmDisabled={isSubmitting}
      closeOnClickOutside={false}
    >
      <div className={Style.body_container}>
        {isSubmitting ? (
          <div className={Style.spinner_container}>
            <Spinner />
            <p>Processing, please wait...</p>
          </div>
        ) : (
          <div>{body}</div>
        )}
      </div>
    </Modal>
  );
};
