import React from "react";
import { useHistory, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Style from "../../FeatureButtons.module.css";

export const BulkUpdateButton = ({ label }) => {
  const history = useHistory();

  return (
    <Link
      className={Style.button}
      to={`${history.location.pathname}/bulk-update`}
    >
      {label}
    </Link>
  );
};

BulkUpdateButton.defaultProps = {
  label: "Bulk Update"
};

BulkUpdateButton.propTypes = {
  label: PropTypes.string
};
