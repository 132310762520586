import React, { useState, useEffect, useCallback, useMemo } from "react";
import { XemelgoClient, ItemTypeV2, ItemV2 } from "@xemelgo/x-client";
import Modal from "../../components/modal";
import Style from "./DeleteItemTypesActionModal.module.css";
import { useXemelgoClient } from "../../services/xemelgo-service";
import Spinner from "../../components/spinner";
import FreeTextSearchInput from "../../components/free-text-search-input";
import CheckboxLabel from "../../components/checkbox-label";

interface DisplayedItemType {
  displayName: string;
  id: string;
  class: string;
  identifier: string;
  name: string | null;
  description: string | null;
  imagePath: string | null;
  customFields: any;
  items: ItemV2[] | null;
}

interface DeleteItemTypesActionModalProps {
  title?: string;
  itemTypeClass?: string;
  itemClasses?: string[];
  onCloseModal?: () => void;
  onPreConfirm?: () => void;
  onPostConfirm?: (error?: Error) => void;
}

export const DeleteItemTypesActionModal: React.FC<DeleteItemTypesActionModalProps> = ({
  title = "Delete Item Types",
  itemTypeClass = undefined,
  itemClasses = [],
  onCloseModal = () => {},
  onPostConfirm = () => {},
  onPreConfirm = () => {}
}) => {
  const xemelgoClient: XemelgoClient | null = useXemelgoClient();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [freeTextSearchInputString, setFreeTextSearchInputString] = useState<string>("");
  const [selectedItemTypes, setSelectedItemTypes] = useState<DisplayedItemType[]>([]);

  const [itemTypeList, setItemTypeList] = useState<ItemTypeV2[]>([]);

  const onLoad = async () => {
    setIsLoading(true);
    const itemTypeClient = xemelgoClient.getItemTypeClient();
    const newItemTypeLists = await itemTypeClient.listItemTypesAndItems(itemTypeClass, itemClasses);
    setItemTypeList(newItemTypeLists);
    setIsLoading(false);
  };

  useEffect(() => {
    onLoad();
  }, [itemTypeClass, itemClasses]);

  const onConfirm = useCallback(async () => {
    const publishClient = xemelgoClient?.getPublishClient();
    const sensorProfileClient = xemelgoClient?.getSensorProfileClient();
    const itemClient = xemelgoClient?.getItemClient();
    const itemTypeClient = xemelgoClient?.getItemTypeClient();

    onPreConfirm();
    setIsSubmitting(true);

    const trackingSessionIds: string[] = [];
    const itemTypeIds: string[] = [];
    const itemIds: string[] = [];
    const sensorProfileIds: string[] = [];

    selectedItemTypes.forEach(({ id: itemTypeId, items }) => {
      itemTypeIds.push(itemTypeId);
      if (items?.length) {
        items.forEach(({ id: itemId, data: { associatedWithSession, hasSensorProfile } }) => {
          itemIds.push(itemId);
          if (associatedWithSession?.length) {
            trackingSessionIds.push(associatedWithSession[0].id);
          }
          if (hasSensorProfile?.length) {
            sensorProfileIds.push(hasSensorProfile[0].id);
          }
        });
      }
    });

    try {
      while (trackingSessionIds.length) {
        const trackingSessionIdsPartial = trackingSessionIds.splice(0, 40);
        await publishClient.userEvent(trackingSessionIdsPartial, null, { actions: { endTrackingSession: true } });
      }

      for (const spId of sensorProfileIds) {
        await sensorProfileClient.removeSensorProfile(spId, false);
      }

      for (const itemId of itemIds) {
        await itemClient.removeItem(itemId, false);
      }

      for (const itemTypeId of itemTypeIds) {
        await itemTypeClient.removeItemType(itemTypeId, false);
      }
      setSelectedItemTypes([]);
      onPostConfirm();
    } catch (error) {
      onPostConfirm(error as Error);
    } finally {
      setIsSubmitting(false);
    }
  }, [xemelgoClient, onPreConfirm, onPostConfirm, selectedItemTypes]);

  const filteredItemTypeList = useMemo<DisplayedItemType[]>(() => {
    return itemTypeList
      .map((eachItemType) => {
        const { identifier = "", items = [] } = eachItemType;
        return {
          ...eachItemType,
          displayName: `${identifier} (${items?.length || 0} Units)`
        };
      })
      .filter((eachItemType) => {
        return (eachItemType?.displayName?.toLowerCase() || "").includes(
          freeTextSearchInputString?.toLowerCase() || ""
        );
      })
      .sort((a, b) => {
        return a.displayName.localeCompare(b.displayName);
      });
  }, [itemTypeList, freeTextSearchInputString]);

  return (
    <Modal
      title={title}
      onCancel={onCloseModal}
      onConfirm={onConfirm}
      confirmDisabled={isSubmitting || isLoading || selectedItemTypes.length === 0}
      closeOnClickOutside={false}
    >
      <div className={Style.body_container}>
        {isLoading || isSubmitting ? (
          <div className={Style.spinner_container}>
            <Spinner />
          </div>
        ) : (
          <>
            <p className={Style.body_heading}>Select the item type that you want to delete:</p>
            <FreeTextSearchInput
              value={freeTextSearchInputString}
              onChangeText={setFreeTextSearchInputString}
            />
            {filteredItemTypeList.length ? (
              <div className={Style.location_list_container}>
                <CheckboxLabel
                  containerClassName={Style.location_list_item}
                  id="select-all-option"
                  name="select-all-option"
                  isChecked={selectedItemTypes.length === filteredItemTypeList.length}
                  label="Select All"
                  onClick={() => {
                    if (selectedItemTypes.length === filteredItemTypeList.length) {
                      setSelectedItemTypes([]);
                    } else {
                      setSelectedItemTypes([...filteredItemTypeList]);
                    }
                  }}
                />
                {filteredItemTypeList.map((eachItemType: DisplayedItemType) => {
                  const { id, displayName } = eachItemType;

                  const isChecked = !!selectedItemTypes.find(({ id: eachId }) => {
                    return eachId === id;
                  });
                  return (
                    <CheckboxLabel
                      key={id}
                      containerClassName={Style.location_list_item}
                      id={id}
                      name={id}
                      isChecked={isChecked}
                      onClick={() => {
                        if (isChecked) {
                          setSelectedItemTypes((currentValue: DisplayedItemType[]) => {
                            return currentValue.filter(({ id: itemTypeId }: DisplayedItemType) => {
                              return itemTypeId !== id;
                            });
                          });
                        } else {
                          setSelectedItemTypes((currentValue: DisplayedItemType[]) => {
                            return [...currentValue, eachItemType];
                          });
                        }
                      }}
                      label={displayName}
                    />
                  );
                })}
              </div>
            ) : (
              <p>{` No Item Type found. `}</p>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};
