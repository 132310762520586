import AddButton from "../features/add-button";
import BulkUpdateItemAssociationButton from "../features/bulk-update-item-association-button";

export const FEATURE_BUTTON_ID_MAP = {
  addItem: "add-item",
  updateItemAssociation: "update-item-association"
};

export const FEATURE_BUTTON_COMPONENT_MAP = {
  [FEATURE_BUTTON_ID_MAP.addItem]: AddButton,
  [FEATURE_BUTTON_ID_MAP.updateItemAssociation]: BulkUpdateItemAssociationButton
};
