import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useStore } from "react-hookstore";
import LoginFormInput from "../../../components/login-form-input";
import PasswordInputFormWithValidation from "../../../features/password-form-with-input-validation/PasswordInputFormWithValidation";
import { loggingUserStore } from "../../../state-managements/stores/login-store";
import CONFIGURATION from "../../../configuration.json";
import {
  COGNITO_PASSWORD_REUSE_ERROR_MESSAGE,
  getPasswordReuseErrorMessge
} from "../../../features/password-form-with-input-validation/data/constant";
import Style from "./ForgotPasswordVerificationForm.module.css";
import xemelgoStyle from "../../../styles/variable";

const ForgotPasswordVerificationForm = ({ logo, poweredByLogo, onSubmit }) => {
  const [loggingUser] = useStore(loggingUserStore);
  const [errorMessage, setErrorMessage] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  const onVerificationCodeChange = (newVerificationCode) => {
    setVerificationCode(newVerificationCode);
  };

  const onSubmit_ = useCallback(
    async (newPassword_, passwordHistorySize) => {
      try {
        await onSubmit(loggingUser, verificationCode, newPassword_);
      } catch (error) {
        let errorMsg = error.message;
        if (errorMsg === COGNITO_PASSWORD_REUSE_ERROR_MESSAGE && passwordHistorySize > 0) {
          errorMsg = getPasswordReuseErrorMessge(passwordHistorySize);
        }
        setErrorMessage(errorMsg);
      }
    },
    [loggingUser, verificationCode, onSubmit]
  );

  return (
    <div
      className={
        CONFIGURATION.controlspan ? "content-body controlspan-login-page-body" : "content-body login-page-body"
      }
    >
      <div
        className="container login-form"
        style={{ textAlign: "initial" }}
      >
        <form className="login-form-background">
          <img
            src={logo}
            className={CONFIGURATION.controlspan ? "controlspan-brand-logo" : "brand-logo"}
            alt="brand-logo"
          />
          <div className="form-group">
            <p
              style={{
                textAlign: "center",
                color: "white",
                marginBottom: 20
              }}
            >
              Check your email and Please enter the verification code you received along with a new password.
            </p>
            <div className="error_message">{errorMessage}</div>
            <div className={Style.input_group}>
              <LoginFormInput
                secondaryColor={xemelgoStyle.theme.APP_WHITE}
                className="input-default"
                label="Verification Code"
                value={verificationCode}
                id="verificationCode"
                onChangeText={onVerificationCodeChange}
              />
              <PasswordInputFormWithValidation
                onSubmit={onSubmit_}
                submitButtonText="Submit"
                classOverrides={{
                  secondaryColor: xemelgoStyle.theme.APP_WHITE
                }}
              />
            </div>
          </div>
        </form>
        {poweredByLogo && (
          <img
            src={poweredByLogo}
            className="powered-by-logo"
            alt="powered-by-logo"
          />
        )}
      </div>
    </div>
  );
};

ForgotPasswordVerificationForm.propTypes = {
  logo: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export { ForgotPasswordVerificationForm };
