import React from "react";
import useTransferOrderTrackPageStateContext from "../../../../../../contexts/transfer-order-track-page-state-context";
import Style from "./ExportCsv.module.css";
import { ReactComponent as ExportIcon } from "../../../../../../../../assets/icons/export.svg";

export const ExportCsv = () => {
  const { exportCsvFn } = useTransferOrderTrackPageStateContext();
  return (
    <button
      className={Style.button}
      type="button"
      onClick={exportCsvFn}
    >
      <ExportIcon className={Style.button_icon} />
      Export
    </button>
  );
};
