import React, { useEffect, useState, useMemo } from "react";
import { ItemV2 } from "@xemelgo/x-client";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import useMixpanelContext from "../../../../context/mixpanel-context";
import {
  BULK_UPDATE_ITEM_ASSOCIATION,
  BULK_UPDATE_ITEM_ASSOCIATION_STEPS
} from "../../../../constants/mixpanel-constant/itemAssociation";
import { ItemAssociationConfigContextProvider, useItemAssociationConfigContext } from "../../contexts";
import useUpdateItemConnections from "../../hooks/use-update-item-connections";
import { ReactComponent as InventoryIcon } from "../../../../assets/icons/inventory.svg";
import OnboardItemModal from "../../../../components/onboard-item-modal";
import UploadCsvFeature from "../../../upload-csv-feature";
import StatusPopupComponent, { STATUS_OPTIONS } from "../../../../components/status-popup-component";
import Style from "./BulkUpdateItemAssociationModal.module.css";
import { pluralizeWord } from "../../../../common/Utilities";
import { FormSection } from "../../data/types";
import { OPPOSITE_DIRECTION_RELATIONSHIP_MAP } from "../../data/constants";

type CSVHeaderMap = {
  [id: string]: Omit<FormSection, "index" | "options" | "optionsControl">;
};

type CSVValue = {
  value: string;
  isError: boolean;
  errorMessage: string;
};

type CSVRow = {
  id: string;
  isError: boolean;
  rowNumber: number;
  data: Record<string, CSVValue>;
};

type BulkUpdateItemAssociationModalProps = {
  solutionId: string;
  onClose: () => void;
};

const RELATIONSHIP = "relationship";

export const BulkUpdateItemAssociationModal: React.FC<BulkUpdateItemAssociationModalProps> = ({
  solutionId,
  onClose
}) => {
  const { sendMixPanelEvent } = useMixpanelContext();

  useEffect(() => {
    sendMixPanelEvent(BULK_UPDATE_ITEM_ASSOCIATION, BULK_UPDATE_ITEM_ASSOCIATION_STEPS.ENTRY);
  }, []);

  const xemelgoClient = useXemelgoClient();
  const { itemLabel, relationshipMap, bulkUpdateItemAssociation } = useItemAssociationConfigContext();
  const { attachItems } = useUpdateItemConnections();

  const [submitStatus, setSubmitStatus] = useState<string>(STATUS_OPTIONS.NONE);
  const [statusMessage, setStatusMessage] = useState<string>("");
  const [csvDataMap, setCsvDataMap] = useState<Record<string, CSVRow>>({});
  const [identifierToIdMap, setIdentifierToIdMap] = useState<Record<string, string>>({});

  const relationshipOptionsMap = useMemo(() => {
    const relationshipField = bulkUpdateItemAssociation.find((field: FormSection) => {
      return field.id === RELATIONSHIP;
    });
    const { options = [] } = relationshipField || {};
    return options.reduce((accumulator: Record<string, string>, option: { label: string; value: string }) => {
      const { label, value } = option;
      accumulator[label] = value;
      return accumulator;
    }, {});
  }, [bulkUpdateItemAssociation]);

  const csvHeadersMap = useMemo(() => {
    return bulkUpdateItemAssociation.reduce((accumulator: CSVHeaderMap, formSection: FormSection) => {
      const { id, label, type, isRequired } = formSection;
      accumulator[id] = {
        id,
        label,
        type,
        isRequired
      };
      return accumulator;
    }, {});
  }, [bulkUpdateItemAssociation]);

  const verifyData = (csvData: Record<string, CSVRow>, items: ItemV2[] | null) => {
    const csvDataCopy = { ...csvData };
    const tempIdentifierToIdMap: Record<string, string> = {};
    if (items) {
      Object.keys(csvDataCopy).forEach((rowId: string) => {
        const { data } = csvDataCopy[rowId];
        const { fromItem: rowFromItem, toItem: rowToItem, relationship } = data;
        const { value: fromItemIdentifier } = rowFromItem;
        const { value: toItemIdentifier } = rowToItem;
        const { value: connectionLabel } = relationship;
        const connection = relationshipOptionsMap[connectionLabel];

        if (!connection) {
          csvDataCopy[rowId].isError = true;
          csvDataCopy[rowId].data.relationship.errorMessage = "Invalid connection";
          csvDataCopy[rowId].data.relationship.isError = true;
        } else if (fromItemIdentifier === toItemIdentifier) {
          csvDataCopy[rowId].isError = true;
          csvDataCopy[rowId].data.toItem.errorMessage = "Cannot associate item to itself";
          csvDataCopy[rowId].data.toItem.isError = true;
        } else {
          const { maxConnections: maxFromConnections } = relationshipMap[connection];
          const oppositeDirection = OPPOSITE_DIRECTION_RELATIONSHIP_MAP[connection];
          const { maxConnections: maxOppositeConnections, label: oppositeConnectionLabel } =
            relationshipMap[oppositeDirection];
          const fromItem = items?.find((item: ItemV2) => {
            return fromItemIdentifier === item.getIdentifier();
          });
          if (!fromItem) {
            csvDataCopy[rowId].isError = true;
            csvDataCopy[rowId].data.fromItem.errorMessage = "Could not find item";
            csvDataCopy[rowId].data.fromItem.isError = true;
          } else {
            tempIdentifierToIdMap[fromItemIdentifier] = fromItem?.getId();

            const rawItemData = fromItem?.getResponseData();
            const fromConnections = rawItemData[connection] || [];

            if (maxFromConnections && fromConnections.length >= maxFromConnections) {
              csvDataCopy[rowId].isError = true;
              csvDataCopy[rowId].data.fromItem.errorMessage = `Item has too many ${pluralizeWord(connectionLabel)}`;
              csvDataCopy[rowId].data.fromItem.isError = true;
            }
          }

          const toItem = items?.find((item: ItemV2) => {
            return toItemIdentifier === item.getIdentifier();
          });
          if (!toItem) {
            csvDataCopy[rowId].isError = true;
            csvDataCopy[rowId].data.toItem.errorMessage = "Could not find item";
            csvDataCopy[rowId].data.toItem.isError = true;
          } else {
            tempIdentifierToIdMap[toItemIdentifier] = toItem.getId();
            const rawItemData = toItem?.getResponseData();
            const toConnections = rawItemData[connection] || [];

            if (maxOppositeConnections && toConnections.length >= maxOppositeConnections) {
              csvDataCopy[rowId].isError = true;
              csvDataCopy[rowId].data.toItem.errorMessage = `Item has too many ${pluralizeWord(
                oppositeConnectionLabel
              )}`;
              csvDataCopy[rowId].data.toItem.isError = true;
            }
          }
        }
      });
    }

    setCsvDataMap(csvDataCopy);
    setIdentifierToIdMap(tempIdentifierToIdMap);
    setSubmitStatus(STATUS_OPTIONS.NONE);
    setStatusMessage("");
  };

  const onCsvDataChange = async (newCsvData: Record<string, CSVRow>) => {
    setSubmitStatus(STATUS_OPTIONS.LOADING);
    setStatusMessage("Loading...");
    const itemClient = xemelgoClient.getItemClient();

    const identifiers: string[] = [];
    Object.values(newCsvData).forEach((row: CSVRow) => {
      const { data } = row;
      const { fromItem, toItem } = data;
      if (fromItem?.value) {
        identifiers.push(fromItem.value);
      }

      if (toItem?.value) {
        identifiers.push(toItem.value);
      }
    });

    const items = await itemClient.getItemsByIdentifiers(
      [...new Set(identifiers)],
      undefined,
      undefined,
      false,
      Object.keys(relationshipMap)
    );
    verifyData(newCsvData, items);
  };

  const onSubmit = async () => {
    setSubmitStatus(STATUS_OPTIONS.LOADING);
    setStatusMessage("Loading...");
    try {
      for (const row of Object.values(csvDataMap)) {
        const { data } = row;
        const { fromItem, toItem, relationship } = data;
        const { value: connectionLabel } = relationship;
        const connection = relationshipOptionsMap[connectionLabel];

        await attachItems(identifierToIdMap[fromItem.value], identifierToIdMap[toItem.value], connection);
      }

      sendMixPanelEvent(BULK_UPDATE_ITEM_ASSOCIATION, BULK_UPDATE_ITEM_ASSOCIATION_STEPS.SUBMISSION_SUCCESSFUL);
      setCsvDataMap({});
      setSubmitStatus(STATUS_OPTIONS.SUCCESS);
      setStatusMessage("Successfully associated the items.");
      setTimeout(() => {
        setSubmitStatus(STATUS_OPTIONS.NONE);
        setStatusMessage("");
      }, 5000);
    } catch (e) {
      sendMixPanelEvent(BULK_UPDATE_ITEM_ASSOCIATION, BULK_UPDATE_ITEM_ASSOCIATION_STEPS.SUBMISSION_FAILED, {
        error_message: JSON.stringify(e)
      });
      setSubmitStatus(STATUS_OPTIONS.ERROR);
      setStatusMessage("Failed to associate items. Please contact Xemelgo Support for assistance");
    }
  };

  return (
    <OnboardItemModal
      title={`Update Associated ${itemLabel}(s)`}
      onSubmit={onSubmit}
      onClose={onClose}
      titleIconComponent={
        <div className={Style.title_icon}>
          <InventoryIcon
            width={25}
            height={25}
          />
        </div>
      }
      submitDisabled={submitStatus === STATUS_OPTIONS.LOADING}
      modalContainerClassName={Style.modal_container}
      popupComponent={
        <StatusPopupComponent
          showPopup={submitStatus !== STATUS_OPTIONS.NONE && !!statusMessage}
          message={statusMessage}
          status={submitStatus}
        />
      }
    >
      <div className={Style.upload_container}>
        <UploadCsvFeature
          csvHeadersMap={csvHeadersMap}
          solution={solutionId}
          csvDataMap={csvDataMap}
          onCsvDataChange={onCsvDataChange}
        />
      </div>
    </OnboardItemModal>
  );
};

export default ({ solutionId, onClose }: BulkUpdateItemAssociationModalProps) => {
  return (
    <ItemAssociationConfigContextProvider solutionId={solutionId}>
      <BulkUpdateItemAssociationModal
        solutionId={solutionId}
        onClose={onClose}
      />
    </ItemAssociationConfigContextProvider>
  );
};
