import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LoginFormInput from "../../components/login-form-input";
import { ReactComponent as RequirementDefaultIcon } from "../../assets/icons/requirement_default.svg";
import { ReactComponent as RequirementMetIcon } from "../../assets/icons/requirement_met.svg";
import { ReactComponent as RequirementNotMetIcon } from "../../assets/icons/requirement_not_met.svg";
import {
  CLASS_OVERRIDE_SHAPE,
  PASSWORDS_DO_NOT_MATCH_LABEL,
  PASSWORD_REQUIREMENTS_FETCH_ERROR_MESSAGE
} from "./data/constant";
import PasswordRequirementStrip from "./components/password-requirement-strip";
import ShowPasswordToggleIcon from "./components/show-password-toggle-icon";
import Style from "./PasswordInputFormWithValidation.module.css";
import useAuthenticationContext from "../../context/authentication-context";

const PasswordInputFormWithValidation = ({
  onSubmit,
  submitButtonText,
  onCancel = null,
  cancelButtonText = null,
  classOverrides = null
}) => {
  const [passwordRequirements, setPasswordRequirements] = useState([]);
  const [passwordHistorySize, setPasswordHistorySize] = useState(0);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordRequirementStatus, setPasswordRequirementStatus] = useState({});
  const [showPasswordRequirementStatus, setShowPasswordRequirementStatus] = useState(false);
  const [showPasswordNotMatchWarning, setShowPasswordNotMatchWarning] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordRequirementFetchFailed, setIsPasswordRequirementFetchFailed] = useState(false);

  const { fetchPasswordRequirements } = useAuthenticationContext();

  const initValidationState = (requirements) => {
    const validationState = {};
    requirements.forEach((req) => {
      validationState[req.name] = null;
    });
    return validationState;
  };

  useEffect(() => {
    const onFetchPasswordRequirementSuccess = (requirements, newPasswordHistorySize) => {
      setPasswordRequirements(requirements);
      setPasswordHistorySize(newPasswordHistorySize);
      setPasswordRequirementStatus(initValidationState(requirements));
    };

    const onFetchPasswordRequirementError = () => {
      setIsPasswordRequirementFetchFailed(true);
    };

    fetchPasswordRequirements(onFetchPasswordRequirementSuccess, onFetchPasswordRequirementError);
  }, []);

  const onPasswordInputChange = (newPassword, event) => {
    setPassword(newPassword);
    updateValidationState(newPassword, true, event);

    // this is for the case when user type the confirm password first first,
    //  and then type the password field that match the confirm password field.
    //  we need to hide the warning if the password is matched as user type this field.
    if (newPassword === confirmPassword) {
      setShowPasswordNotMatchWarning(false);
    }
  };

  const onnPasswordInputFocus = () => {
    if (!showPasswordRequirementStatus) {
      setShowPasswordRequirementStatus(true);
    }
  };

  const onPasswordInputBlur = (event) => {
    updateValidationState(event.target.value, false, event);

    // if user already entered confirm password, and enter a passowrd that doesn't match, show warning on blur
    if (confirmPassword !== "") {
      setShowPasswordNotMatchWarning(!checkIfPasswordsMatched());
    }
  };

  const onConfirmPasswordInputChange = (newConfirmPassword) => {
    setShowPasswordNotMatchWarning(false);
    setConfirmPassword(newConfirmPassword);
  };

  const onConfirmPasswordInputBlur = () => {
    setShowPasswordNotMatchWarning(!checkIfPasswordsMatched());
  };

  const updateValidationState = (passwordToValidate, isUpdatePassedStateOnly, event = {}) => {
    // only set the requirement status to initial state when user clear the password field.
    if (passwordToValidate === "" && event.type !== "blur" && event.type !== "click") {
      setPasswordRequirementStatus(initValidationState(passwordRequirements));
      return;
    }

    const validationState = { ...passwordRequirementStatus };
    passwordRequirements.forEach((requirement) => {
      const { name } = requirement;
      const isValid = requirement.isValid(passwordToValidate);

      // if prev state is green, then it can only go to gray
      if (isUpdatePassedStateOnly && !isValid) {
        if (validationState[name] === true) {
          validationState[name] = null;
        }

        return;
      }
      validationState[name] = isValid;
    });
    setPasswordRequirementStatus(validationState);
  };

  const checkIsFormValid = (event) => {
    if (!showPasswordRequirementStatus) {
      setShowPasswordRequirementStatus(true);
    }

    const isPasswordValid = Object.values(passwordRequirementStatus).every((value) => {
      return value === true;
    });

    const isPasswordMatched = checkIfPasswordsMatched();

    if (!isPasswordValid || !isPasswordMatched) {
      updateValidationState(password, false, event);
      setShowPasswordNotMatchWarning(!isPasswordMatched);
    }
    return isPasswordValid && isPasswordMatched;
  };

  const getInputType = (showPassword_) => {
    return showPassword_ ? "text" : "password";
  };

  const checkIfPasswordsMatched = () => {
    return confirmPassword === password;
  };

  const passwordRequirementDisplay = () => {
    if (isPasswordRequirementFetchFailed) {
      return <div>{PASSWORD_REQUIREMENTS_FETCH_ERROR_MESSAGE}</div>;
    }

    if (showPasswordRequirementStatus) {
      return (
        <div>
          {passwordRequirements.map((requirement) => {
            const { name, label } = requirement;
            const state = passwordRequirementStatus[name];
            const icon =
              state === null ? (
                <RequirementDefaultIcon title="requirement-default" />
              ) : state ? (
                <RequirementMetIcon title="requirement-met" />
              ) : (
                <RequirementNotMetIcon title="requirement-not-met" />
              );

            return (
              <PasswordRequirementStrip
                label={label}
                icon={icon}
                key={label}
                classOverrides={classOverrides}
              />
            );
          })}
        </div>
      );
    }

    return null;
  };

  const passwordNotMatchedWarningDisplay = () => {
    const icon = <RequirementNotMetIcon title="password-not-matched" />;
    return (
      <PasswordRequirementStrip
        label={PASSWORDS_DO_NOT_MATCH_LABEL}
        icon={icon}
        classOverrides={classOverrides}
      />
    );
  };

  return (
    <div className={Style.login_form}>
      <form className={Style.form_group}>
        <div className={Style.input_group}>
          <div className={Style.input_field_container}>
            <LoginFormInput
              primaryColor={classOverrides?.primaryColor}
              secondaryColor={classOverrides?.secondaryColor}
              className={`${classOverrides?.passwordInput || Style.password_input}`}
              label="New Password"
              id="newPassword"
              onChangeText={onPasswordInputChange}
              onFocus={onnPasswordInputFocus}
              onBlur={onPasswordInputBlur}
              type={getInputType(showPassword)}
            />
            <button
              aria-label="toggle-show-password"
              type="button"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              className={Style.show_password_button}
            >
              <ShowPasswordToggleIcon
                showPassword={showPassword}
                classOverrides={classOverrides}
              />
            </button>
          </div>

          {passwordRequirementDisplay()}
        </div>
        <div className={Style.input_group}>
          <div className={Style.input_field_container}>
            <LoginFormInput
              primaryColor={classOverrides?.primaryColor}
              secondaryColor={classOverrides?.secondaryColor}
              className={`${classOverrides?.passwordInput || Style.password_input}`}
              label="Confirm Password"
              id="confirmPassword"
              onChangeText={onConfirmPasswordInputChange}
              onBlur={onConfirmPasswordInputBlur}
              type={getInputType(showConfirmPassword)}
            />
            <button
              aria-label="toggle-show-confirm-password"
              type="button"
              onClick={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
              className={Style.show_password_button}
            >
              <ShowPasswordToggleIcon
                showPassword={showConfirmPassword}
                classOverrides={classOverrides}
              />
            </button>
          </div>

          {showPasswordNotMatchWarning && passwordNotMatchedWarningDisplay()}
        </div>
        <div className={Style.password_form_button_container}>
          {onCancel && (
            <button
              className={`cancel-button ${Style.change_password_button}`}
              type="button"
              onClick={onCancel}
            >
              {cancelButtonText}
            </button>
          )}
          <button
            aria-label="submit"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              if (checkIsFormValid(e)) {
                e.preventDefault();
                onSubmit(password, passwordHistorySize);
              }
            }}
            className={`default-button ${Style.change_password_button}`}
          >
            {submitButtonText}
          </button>
        </div>
      </form>
    </div>
  );
};

PasswordInputFormWithValidation.defaultProps = {
  onCancel: null,
  cancelButtonText: null,
  classOverrides: null
};

PasswordInputFormWithValidation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  cancelButtonText: PropTypes.string,
  classOverrides: CLASS_OVERRIDE_SHAPE
};

export default PasswordInputFormWithValidation;
