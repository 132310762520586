import PropTypes from "prop-types";

export const PASSWORD_REQUIREMENTS_FETCH_ERROR_MESSAGE = "Network issue. Please refresh and try again.";

export const COGNITO_PASSWORD_REUSE_ERROR_MESSAGE = "Password has previously been used";

export const getPasswordReuseErrorMessge = (passwordHistorySize) => {
  return `Your new password cannot match any of your last ${passwordHistorySize} passwords.`;
};

export const PASSWORDS_DO_NOT_MATCH_LABEL = "Passwords do not match";

export const CLASS_OVERRIDE_SHAPE = PropTypes.shape({
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  passwordInput: PropTypes.string,
  showPasswordToggleIcon: PropTypes.string,
  showPasswordToggleLabel: PropTypes.string,
  passwordRequirementLabelColor: PropTypes.string
});
