import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Div } from "../../../../components/div";
import { CollapsibleListView } from "../../../../components/collapsible-list-view";
import { LocationRowsWithOperations } from "../location-rows-with-operations";
import { LocationGroupHeader } from "./location-group-header";
import { AddLocationForm } from "../add-location-form";
import { AddLocationFormV2 } from "../add-location-form-v2/AddLocationFormV2";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";
import "./style.css";
import { FeatureConfigurationProvider } from "../../../../domains/feature-configuration-provider";

const APP_ID = "myFacility";

export const ListLocationGroupsPanel = ({
  configuration,
  locationModelIds,
  locationModelConfigurationMap,
  locationsMap,
  appId,
  featureId,
  onSelectLocation,
  onLocationRecordChanged
}) => {
  const configProvider = useAppConfigProvider(APP_ID);
  const { useV2 } = configProvider.config;
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [addingModelId, setAddingModelId] = useState(null);
  const [addLocationConfiguration, setAddLocationConfiguration] = useState(null);
  const [filteredCounts, setFilteredCounts] = useState({});
  const [filters, setFilters] = useState({});

  /**
   * Name: prepareChildrenConfiguration
   */
  useEffect(() => {
    let cancelled = false;
    const cancelEffectCallback = () => {
      cancelled = true;
    };

    const configurationProvider = FeatureConfigurationProvider.parse("ListLocationGroupsPanel", configuration);

    const addFormConfig = configurationProvider.getFeatureConfiguration("addResource");
    if (!cancelled) {
      setAddLocationConfiguration(addFormConfig);
    }

    return cancelEffectCallback;
  }, [configuration]);

  const onSelectLocationCallback = useCallback(
    (location, groupId) => {
      setSelectedGroupId(groupId);
      onSelectLocation(location, groupId);
    },
    [onSelectLocation]
  );

  const onAddClicked = (modelId) => {
    if (useV2) {
      window.fcWidget.hide();
    }
    setAddingModelId(modelId);
    setShowAddModal(true);
  };

  const onAddSubmit = () => {
    if (useV2) {
      window.fcWidget.show();
    }
    onLocationRecordChanged(addingModelId);
    setShowAddModal(false);
    setAddingModelId(null);
  };

  const onAddFormClose = useCallback(() => {
    if (useV2) {
      window.fcWidget.show();
    }
    setShowAddModal(false);
  }, []);

  const handleFilteredLocationsCount = (groupId, count) => {
    setFilteredCounts((prevCounts) => ({
      ...prevCounts,
      [groupId]: count
    }));
  };

  const handleFilterInputChange = (groupId, filterString) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [groupId]: filterString
    }));
  };

  const renderAddModal = () => {
    if (useV2) {
      return (
        <AddLocationFormV2
          modelId={addingModelId}
          onFormClosed={onAddFormClose}
          show={showAddModal}
          onSubmit={onAddSubmit}
          configuration={addLocationConfiguration}
          rolesConfiguration={configuration?.modelMap?.roles}
        />
      );
    }
    return (
      <AddLocationForm
        modelId={addingModelId}
        onFormClosed={onAddFormClose}
        show={showAddModal}
        onSubmit={onAddSubmit}
        configuration={addLocationConfiguration}
      />
    );
  };

  const getFilteredLocations = (groupId, locations) => {
    const filterInput = filters[groupId] || "";
    if (!filterInput) return locations;
    return locations.filter((location) => location.name.toLowerCase().includes(filterInput.toLowerCase()));
  };

  return (
    <Div className="my-facility-locations">
      {showAddModal && renderAddModal()}
      <Div className="my-facility-locations-title-section">
        <Div className="my-facility-locations-title-header">Locations</Div>
      </Div>
      <Div>
        {locationModelIds.map((modelId) => {
          const modelConfiguration = locationModelConfigurationMap[modelId];
          const { category } = modelConfiguration;
          const { groupDisplayName: categoryGroupDisplayName } = category;

          const groupId = modelId;
          const locations = locationsMap[groupId] || [];
          const filteredLocations = getFilteredLocations(groupId, locations);
          const filteredCount =
            filteredCounts[groupId] !== undefined ? filteredCounts[groupId] : filteredLocations.length;

          return (
            <CollapsibleListView
              openAtStart={false}
              className="category-detail-view"
              key={groupId}
              title={
                <LocationGroupHeader
                  modelId={modelId}
                  groupName={categoryGroupDisplayName}
                  categoryGroupDisplayName={categoryGroupDisplayName}
                  onAddClick={onAddClicked}
                  onSelectLocation={onSelectLocationCallback}
                  resourceCount={filteredCount}
                />
              }
              content={
                <LocationRowsWithOperations
                  locations={getFilteredLocations(groupId, locations)}
                  appId={appId}
                  featureId={featureId}
                  filterInput={filters[groupId] || ""}
                  onFilterInputChange={(filterString) => handleFilterInputChange(groupId, filterString)}
                  onSelectLocation={(location) => onSelectLocationCallback(location, groupId)}
                  isChildSelected={selectedGroupId === groupId}
                  onLocationRecordChanged={(locationId) => onLocationRecordChanged(groupId, locationId)}
                  setFilteredLocationsCount={handleFilteredLocationsCount}
                  groupId={groupId}
                />
              }
            />
          );
        })}
      </Div>
    </Div>
  );
};

ListLocationGroupsPanel.propTypes = {
  configuration: PropTypes.object.isRequired,
  locationModelIds: PropTypes.array.isRequired,
  locationModelConfigurationMap: PropTypes.object.isRequired,
  locationsMap: PropTypes.object.isRequired,
  appId: PropTypes.string.isRequired,
  featureId: PropTypes.string.isRequired,
  onSelectLocation: PropTypes.func.isRequired,
  onLocationRecordChanged: PropTypes.func.isRequired
};
