import React, { useState, useEffect, useMemo } from "react";
import { useDisplayBannerContext } from "context/DisplayBannerContext/DisplayBannerContext";
import Spinner from "react-bootstrap/Spinner";
import { FeatureConfigurationProvider } from "../../../../domains/feature-configuration-provider";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import DisplayBanner from "../../../../components/display-banner/DisplayBanner";
import InputGroup from "../../../../components/my-facility-v2-components/InputGroup";
import DetectorGroup from "../../../../components/my-facility-v2-components/DetectorGroup.js";
import AddLocationFormV2Style from "./AddLocationFormV2.module.css";
import { getPartnerDisplayValue, getPartnerPropertyFromConfig } from "../../../list-partners/ListPartnersUtil";
import formatText from "../../../../utils/format-text";
import { ReactComponent as MyFacilityIcon } from "../../../../assets/icons/my-facility.svg";
import OnboardItemModal from "components/onboard-item-modal";
import "./style.css";

const getProvidedOptionsMaps = async (xClient, partnerDisplayProperty) => {
  const partners = await getPartners(xClient);
  return {
    partner: partners.map((partner) => {
      return { key: partner.id, value: getPartnerDisplayValue(partner, partnerDisplayProperty) };
    })
  };
};

const getPartners = async (xClient) => {
  // fetch the data
  const partnerClient = xClient.getPartnerClient();
  const results = await partnerClient.listPartners();
  return results;
};

const retrieveProperties = async (
  xClient,
  modelConfigProvider,
  partnerDisplayProperty,
  defaultPropertyOrders = [],
  additionalFilterFn = (prop) => {
    return prop;
  }
) => {
  const propertyMap = modelConfigProvider.getPropertyMap();
  const propertyOrders = modelConfigProvider.getValue("propertyOrders", "array", defaultPropertyOrders);

  const optionsMap = await getProvidedOptionsMaps(xClient, partnerDisplayProperty);

  const filterProperties = propertyOrders
    .map((propertyId) => {
      const property = propertyMap[propertyId];
      const resolvedProperty = { name: propertyId, ...property };

      let { options, optionsProvided } = resolvedProperty;
      if (optionsProvided) {
        options = optionsMap[propertyId];
      }

      const formattedOptions = options?.map((option) => {
        return {
          ...option,
          id: option.key,
          label: option.value,
          value: option.value
        };
      });

      resolvedProperty.options = formattedOptions;

      return resolvedProperty;
    })
    .filter((property) => {
      return !property.hidden;
    })
    .filter(additionalFilterFn);
  return filterProperties;
};

const fetchLocationOptions = (locationClient, categoryName) => {
  return locationClient.getLocationsOfCategory(categoryName).then((results) => {
    const sorted = results.sort((loc1, loc2) => {
      return loc1.getName().localeCompare(loc2.getName());
    });
    const options = sorted.map((loc) => {
      const nameSegments = [loc.getName()];
      const parentLoc = loc.getParent();
      if (parentLoc) {
        nameSegments.push(parentLoc.getName());
      }
      return {
        id: loc.getId(),
        value: nameSegments.join(" - "),
        label: nameSegments.join(" - ")
      };
    });
    return options;
  });
};

const FeatureId = "addResource";
const ERROR_MESSAGE = "Action was not completed. Please review highlighted items and try again.";

export const AddLocationFormV2 = ({ configuration, modelId, show, onSubmit, onFormClosed }) => {
  const { setShowBanner, setBannerTitle, setBannerHasError } = useDisplayBannerContext();
  const [showModalBanner, setShowModalBanner] = useState(false);
  const [modalBannerMessage, setModalBannerMessage] = useState("");
  const [modalBannerHasError, setModalBannerHasError] = useState(false);
  const [locationCategoryName, setLocationCategoryName] = useState(modelId);
  const [reloadOnClose, setReloadOnClose] = useState(false);
  const [loading, setLoading] = useState(true);
  const [xemelgoClient] = useState(useXemelgoClient());
  const [properties, setProperties] = useState([]);
  const [editedProperties, setEditedProperties] = useState({});

  const [detectorGroups, setDetectorGroups] = useState({
    "detector-0": {}
  });
  const [rolesConfig, setRolesConfig] = useState([]);
  const [detectorProperties, setDetectorProperties] = useState([]);

  const detectorsEnabled = useMemo(() => {
    if (rolesConfig && rolesConfig.options && editedProperties.role) {
      const option = rolesConfig.options.find((role) => {
        return role.name === editedProperties.role.value;
      });
      return option && option.detectorsEnabled;
    }
    return false;
  }, [rolesConfig, editedProperties]);

  const vendorOptions = useMemo(() => {
    const vendorProperty = detectorProperties.find((property) => {
      return property.name === "vendor";
    });
    return vendorProperty?.options || [];
  }, [detectorProperties]);

  const detectorClassToModeMap = useMemo(() => {
    const classProperty = detectorProperties.find((property) => {
      return property.name === "class";
    });
    const { options = [] } = classProperty || {};
    const tempDetectorClass = [];
    const tempModeMap = {};
    let roleDetectorModeIds;
    if (rolesConfig && rolesConfig.options && editedProperties.role) {
      const roleOption = rolesConfig.options.find((role) => {
        return role.name === editedProperties.role.value;
      });
      roleDetectorModeIds = roleOption?.detectorModes;
    }

    options.forEach((option) => {
      const { key, value, properties: modeProperties } = option;
      tempDetectorClass.push({
        id: key,
        label: value,
        value
      });

      tempModeMap[key] =
        modeProperties?.mode?.options
          ?.filter((mode) => {
            return !roleDetectorModeIds || roleDetectorModeIds.includes(mode.key);
          })
          .map((mode) => {
            return {
              id: mode.key,
              label: mode.value,
              value: mode.value
            };
          }) || [];
    });

    return tempModeMap;
  }, [detectorProperties, editedProperties, rolesConfig]);

  useEffect(() => {
    onLoad();
  }, [configuration, modelId]);

  const onLoad = async () => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!configuration || !modelId || !xemelgoClient) {
      return cancelCallback;
    }

    // configuration provided information.
    const configurationProvider = FeatureConfigurationProvider.parse(FeatureId, configuration);
    const modelConfigProvider = configurationProvider.getModel(modelId);
    const parentRef = modelConfigProvider.getValue("locatedInModel", "string", null);
    const partnerDisplayProperty = getPartnerPropertyFromConfig(
      configurationProvider,
      "displayProperty",
      "string",
      "name"
    );

    // prepare the properties
    const modelConfig = modelConfigProvider.getDefinitionObject();
    const { category = { displayName: modelId }, roles } = modelConfig;
    const { displayName: newLocationCategoryName } = category;

    const filterAddablePropertiesFn = (prop) => {
      const { __addable: canAdd } = prop;
      return canAdd;
    };

    let filteredProperties = await retrieveProperties(
      xemelgoClient,
      modelConfigProvider,
      partnerDisplayProperty,
      ["name", "description"],
      filterAddablePropertiesFn
    );

    filteredProperties = filteredProperties.map((property) => {
      return {
        ...property,
        displayName: `${newLocationCategoryName} ${property.displayName}`
      };
    });

    // prepare api-query information.
    if (parentRef) {
      const parentLocationModelProvider = configurationProvider.getModel(parentRef);
      const parentCategory = parentLocationModelProvider.getValue("category", "object", {
        name: parentRef
      });
      const { name: parentCategoryName, displayName: parentCategoryDisplayName } = parentCategory;
      const locationClient = xemelgoClient.getLocationClient();
      const options = await fetchLocationOptions(locationClient, parentCategoryName);
      if (!cancelled) {
        filteredProperties.unshift({
          name: "parent",
          displayName: parentCategoryDisplayName,
          options
        });
        setProperties(filteredProperties);
      }
    }

    const detectorModelProvider = configurationProvider.getModel("detector");
    const newDetectorProperties = await retrieveProperties(
      xemelgoClient,
      detectorModelProvider,
      ["vendor", "class", "action"],
      () => {
        return true;
      }
    );

    if (roles && roles.options) {
      let rolesOptions;
      rolesOptions = roles.options.map((option) => {
        return {
          id: option.name,
          label: option.name,
          value: option.name
        };
      });

      filteredProperties.push({
        name: "role",
        displayName: "Role",
        options: rolesOptions,
        optional: roles.optional
      });
    }

    if (!cancelled) {
      setLoading(false);
      setProperties(filteredProperties);
      setRolesConfig(roles);
      setLocationCategoryName(newLocationCategoryName);
      setDetectorProperties(newDetectorProperties);
    }

    return cancelCallback;
  };

  const handleEdit = (id, value) => {
    const tempEditedProperties = { ...editedProperties };
    tempEditedProperties[id] = value;
    if (id === "role") {
      setDetectorGroups({
        "detector-0": {}
      });
    }
    setEditedProperties(tempEditedProperties);
  };

  const handleDetectorEdit = (key, detectorIndex, property, value) => {
    const newDetectorGroups = { ...detectorGroups };
    newDetectorGroups[key].detectors[detectorIndex][property] = value;
    const vendorOption = vendorOptions.find((option) => {
      return option.prefix && value.toUpperCase().startsWith(option.prefix.toUpperCase());
    });
    if (vendorOption) {
      newDetectorGroups[key].detectors[detectorIndex].vendor = vendorOption.value;
    } else {
      const defaultOption = vendorOptions.find((option) => {
        return !option.prefix;
      });
      newDetectorGroups[key].detectors[detectorIndex].vendor = defaultOption.value;
    }
    setDetectorGroups(newDetectorGroups);
  };

  const renderLoading = () => {
    return (
      <div className="loading_circle">
        <Spinner animation="border" />
      </div>
    );
  };

  const handleCreateLocation = async () => {
    const locationClient = xemelgoClient.getLocationClient();
    const { role = {}, parent = {}, partner = {} } = editedProperties;
    const { id: roleName } = role;
    const { id: parentId } = parent;
    const { id: partnerId } = partner;
    let roleId;

    if (roleName) {
      const roleResult = await locationClient.getRolesByNames([roleName]);
      const { locationRoles = [{}] } = roleResult;
      roleId = locationRoles[0]?.id;
    }

    delete editedProperties.role;
    delete editedProperties.parent;
    delete editedProperties.partner;
    editedProperties.category = locationCategoryName;

    const location = await locationClient.createLocationV2(editedProperties, parentId, roleId, partnerId);

    const detectorClient = xemelgoClient.getDetectorClient();
    for (const group in detectorGroups) {
      const currentGroup = detectorGroups[group];
      const { class: detectorClass, mode, detectors = [] } = currentGroup;
      for (const detector of detectors) {
        const { vid, vendor } = detector;
        if (vid) {
          const vendorOption = vendorOptions.find((option) => {
            return option.key === vendor;
          });
          await detectorClient.createDetectorV2(
            {
              vid,
              name: vid,
              vendor,
              class: detectorClass.id,
              mode: mode?.id,
              protocol: vendorOption.protocol
            },
            location.id
          );
        }
      }
    }

    setEditedProperties({});
    setDetectorGroups({
      "detector-0": {}
    });
    setLoading(false);
  };

  const verifyPayload = async () => {
    setLoading(true);
    const tempProperties = [...properties];
    const tempDetectorGroups = { ...detectorGroups };
    let canSubmit = true;
    let errorMessage = ERROR_MESSAGE;
    tempProperties.forEach((property) => {
      if (!property.optional && !editedProperties[property.name]) {
        property.error = true;
        canSubmit = false;
      } else {
        property.error = false;
      }
    });

    if (editedProperties.name) {
      const locationClient = xemelgoClient.getLocationClient();
      const locationResult = await locationClient.getLocationsByNames([editedProperties.name]);

      if (locationResult.length > 0) {
        errorMessage = `A location with name ${editedProperties.name} already exists!`;
        const nameProperty = properties.find((property) => {
          return property.name === "name";
        });
        nameProperty.error = true;
        canSubmit = false;
      }
    }

    const vidMap = {};
    Object.keys(tempDetectorGroups).forEach((group) => {
      if (tempDetectorGroups[group].detectors) {
        for (let index = 0; index < tempDetectorGroups[group].detectors.length; index++) {
          const detector = tempDetectorGroups[group].detectors[index];
          if (detector.vid) {
            if (!vidMap[detector.vid]) {
              vidMap[detector.vid] = [{ group, index }];
            } else {
              vidMap[detector.vid].push({ group, index });
            }
          } else {
            tempDetectorGroups[group].detectors[index].error = true;
            canSubmit = false;
          }
        }
      }
    });

    Object.keys(vidMap).forEach((vid) => {
      const currentVid = vidMap[vid];
      currentVid.forEach((entry) => {
        tempDetectorGroups[entry.group].detectors[entry.index].error = vidMap[vid].length > 1;
      });
      if (currentVid.length > 1) {
        canSubmit = false;
      }
    });

    const detectorClient = xemelgoClient.getDetectorClient();
    const result = await detectorClient.getDetectorsByVids(Object.keys(vidMap));
    const existingVids = [];
    if (result.length > 0) {
      result.forEach((detector) => {
        existingVids.push(detector.vid);
        const currentVid = vidMap[detector.vid];
        currentVid.forEach((entry) => {
          tempDetectorGroups[entry.group].detectors[entry.index].error = true;
        });
      });
      canSubmit = false;
      errorMessage = `The following detector(s) already exist: ${existingVids.join(", ")}`;
    }

    setDetectorGroups(tempDetectorGroups);
    setProperties(tempProperties);
    setModalBannerMessage(errorMessage);
    setModalBannerHasError(true);
    setShowModalBanner(true);
    return canSubmit;
  };

  return (
    <OnboardItemModal
      scrollable
      show={show}
      prefix="location"
      className="locationModal"
      titleIconComponent={
        <MyFacilityIcon
          width={42}
          height={42}
          className={AddLocationFormV2Style.title_icon}
        />
      }
      submitDisabled={false}
      submitLoading={loading}
      onSubmit={async () => {
        const canSubmit = await verifyPayload();
        if (canSubmit) {
          await handleCreateLocation();
          setModalBannerMessage(`Your ${modelId} was created successfully!`);
          setModalBannerHasError(false);
          setShowModalBanner(true);
          setReloadOnClose(true);
        }
        setLoading(false);
      }}
      onClose={() => {
        if (reloadOnClose) {
          onSubmit();
        } else {
          onFormClosed();
        }
      }}
      modalContainerClassName={AddLocationFormV2Style.modal_container}
      title={`Add a ${formatText(modelId, ["capitalize"])}`}
    >
      {loading ? (
        renderLoading()
      ) : (
        <div>
          {showModalBanner && (
            <DisplayBanner
              onCloseBanner={() => {
                setShowModalBanner(false);
              }}
              bannerMessage={modalBannerMessage}
              bannerError={modalBannerHasError}
            />
          )}
          <InputGroup
            title="Location Information"
            properties={properties}
            onChange={handleEdit}
            values={editedProperties}
          />
          {detectorsEnabled && (
            <DetectorGroup
              detectorGroups={detectorGroups}
              properties={detectorProperties}
              onGroupChange={(key, property, newValue) => {
                const newDetectorGroups = { ...detectorGroups };
                newDetectorGroups[key][property] = newValue;
                if (!newDetectorGroups[key].detectors) {
                  newDetectorGroups[key].detectors = [{}];
                }
                setDetectorGroups(newDetectorGroups);
              }}
              onDetectorChange={handleDetectorEdit}
              handleAddGroup={() => {
                const newDetectorGroups = { ...detectorGroups };
                newDetectorGroups[`detector-${Date.now()}`] = {};
                setDetectorGroups(newDetectorGroups);
              }}
              handleRemoveGroup={(key) => {
                const newDetectorGroups = { ...detectorGroups };
                delete newDetectorGroups[key];
                setDetectorGroups(newDetectorGroups);
              }}
              handleAddDetector={(key) => {
                const newDetectorGroups = { ...detectorGroups };
                newDetectorGroups[key].detectors.push({});
                setDetectorGroups(newDetectorGroups);
              }}
              handleRemoveDetector={(key, index) => {
                const newDetectorGroups = { ...detectorGroups };
                newDetectorGroups[key].detectors.splice(index, 1);
                setDetectorGroups(newDetectorGroups);
              }}
              classToModeMap={detectorClassToModeMap}
            />
          )}
        </div>
      )}
    </OnboardItemModal>
  );
};
