import xemelgoStyle from "../../../../../styles/variable";
import { ACTION_OPTIONS_MAP } from "../../../data/constants";
import { ReactComponent as AddIcon } from "../../../../../assets/icons/add.svg";
import { ReactComponent as CheckoutIcon } from "../../../../../assets/icons/goods-receipt.svg";
import { ReactComponent as ConsumeIcon } from "../../../../../assets/icons/consume.svg";
import { ReactComponent as MoveIcon } from "../../../../../assets/icons/manifest.svg";
import { ReactComponent as OrderCreationIcon } from "../../../../../assets/icons/order-creation.svg";
import { ReactComponent as UnconsumeIcon } from "../../../../../assets/icons/archive.svg";
import { ReactComponent as VerificationIcon } from "../../../../../assets/icons/inventory.svg";

export const ACTION_TYPE_TO_ICON = {
  [ACTION_OPTIONS_MAP.ADD]: AddIcon,
  [ACTION_OPTIONS_MAP.CHECK_OUT]: CheckoutIcon,
  [ACTION_OPTIONS_MAP.CONSUME]: ConsumeIcon,
  [ACTION_OPTIONS_MAP.MOVE]: MoveIcon,
  [ACTION_OPTIONS_MAP.ORDER_CREATION]: OrderCreationIcon,
  [ACTION_OPTIONS_MAP.PICKLIST_VERIFICATION]: VerificationIcon,
  [ACTION_OPTIONS_MAP.UNCONSUME]: UnconsumeIcon
};

export const ACTION_TYPE_TO_COLOR = {
  [ACTION_OPTIONS_MAP.ADD]: xemelgoStyle.theme.ASSET_PRIMARY,
  [ACTION_OPTIONS_MAP.CHECK_OUT]: xemelgoStyle.theme.PURCHASE_PRIMARY,
  [ACTION_OPTIONS_MAP.CONSUME]: xemelgoStyle.theme.STATUS_YELLOW,
  [ACTION_OPTIONS_MAP.MOVE]: xemelgoStyle.theme.XEMELGO_BLUE,
  [ACTION_OPTIONS_MAP.ORDER_CREATION]: xemelgoStyle.theme.XEMELGO_DEEP_BLUE,
  [ACTION_OPTIONS_MAP.PICKLIST_VERIFICATION]: xemelgoStyle.theme.ORDER_PRIMARY,
  [ACTION_OPTIONS_MAP.UNCONSUME]: xemelgoStyle.theme.PACKAGE_PRIMARY
};
