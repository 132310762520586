import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import { LocalCacheService } from "../../../../services/local-cache-service";
import { DATA_VIEW_TYPE_MAP } from "../../../../components/change-data-view-dropdown";
import useTransferOrderTrackPageSearchParams from "../../hooks/use-transfer-order-track-page-search-params";
import useTransferOrderTrackPageConfigContext from "../transfer-order-track-page-config-context";

const TransferOrderTrackPageStateContext = React.createContext();

const initialState = {
  selectedDataViewId: DATA_VIEW_TYPE_MAP.graphsAndMetrics,
  isSideFilterHidden: false,
  freeTextSearchInputString: ""
};

export const useTransferOrderTrackPageStateContext = () => {
  return useContext(TransferOrderTrackPageStateContext);
};

export const TransferOrderTrackPageStateContextProvider = ({ children }) => {
  const [selectedDataViewId, setSelectedDataViewIdState] = useState(initialState.selectedDataViewId);
  const [isSideFilterHidden, setIsSideFilterHidden] = useState(initialState.isSideFilterHidden);
  const [freeTextSearchInputString, setFreeTextSearchInputString] = useState(initialState.freeTextSearchInputString);
  const [displayedTableHeadersMap, setDisplayedTableHeadersMapState] = useState({});
  const [displayedTableHeadersMapDefault, setDisplayedTableHeadersMapDefault] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [exportCsvFn, setExportCsvFn] = useState(() => {});

  const { selectedViewTypeId, setSelectedViewTypeId, sideFilterValue, setSideFilterValue, isSearchParamReady } =
    useTransferOrderTrackPageSearchParams();

  const {
    listTableControl,
    tableViewTypeControl,
    isLoading: isConfigLoading
  } = useTransferOrderTrackPageConfigContext();

  // Validate and update savedDisplayedTableHeadersMapCloned object
  const validateAndDefaultDisplayedTableHeadersMap = (savedDisplayedTableHeadersMap, defaultValue) => {
    const savedDisplayedTableHeadersMapCloned = _.cloneDeep(savedDisplayedTableHeadersMap);

    // Iterate over defaultValue keys
    Object.keys(defaultValue).forEach((eachTableId) => {
      // If key doesn't exist in savedDisplayedTableHeadersMapCloned, default it
      if (!savedDisplayedTableHeadersMapCloned[eachTableId]) {
        savedDisplayedTableHeadersMapCloned[eachTableId] = { ...defaultValue[eachTableId] };
      } else {
        Object.keys(defaultValue[eachTableId]).forEach((eachViewTypeId) => {
          if (!savedDisplayedTableHeadersMapCloned[eachTableId][eachViewTypeId]) {
            savedDisplayedTableHeadersMapCloned[eachTableId][eachViewTypeId] = [
              ...defaultValue[eachTableId][eachViewTypeId]
            ];
          } else {
            // Filter out invalid IDs not in defaultValue

            const validHeaderIds = new Set(defaultValue[eachTableId][eachViewTypeId]);
            savedDisplayedTableHeadersMapCloned[eachTableId][eachViewTypeId] = savedDisplayedTableHeadersMapCloned[
              eachTableId
            ][eachViewTypeId].filter((id) => {
              return validHeaderIds.has(id);
            });

            // Add missing IDs if no valid ids remain
            if (!savedDisplayedTableHeadersMapCloned[eachTableId][eachViewTypeId].length) {
              defaultValue[eachTableId][eachViewTypeId].forEach((id) => {
                if (!savedDisplayedTableHeadersMapCloned[eachTableId][eachViewTypeId].includes(id)) {
                  savedDisplayedTableHeadersMapCloned[eachTableId][eachViewTypeId].push(id);
                }
              });
            }
          }
        });
      }
    });

    // Remove any keys from savedDisplayedTableHeadersMapCloned not present in defaultValue
    Object.keys(savedDisplayedTableHeadersMapCloned).forEach((eachTableId) => {
      if (!defaultValue[eachTableId]) {
        delete savedDisplayedTableHeadersMapCloned[eachTableId];
      } else {
        Object.keys(savedDisplayedTableHeadersMapCloned[eachTableId]).forEach((eachViewTypeId) => {
          if (!defaultValue[eachTableId][eachViewTypeId]) {
            delete savedDisplayedTableHeadersMapCloned[eachTableId][eachViewTypeId];
          }
        });
      }
    });

    return savedDisplayedTableHeadersMapCloned;
  };

  useEffect(() => {
    const getDefaultDisplayedTableHeadersMap = () => {
      const defaultValue = {};

      // Retrieve the headers from listTableControl and assign them directly
      Object.keys(listTableControl).forEach((eachTableId) => {
        const { headers } = listTableControl[eachTableId];

        // initialize an empty object for each tableId
        defaultValue[eachTableId] = {};

        const tableViewTypeControlPerTable = tableViewTypeControl[eachTableId];

        tableViewTypeControlPerTable.forEach(({ id: eachTableViewTypeId }) => {
          // initialize an empty array for each viewTypeId
          defaultValue[eachTableId][eachTableViewTypeId] = [];

          headers.forEach(({ id: eachHeaderId, viewTypes }) => {
            if (!viewTypes?.length || viewTypes.includes("all") || viewTypes.includes(eachTableViewTypeId)) {
              defaultValue[eachTableId][eachTableViewTypeId].push(eachHeaderId);
            }
          });
        });
      });

      return defaultValue;
    };

    if (!isConfigLoading) {
      const newDisplayedTableHeadersMapDefault = getDefaultDisplayedTableHeadersMap();
      setDisplayedTableHeadersMapDefault(newDisplayedTableHeadersMapDefault);

      const newDisplayedTableHeadersMap = validateAndDefaultDisplayedTableHeadersMap(
        LocalCacheService.getTransferOrderTrackPageDisplayedHeadersMap(),
        newDisplayedTableHeadersMapDefault
      );
      setDisplayedTableHeadersMap(newDisplayedTableHeadersMap);
    }
  }, [listTableControl, tableViewTypeControl, isConfigLoading]);

  useEffect(() => {
    const newSelectedDataView = LocalCacheService.getTransferOrderTrackPageDataView();
    setSelectedDataViewIdState(newSelectedDataView || initialState.selectedDataViewId);
  }, []);

  const setSelectedDataViewId = (value) => {
    LocalCacheService.saveTransferOrderTrackPageDataView(value);
    setSelectedDataViewIdState(value);
  };

  const setDisplayedTableHeadersMap = (value) => {
    LocalCacheService.saveTransferOrderTrackPageDisplayedHeadersMap(value);
    setDisplayedTableHeadersMapState(value);
  };

  return (
    <TransferOrderTrackPageStateContext.Provider
      value={{
        selectedDataViewId,
        setSelectedDataViewId,
        isSideFilterHidden,
        setIsSideFilterHidden,
        freeTextSearchInputString,
        setFreeTextSearchInputString,
        selectedViewTypeId,
        setSelectedViewTypeId,
        sideFilterValue,
        setSideFilterValue,
        isSearchParamReady,
        displayedTableHeadersMap,
        displayedTableHeadersMapDefault,
        setDisplayedTableHeadersMap,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        exportCsvFn,
        setExportCsvFn
      }}
    >
      {children}
    </TransferOrderTrackPageStateContext.Provider>
  );
};
