export const getDefaultedConfig = <ConfigType extends Record<string, unknown>>(
  defaultConfig: ConfigType,
  config: Record<string, unknown>
): ConfigType => {
  return Object.keys(defaultConfig).reduce((acc, configKey: keyof ConfigType) => {
    if (!(configKey in config)) {
      return acc;
    }

    const defaultConfigValue = defaultConfig[configKey];
    let configValue = config[configKey as string];

    // TODO: Add better type checking since this doesn't check actual types of objects
    if (typeof configValue !== typeof defaultConfigValue) {
      // Show popup warning too
      console.warn(
        `Config value type mismatch for key ${
          configKey as string
        }. Expected ${typeof defaultConfigValue}, got ${typeof configValue}.`
      );

      configValue = defaultConfigValue;
    }

    if (typeof configValue === "object" && typeof defaultConfigValue === "object" && !Array.isArray(configValue)) {
      acc[configKey] = {
        ...defaultConfigValue,
        ...configValue
      };
    } else {
      acc[configKey] = configValue as ConfigType[keyof ConfigType];
    }

    return acc;
  }, defaultConfig);
};
