import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAddAssetFeatureConfigContext } from "../../contexts/add-asset-feature-config-context";
import { useAddAssetFeatureStateContext } from "../../contexts/add-asset-feature-state-context";
import Style from "./AssetSingleCreateTab.module.css";
import SingleCreateForm from "../../../../components/single-create-form";
import useOnboardingForm from "../../../../hooks/use-onboarding-form";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import { getCategoryDropdowns } from "./utils/get-category-dropdowns/getCategoryDropdown";
import { STATUS_OPTIONS } from "../../../../components/status-popup-component/data/constants";

const AssetSingleCreateTab = () => {
  const [categoryClient] = useState(useXemelgoClient().getCategoryClient());
  const {
    formFields,
    formSections,
    categorySearchEnabled,
    subcategorySearchEnabled,
    isLoading,
    showSaveEntriesCheckbox
  } = useAddAssetFeatureConfigContext();
  const { onFormSectionsChange, updateFormSectionsWithFormData, getFieldCachedValue } = useOnboardingForm();
  const { formDataMap, setFormDataMap, submitStatus, setSubmitStatus, isSaveLastSubmittedEntriesChecked } =
    useAddAssetFeatureStateContext();

  const [flatCategoryTrees, setFlatCategoryTrees] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedSubcategory, setSelectedSubcategory] = useState({});
  const [showCategorySearch, setShowCategorySearch] = useState(false);

  const processedFormSections = useMemo(() => {
    if (!formSections.length) {
      return [];
    }

    const { data = {} } = Object.values(formDataMap)[0] || {};

    const newFormSections = updateFormSectionsWithFormData(data, formSections);
    const assetTypeFields = newFormSections[0];

    if (showCategorySearch) {
      // Add category and subcategory dropdowns to the form
      const { categoryDropdown, subcategoryDropdown } = getCategoryDropdowns(
        flatCategoryTrees,
        selectedCategory,
        selectedSubcategory
      );

      if (subcategorySearchEnabled) {
        assetTypeFields.unshift(subcategoryDropdown);
      }
      assetTypeFields.unshift(categoryDropdown);
    }

    const typeIdentifierFieldIndex = assetTypeFields.findIndex((field) => {
      return field.id === "item_number";
    });
    const typeIdentifierField = assetTypeFields[typeIdentifierFieldIndex];

    // Only show the types that are part of the selected category or subcategory
    if (selectedCategory.id || selectedSubcategory.id) {
      typeIdentifierField.options = typeIdentifierField.options.filter((option) => {
        return option.ofCategory.some((category) => {
          return category.id === (selectedSubcategory.id ?? selectedCategory.id);
        });
      });
    }

    // Put the selected asset type's category and subcategory in the subFieldsMap so they can be displayed
    const selectedTypeCategories = (typeIdentifierField.value?.ofCategory || [])
      .reduce((acc, category) => {
        if (flatCategoryTrees[category.id]?.depth === 0) {
          acc.push(category.identifier);
        }
        return acc;
      }, [])
      .join(", ");

    const selectedTypeSubCategories = (typeIdentifierField.value?.ofCategory || [])
      .reduce((acc, category) => {
        if (flatCategoryTrees[category.id]?.depth === 1) {
          acc.push(category.identifier);
        }
        return acc;
      }, [])
      .join(", ");

    typeIdentifierField.subFieldsMap = {
      ...typeIdentifierField.subFieldsMap,
      category: {
        label: "Category",
        value: categorySearchEnabled ? selectedTypeCategories : ""
      },
      subcategory: {
        label: "Subcategory",
        value: subcategorySearchEnabled ? selectedTypeSubCategories : ""
      }
    };

    assetTypeFields[typeIdentifierFieldIndex] = typeIdentifierField;
    newFormSections[0] = assetTypeFields;

    return newFormSections;
  }, [
    showCategorySearch,
    subcategorySearchEnabled,
    formSections,
    formDataMap,
    selectedCategory,
    selectedSubcategory,
    flatCategoryTrees
  ]);

  useEffect(() => {
    const fetchCategoryTrees = async () => {
      setFlatCategoryTrees(await categoryClient.getFlatCategoryTrees());
    };

    if (!isLoading) {
      fetchCategoryTrees();
    }
  }, [isLoading, subcategorySearchEnabled]);

  const resetFormData = () => {
    if (formFields) {
      const newFormData = {};
      const { data = {} } = Object.values(formDataMap)[0] || {};
      Object.keys(formFields).forEach((fieldName) => {
        const field = formFields[fieldName];
        let isFieldSaved;
        if (showSaveEntriesCheckbox) {
          isFieldSaved = !!(isSaveLastSubmittedEntriesChecked && field.keepPreviousValue);
        } else {
          isFieldSaved = !!field.keepPreviousValue;
        }

        const cachedValue = getFieldCachedValue("asset", field);
        if (cachedValue) {
          newFormData[fieldName] = cachedValue;
          return;
        }

        newFormData[fieldName] = isFieldSaved ? data[fieldName] : field.defaultValue;
      });

      if (Object.keys(newFormData).length) {
        setFormDataMap({ id: { data: newFormData } });
      }
    }
  };

  const onFormChange = useCallback(
    (fieldId, value) => {
      const { data: newFormData = {} } = Object.values(formDataMap)[0] || {};

      if (fieldId === "category") {
        setSelectedCategory(value);
        setSelectedSubcategory({});
        newFormData.item_number = null;
      }
      if (fieldId === "subcategory") {
        setSelectedSubcategory(value);
        newFormData.item_number = null;
      }

      setFormDataMap({ id: { data: onFormSectionsChange(fieldId, value, formSections, formFields, newFormData) } });
    },
    [formSections, formDataMap, formFields]
  );

  useEffect(() => {
    resetFormData();
  }, [formFields]);

  useEffect(() => {
    if (submitStatus === STATUS_OPTIONS.SUCCESS) {
      resetFormData();
    }
  }, [submitStatus]);

  useEffect(() => {
    // Clears popup message
    if (submitStatus === STATUS_OPTIONS.ERROR) {
      setSubmitStatus(STATUS_OPTIONS.NONE);
    }
  }, [formDataMap]);

  return (
    <div className={submitStatus === STATUS_OPTIONS.LOADING ? Style.modal_disabled : ""}>
      <SingleCreateForm
        title="Asset Information"
        formSections={processedFormSections}
        onFormChange={onFormChange}
        additionalOptionsComponent={
          !showCategorySearch &&
          categorySearchEnabled && (
            <div
              className={Style.clickable_text}
              onClick={() => {
                setShowCategorySearch(true);
              }}
            >
              Look for an asset type by category
            </div>
          )
        }
      />
    </div>
  );
};

export default AssetSingleCreateTab;
