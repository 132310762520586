export const OPPOSITE_DIRECTION_RELATIONSHIP_MAP: Record<string, string> = {
  partOf: "hasPart",
  hasPart: "partOf",
  // Will need additional verification if we want contains as it's only for "Container" items
  contains: "isContainedIn",
  isContainedIn: "contains"
};

export const ITEM_TYPE = "itemType";

export const ITEM = "item";

export const CONNECTIONS_MAP = {
  HAS_PART: "hasPart",
  PART_OF: "partOf",
  CONTAINS: "contains",
  IS_CONTAINED_IN: "isContainedIn"
};
