import React, { useState } from "react";
import Style from "./ValueUnitInput.module.css";

type ValueInputProps = {
  initialValue?: string;
  initialUnit?: string;
  error?: boolean;
  units?: string[];
  onValueChange: (value: string) => {};
  onUnitChange: (value: string) => {};
};

export const ValueUnitInput = ({
  initialValue = "",
  initialUnit = "",
  error,
  units = [],
  onValueChange,
  onUnitChange
}: ValueInputProps) => {
  const [value, setValue] = useState(initialValue);
  const [unit, setUnit] = useState(initialUnit);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  const handleUnitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newUnit = e.target.value;
    setUnit(newUnit);
    if (onUnitChange) {
      onUnitChange(newUnit);
    }
  };

  return (
    <div className={Style.value_unit_input_container}>
      <input
        className={error ? Style.error_input : Style.value_input}
        value={value}
        onChange={handleValueChange}
      />
      <select
        className={Style.unit_select}
        value={unit}
        onChange={handleUnitChange}
      >
        {units.map((unitOption) => (
          <option
            key={unitOption}
            value={unitOption}
          >
            {unitOption}
          </option>
        ))}
      </select>
    </div>
  );
};
