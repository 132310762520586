import React from "react";
import { OptionsControl } from "../../../../data/types";
import Style from "./DropdownOption.module.css";
import PlaceholderImage from "../../../../../../assets/images/placeholder.png";

type DropdownOptionProps = {
  item: Record<string, unknown>;
  optionsControl?: OptionsControl;
};

export const DropdownOption: React.FC<DropdownOptionProps> = ({ item, optionsControl = {} }) => {
  const { displayText = "identifier", imageProperty, subText } = optionsControl;
  return (
    <div className={Style.dropdown_container}>
      {imageProperty && (
        <img
          alt={displayText}
          src={(item[imageProperty] as string) || PlaceholderImage}
          className={Style.dropdown_image_container}
        />
      )}
      <div className={imageProperty && Style.image_text_container}>
        <div className={Style.dropdown_option_title}>{item[displayText]}</div>
        {subText && <div className={Style.dropdown_sub_text}>{item[subText] || "-"}</div>}
      </div>
    </div>
  );
};
