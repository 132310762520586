import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import PropTypes from "prop-types";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import { FeatureConfigurationProvider } from "../../../../domains/feature-configuration-provider";
import DisplayBanner from "../../../../components/display-banner/DisplayBanner";
import InputGroup from "../../../../components/my-facility-v2-components/InputGroup";
import { useDisplayBannerContext } from "../../../../context/DisplayBannerContext/DisplayBannerContext";
import { ReactComponent as MyFacilityIcon } from "../../../../assets/icons/my-facility.svg";
import OnboardItemModal from "components/onboard-item-modal";
import AddEditFormStyle from "./AddEditForm.module.css";
import "./style.css";

const NOT_PROVIDED = "[Not Provided]";

const getModeVerbage = (isCreateMode, entityName, modelDisplayName) => {
  let verbage = {};
  if (isCreateMode) {
    verbage = {
      formTitle: `Add a ${modelDisplayName}`,
      successMessage: `Your ${modelDisplayName} was created successfully!`,
      persistActionText: "Create"
    };
  } else {
    const existingEntityName = entityName || NOT_PROVIDED;
    verbage = {
      formTitle: `Edit ${existingEntityName}`,
      successMessage: `Successfully updated ${existingEntityName}`,
      persistActionText: "Save"
    };
  }

  verbage.inputGroupTitle = `${modelDisplayName} Information`;
  verbage.modelDisplayName = modelDisplayName;

  return verbage;
};

const ERROR_MESSAGE = "Action was not completed. Please review highlighted items and try again.";
export const AddEditForm = ({
  isCreateMode,
  configuration,
  featureId,
  show,
  modelId,
  onCancel,
  onSubmit,
  persistEntityAction,
  entity = null
}) => {
  const [xemelgoClient] = useState(useXemelgoClient());
  const { setShowBanner, setBannerTitle, setBannerHasError } = useDisplayBannerContext();
  const [modalBannerHasError, setModalBannerHasError] = useState(false);
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [entityValues, setEntityValues] = useState({});
  const [showModalBanner, setShowModalBanner] = useState(false);
  const [modalBannerMessage, setModalBannerMessage] = useState("");
  const [modeVerbage, setModeVerbage] = useState({});
  const [hasPersistedEntity, setHasPersistedEntity] = useState(false);

  const setInitialValues = (providedEntity, props) => {
    if (providedEntity == null) {
      return;
    }

    const initialEntityValue = {};
    initialEntityValue.id = providedEntity.id;
    props.forEach((prop) => {
      initialEntityValue[prop.name] = providedEntity[prop.name];
    });

    setEntityValues(initialEntityValue);
  };

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!configuration || !modelId) {
      return cancelCallback;
    }

    const configProvider = FeatureConfigurationProvider.parse(featureId, configuration);
    const modelConfigProvider = configProvider.getModel(modelId);
    const propertyOrders = modelConfigProvider.getValue("propertyOrders", "array", []);
    const modelDisplayName = modelConfigProvider.getValue("displayName", "string", modelId);
    const instanceDisplayProperty = modelConfigProvider.getValue("displayProperty", "string", modelId);
    const propertyMap = modelConfigProvider.getPropertyMap();

    setModeVerbage(getModeVerbage(isCreateMode, entity?.[instanceDisplayProperty], modelDisplayName));

    getProperties(propertyOrders, propertyMap, configProvider).then((props) => {
      if (!cancelled) {
        setProperties(props);
        setInitialValues(entity, props);
        setLoading(false);
      }
    });

    return cancelCallback;
  }, [configuration, modelId]);

  const getProperties = async (propertyOrders, propertyMap) => {
    const props = propertyOrders
      .filter((propertyId) => {
        return propertyMap[propertyId];
      })
      .map((propertyId) => {
        const property = propertyMap[propertyId];
        const {
          displayName,
          __addable,
          __updatable,
          optional,
          optionallyDependsOn,
          unique,
          defaultValue,
          autoPopulate
        } = property;

        const { options } = property;

        const formattedOptions = options?.map((option) => {
          return {
            ...option,
            id: option.key,
            label: option.value,
            value: option.value
          };
        });

        return {
          displayName,
          optional,
          optionallyDependsOn,
          options: formattedOptions,
          editable: __addable,
          disabled: !__updatable,
          name: propertyId,
          unique,
          defaultValue,
          autoPopulate
        };
      });

    return props;
  };

  const verifyPayload = async () => {
    const tempValues = { ...entityValues };
    const requiredProperties = properties.filter((property) => {
      return !property.optional;
    });
    let errorMessage = ERROR_MESSAGE;
    let canSubmit = true;

    requiredProperties.forEach((property) => {
      if (!tempValues[property.name]) {
        canSubmit = false;
        property.error = true;
      } else {
        property.error = false;
      }
    });

    const { isValid, error } = await checkForDuplicate(tempValues);
    if (!isValid) {
      canSubmit = false;
      errorMessage = error;
    }

    if (!canSubmit) {
      setShowModalBanner(true);
      setModalBannerMessage(errorMessage);
      setModalBannerHasError(true);
    }

    setEntityValues(tempValues);
    return canSubmit;
  };

  const checkForDuplicate = async (values) => {
    if (!isCreateMode && entity && values.identifier === entity.identifier) {
      // Identifier is valid if it is unchanged from original value
      return { isValid: true };
    }

    const partnerClient = xemelgoClient.getPartnerClient();
    const partnerResult = await partnerClient.getPartnersByProperty("identifier", [values.identifier]);

    if (partnerResult.length === 0) {
      return { isValid: true };
    }

    const error = `A ${modeVerbage.modelDisplayName} with identifier ${values.identifier} already exists!`;
    const identifierProperty = properties.find((property) => {
      return property.name === "identifier";
    });
    identifierProperty.error = true;

    return { isValid: false, error };
  };

  const handleEdit = (id, value) => {
    const tempEditedProperties = { ...entityValues };
    tempEditedProperties[id] = value;
    setEntityValues(tempEditedProperties);
  };

  const handlePersistenceClick = async (shouldPerformOnSubmitAction = true) => {
    setLoading(true);
    const canSubmit = await verifyPayload();
    if (canSubmit) {
      const persistResult = await persistEntityAction(xemelgoClient, entityValues);
      if (shouldPerformOnSubmitAction) {
        setBannerHasError(!persistResult.isSuccess);
        setBannerTitle(persistResult.isSuccess ? modeVerbage.successMessage : persistResult.failureMessage);
        setShowBanner(true);
        onSubmit();
      } else {
        setModalBannerHasError(!persistResult.isSuccess);
        setModalBannerMessage(persistResult.isSuccess ? modeVerbage.successMessage : persistResult.failureMessage);
        setShowModalBanner(true);
        setEntityValues({});
      }

      setHasPersistedEntity(true);
    }
    setLoading(false);
  };

  const renderLoading = () => {
    return (
      <div className="loading_circle">
        <Spinner animation="border" />
      </div>
    );
  };

  const renderPartners = () => {
    return (
      <div>
        <InputGroup
          title={modeVerbage.inputGroupTitle}
          properties={properties}
          onChange={handleEdit}
          values={entityValues}
          placeholders={entity}
        />
      </div>
    );
  };

  return (
    <OnboardItemModal
      scrollable
      show={show}
      prefix="entity"
      className="entityModal"
      titleIconComponent={
        <MyFacilityIcon
          width={42}
          height={42}
          className={AddEditFormStyle.title_icon}
        />
      }
      submitDisabled={false}
      submitLoading={loading}
      onSubmit={isCreateMode ? () => handlePersistenceClick(false) : handlePersistenceClick}
      onClose={onCancel}
      modalContainerClassName={AddEditFormStyle.modal_container}
      title={modeVerbage.formTitle}
    >
      {loading ? (
        renderLoading()
      ) : (
        <div>
          {showModalBanner && (
            <DisplayBanner
              onCloseBanner={() => {
                setShowModalBanner(false);
              }}
              bannerMessage={modalBannerMessage}
              bannerError={modalBannerHasError}
            />
          )}
          <div>{renderPartners()}</div>
        </div>
      )}
    </OnboardItemModal>
  );
};

AddEditForm.defaultProps = {
  show: false,
  entity: {}
};

AddEditForm.propTypes = {
  isCreateMode: PropTypes.bool.isRequired,
  configuration: PropTypes.shape({
    modelMap: PropTypes.objectOf(
      PropTypes.shape({
        displayName: PropTypes.string,
        propertyOrders: PropTypes.arrayOf(PropTypes.string),
        properties: PropTypes.objectOf(
          PropTypes.shape({
            displayName: PropTypes.string.isRequired
          })
        )
      })
    )
  }).isRequired,
  featureId: PropTypes.string.isRequired,
  show: PropTypes.bool,
  modelId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  persistEntityAction: PropTypes.func.isRequired,
  entity: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    identifier: PropTypes.string,
    description: PropTypes.string
  })
};
