import React, { useMemo } from "react";
import useAssetTrackPageConfigContext from "../../../../contexts/asset-track-page-config-context";
import useAssetTrackPageDataSourceContext from "../../../../contexts/asset-track-page-data-source-context";
import useAssetTrackPageStateContext from "../../../../contexts/asset-track-page-state-context";
import { TAB_OPTION_MAP } from "../../../../data/constants";
import GroupedAssetListTable from "../grouped-asset-list-table";

export const AssetTypeViewTable = () => {
  const { listTableOptions, locationMetricsFields } = useAssetTrackPageConfigContext();
  const { locationTreeMap, locationToTypeMetricsMap } = useAssetTrackPageDataSourceContext();
  const { selectedColumns, sideFilterValue, locationsToDisplay } = useAssetTrackPageStateContext();

  const { pageSize = 10 } = listTableOptions[TAB_OPTION_MAP.assetTypeTab] || {};
  const headers = selectedColumns[TAB_OPTION_MAP.assetTypeTab] || [{ id: "identifier", label: "Asset Type" }];

  const groups = useMemo(() => {
    return locationsToDisplay.map((locationId) => {
      const sideFilterLocationMap = {};
      if (sideFilterValue.locationId && Array.isArray(sideFilterValue.locationId)) {
        sideFilterValue.locationId
          .filter((location) => {
            return location.value;
          })
          .forEach((location) => {
            sideFilterLocationMap[location.id] = true;
          });
      }

      const numSideFilterLocations = Object.keys(sideFilterLocationMap).length;

      // Aggregate asset types at a location
      const aggregateAssetTypeData = locationTreeMap[locationId].childLocations.reduce((acc, childLocationId) => {
        if (numSideFilterLocations > 0 && !sideFilterLocationMap[childLocationId]) {
          return acc;
        }
        const assetTypeMetricsMap = locationToTypeMetricsMap[childLocationId] || {};
        Object.keys(assetTypeMetricsMap).forEach((assetTypeId) => {
          if (!acc[assetTypeId]) {
            acc[assetTypeId] = {
              id: assetTypeId,
              locationId: childLocationId,
              ...assetTypeMetricsMap[assetTypeId].properties
            };
          }

          const assetTypeCounts = assetTypeMetricsMap[assetTypeId].counts;

          locationMetricsFields.forEach((metric) => {
            acc[assetTypeId][metric.id] = (acc[assetTypeId][metric.id] || 0) + assetTypeCounts[metric.id] || 0;
          });
        });
        return acc;
      }, {});

      return {
        id: locationId,
        dataList: Object.values(aggregateAssetTypeData)
      };
    });
  }, [locationToTypeMetricsMap, locationTreeMap, sideFilterValue, locationsToDisplay, locationMetricsFields]);

  return (
    <GroupedAssetListTable
      headers={headers}
      groups={groups}
      pageSize={pageSize}
    />
  );
};
