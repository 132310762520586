import React, { useEffect, useRef, useState } from "react";
import { useXemelgoClient } from "../../../../services/xemelgo-service";

import Style from "./AddTransferOrderStyle.module.css";
import { useAddTransferOrderFeatureConfigContext } from "./contexts/add-transfer-order-feature-config-context/AddTransferOrderFeatureConfigContext";
import BulkCreateTransferOrderFeature from "./features/bulk-create-transfer-order-feature";
import { useAddTransferOrderFeatureStateContext } from "./contexts/add-transfer-order-feature-state-context/AddTransferOrderFeatureStateContext";
import validateCsvData from "./utils/validate-transfer-order-csv-data";
import OnboardItemModal from "../../../../components/onboard-item-modal";
import { ReactComponent as InventoryIcon } from "../../../../assets/icons/inventory.svg";
import StatusPopupComponent from "../../../../components/status-popup-component";
import getHierarchyTransferOrderPayloads from "./utils/get-hierarchy-transfer-order-payloads";
import { STATUS_OPTIONS } from "../../../../components/status-popup-component/data/constants";

const POPUP_DURATION_MS = 7000;

class TransferOrderPayloadItem {
  fields = {
    // string
    aggregation_method: String,

    // number
    quantity_total: Number
  };

  connections = {
    // string (id of node)
    ofItemType: String
  };
}

export const AddTransferOrderFeature = ({ onClose }) => {
  const [itemTypeClient] = useState(useXemelgoClient().getItemTypeClient());
  const [transferClient] = useState(useXemelgoClient().getTransferClient());
  const [locationClient] = useState(useXemelgoClient().getLocationClient());

  const popupTimeoutRef = useRef(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [popupExpired, setPopupExpired] = useState(false);

  const {
    useDefaultLocation,
    fromLocationDefault,
    toLocationDefault,
    transferOrderTitleLabel,
    allowMultipleTransferOrdersPerFile,
    formFields
  } = useAddTransferOrderFeatureConfigContext();

  const { formData, setFormData, submitStatus, setSubmitStatus } = useAddTransferOrderFeatureStateContext();

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    // disable the submit button if we have no data
    setIsSubmitDisabled(!Object.keys(formData).length);
  }, [formData]);

  const onLoad = async () => {
    setIsSubmitDisabled(false);
  };

  /**
   * Submits transfer orders and creates transfer order items.
   * @param {Array} transferOrders - The array of transfer orders to be submitted.
   * @returns {Object} - A dictionary of responses for each transfer order, with the tracking number as the key.
   */
  const submitTransferOrder = async (transferOrders) => {
    // first we need to iterate through all the items to extract their itemTypes
    const itemTypeIdentifiers = [];
    transferOrders.forEach((transferOrder) => {
      transferOrder.items.forEach((item) => {
        itemTypeIdentifiers.push(item.itemType);
      });
    });

    // break it into slices of 50 to manage query sizes
    const itemTypeIdentifierSlices = [];
    for (let i = 0; i < itemTypeIdentifiers.length; i += 50) {
      itemTypeIdentifierSlices.push(itemTypeIdentifiers.slice(i, i + 50));
    }

    // for each slice, issue a query to get the itemTypes and use the results to create a dictionary
    const itemTypeIds = {};
    for (let i = 0; i < itemTypeIdentifierSlices.length; i++) {
      const itemTypeIdentifiers = itemTypeIdentifierSlices[i];
      const itemTypeQuery = await itemTypeClient.getItemTypeByIdentifiers(itemTypeIdentifiers);

      itemTypeQuery.forEach((itemType) => {
        itemTypeIds[itemType.identifier] = itemType.id;
      });
    }

    // now we need to actually issue the command to create each transfer order
    // this will be a dictionary of Promises from the responses, with the key being the tracking number
    const responses = await Promise.all(
      transferOrders.map(async (transferOrder) => {
        const transferItems = [];
        for (let j = 0; j < transferOrder.items.length; j++) {
          const item = transferOrder.items[j];
          const transferItem = new TransferOrderPayloadItem();
          transferItem.fields = {
            aggregation_method: "Manual",
            quantity_total: parseInt(item.quantity)
          };

          transferItem.connections = {
            ofItemType: itemTypeIds[item.itemType]
          };
          transferItems.push(transferItem);
        }

        // tricky, as this can have the same location as the source and destination, which would result in only a single result.
        // So we need to look it up via the identifier. But still get it as a single API call for efficiency reasons.
        const locationIds = await getLocationIds([transferOrder.fromLocation, transferOrder.toLocation]);
        const fromLocationId = locationIds.find((location) => {
          return location.identifier === transferOrder.fromLocation;
        })?.id;
        const toLocationId = locationIds.find((location) => {
          return location.identifier === transferOrder.toLocation;
        })?.id;

        if (!fromLocationId) {
          throw new Error(
            `Could not find location '${transferOrder.fromLocation}' for transfer order '${transferOrder.trackingNumber}'`
          );
        }
        if (!toLocationId) {
          throw new Error(
            `Could not find location '${transferOrder.toLocation}' for transfer order '${transferOrder.trackingNumber}'`
          );
        }

        const thisTrackingResponse = {
          trackingNumber: transferOrder.trackingNumber,
          error: null,
          success: false,
          response: null
        };

        try {
          thisTrackingResponse.response = await transferClient.createTransferOrder({
            transferOrderIdentifier: transferOrder.trackingNumber,
            transferOrderStatus: "InProgress",
            transferFromLocation: fromLocationId,
            transferToLocation: toLocationId,
            itemEntries: transferItems
          });
          thisTrackingResponse.success = true;
          thisTrackingResponse.childOf = transferOrder.childOf;
          thisTrackingResponse.fromLocationId = fromLocationId;
          thisTrackingResponse.toLocationId = toLocationId;
        } catch (e) {
          thisTrackingResponse.error = e;
        }
        return thisTrackingResponse;
      })
    );

    const transferOrderWithChidlrenPayloads = getHierarchyTransferOrderPayloads(responses);

    const promises = transferOrderWithChidlrenPayloads.map(async (payload) => {
      const { identifier, fromLocationId, toLocationId, contains } = payload;
      const thisTrackingResponse = {
        trackingNumber: "",
        error: null,
        success: false,
        response: null
      };
      try {
        thisTrackingResponse.response = await transferClient.createTransferOrder({
          transferOrderIdentifier: identifier,
          transferOrderStatus: "InProgress",
          transferFromLocation: fromLocationId,
          transferToLocation: toLocationId,
          itemClass: "Inventory",
          contains
        });
        thisTrackingResponse.trackingNumber = identifier;
        thisTrackingResponse.success = true;
        thisTrackingResponse.fromLocationId = fromLocationId;
        thisTrackingResponse.toLocationId = toLocationId;
      } catch (e) {
        thisTrackingResponse.error = e;
      }
      return thisTrackingResponse;
    });

    const parentTransferOrders = await Promise.all(promises);

    const responsesDictionary = {};
    responses.forEach((response) => {
      responsesDictionary[response.trackingNumber] = response;
    });
    parentTransferOrders.forEach((transferOrder) => {
      responsesDictionary[transferOrder.trackingNumber] = transferOrder;
    });

    return responsesDictionary;
  };

  /**
   * Retrieves location IDs based on the given location identifiers.
   *
   * @param {string[]} locationIdentifiers - An array of location identifiers.
   * @returns {Promise<{ id: string, identifier: string }[]>} - A promise that resolves to an array of location objects containing their IDs and identifiers.
   */
  const getLocationIds = async (locationIdentifiers) => {
    return (await locationClient.getLocationsByIdentifiers(locationIdentifiers)).map((location) => {
      return { id: location.id, identifier: location.identifier };
    });
  };

  const mapErrorResponseToHeaderMessage = (response) => {
    let reason = response.error.message;
    if (reason.includes("status code 409")) {
      reason = "already exists";
    }
    const message = `${transferOrderTitleLabel} '${response.trackingNumber}' failed to create: ${reason}`;
    return message;
  };

  const onSubmit = async () => {
    setIsSubmitDisabled(true);
    setSubmitStatus(STATUS_OPTIONS.LOADING);
    setStatusMessage(`The ${transferOrderTitleLabel} are being added...`);
    setPopupExpired(false);

    const trackingNumbers = [];
    const getTransferOrder = (trackingNumber) => {
      if (!trackingNumber) {
        throw new Error("Tracking number is required");
      }
      let index = trackingNumbers.findIndex((tn) => {
        return tn.trackingNumber === trackingNumber;
      });
      if (index === -1) {
        // does not exist. Add it and return the index (which is the length - 1)
        index =
          trackingNumbers.push({
            trackingNumber,
            items: [],
            fromLocation: useDefaultLocation ? fromLocationDefault : null,
            toLocation: useDefaultLocation ? toLocationDefault : null
          }) - 1;
      }
      return trackingNumbers[index];
    };
    try {
      const formDataToSubmit = Object.values(formData).map((eachRow) => {
        const { data } = eachRow;
        const updatedData = Object.keys(data).reduce((newData, fieldId) => {
          const { value } = data[fieldId];
          newData[fieldId] = value;
          return newData;
        }, {});
        return updatedData;
      });
      // convert the csv data into an object
      validateCsvData(
        formDataToSubmit,
        getTransferOrder,
        useDefaultLocation,
        formFields?.tracking_number?.connectionWith
      );
      // check to see if we have multiple tracking numbers... if so, check to see if that is allowed
      if (trackingNumbers.length > 1 && !allowMultipleTransferOrdersPerFile) {
        throw new Error(`CSV Must contain only a single ${transferOrderTitleLabel}`);
      }
      // now submit the transfer order
      const results = await submitTransferOrder(trackingNumbers);
      // now we need to check the responses for errors
      const errors = [];
      Object.keys(results).forEach((transferOrder) => {
        const response = results[transferOrder] || {};
        if (!response?.success) {
          errors.push(mapErrorResponseToHeaderMessage(response));
        }
      });

      if (errors.length > 0) {
        throw new Error(errors.join("\n"));
      }
      setStatusMessage(`${transferOrderTitleLabel} created successfully`);
    } catch (e) {
      setStatusMessage(e.message);
      setSubmitStatus(STATUS_OPTIONS.ERROR);
      popupTimeoutRef.current = setTimeout(() => {
        setPopupExpired(true);
      }, POPUP_DURATION_MS);
      return;
    }

    setFormData({});
    popupTimeoutRef.current = setTimeout(() => {
      setPopupExpired(true);
    }, POPUP_DURATION_MS);
    setSubmitStatus(STATUS_OPTIONS.SUCCESS);
  };

  return (
    <OnboardItemModal
      title={`Add ${transferOrderTitleLabel}`}
      titleIconComponent={
        <div className={Style.title_icon}>
          <InventoryIcon
            width={25}
            height={25}
          />
        </div>
      }
      onClose={onClose}
      popupComponent={
        <StatusPopupComponent
          showPopup={!popupExpired && submitStatus !== STATUS_OPTIONS.NONE && statusMessage}
          message={statusMessage}
          status={submitStatus}
        />
      }
      onSubmit={onSubmit}
      submitDisabled={isSubmitDisabled}
      submitLoading={submitStatus === STATUS_OPTIONS.LOADING}
      modalContainerClassName={Style.modal_container}
    >
      <div className={Style.modal_body_container}>
        <BulkCreateTransferOrderFeature />
      </div>
    </OnboardItemModal>
  );
};
