import React, { useState, useEffect, useCallback, useMemo } from "react";
import { XemelgoClient, LocationTree, LocationTreeNode } from "@xemelgo/x-client";
import Modal from "../../components/modal";
import Style from "./ResetItemsActionModal.module.css";
import { useXemelgoClient } from "../../services/xemelgo-service";
import Spinner from "../../components/spinner";
import FreeTextSearchInput from "../../components/free-text-search-input";
import CheckboxLabel from "../../components/checkbox-label";
import { useConfigurationProvider } from "../../services/soft-cache-service";

interface ResetItemsActionModalProps {
  title?: string;
  locationCategories?: string[];
  onCloseModal?: () => void;
  onPreConfirm?: () => void;
  onPostConfirm?: (error?: Error) => void;
}

interface DisplayedLocation extends LocationTreeNode {
  displayName: string;
}

export const ResetItemsActionModal: React.FC<ResetItemsActionModalProps> = ({
  title = "Reset Items",
  onCloseModal = () => {},
  onPostConfirm = () => {},
  onPreConfirm = () => {},
  locationCategories = ["all"]
}) => {
  const xemelgoClient: XemelgoClient | null = useXemelgoClient();

  const locationRoleConfig = useConfigurationProvider().config.features.locationRole || {};

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [freeTextSearchInputString, setFreeTextSearchInputString] = useState<string>("");
  const [locationTreeMap, setLocationTreeMap] = useState<LocationTree>({});
  const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>([]);

  const onLoad = async () => {
    setIsLoading(true);
    const locationClient = xemelgoClient?.getLocationClient();
    const newLocationTreeMap = await locationClient.getLocationTree(
      ["identifier"],
      !locationCategories || locationCategories?.includes("all") ? undefined : locationCategories
    );
    setLocationTreeMap(newLocationTreeMap);
    setIsLoading(false);
  };

  useEffect(() => {
    onLoad();
  }, [locationCategories]);

  const onConfirm = useCallback(async () => {
    onPreConfirm();
    setIsSubmitting(true);

    const trackPageClient = xemelgoClient?.getTrackPageClient();

    try {
      await trackPageClient.resetInactiveTrackingSessions(locationRoleConfig.enabled, selectedLocationIds);

      setSelectedLocationIds([]);

      onPostConfirm();
    } catch (error: any) {
      onPostConfirm(error as Error);
    } finally {
      setIsSubmitting(false);
    }
  }, [xemelgoClient, onPreConfirm, onPostConfirm, selectedLocationIds]);

  const locationList = useMemo<DisplayedLocation[]>(() => {
    return Object.keys(locationTreeMap)
      .map((locationId: string) => {
        const { name, identifier } = locationTreeMap[locationId];
        return {
          ...locationTreeMap[locationId],
          displayName: `${name}${identifier ? ` (${identifier})` : ""}`
        };
      })
      .filter((eachLocation) => {
        return (eachLocation?.displayName?.toLowerCase() || "").includes(
          freeTextSearchInputString?.toLowerCase() || ""
        );
      })
      .sort((a, b) => {
        return a.displayName.localeCompare(b.displayName);
      });
  }, [locationTreeMap, freeTextSearchInputString]);

  return (
    <Modal
      title={title}
      onCancel={onCloseModal}
      onConfirm={onConfirm}
      confirmDisabled={isSubmitting || isLoading || selectedLocationIds.length === 0}
      closeOnClickOutside={false}
    >
      <div className={Style.body_container}>
        {isLoading || isSubmitting ? (
          <div className={Style.spinner_container}>
            <Spinner />
          </div>
        ) : (
          <>
            <p className={Style.body_heading}>Select the location that you want to reset:</p>
            <FreeTextSearchInput
              value={freeTextSearchInputString}
              onChangeText={setFreeTextSearchInputString}
            />
            {locationList.length ? (
              <div className={Style.location_list_container}>
                <CheckboxLabel
                  containerClassName={Style.location_list_item}
                  id="select-all-option"
                  name="select-all-option"
                  isChecked={selectedLocationIds.length === locationList.length}
                  label="Select All"
                  onClick={() => {
                    if (selectedLocationIds.length === locationList.length) {
                      setSelectedLocationIds([]);
                    } else {
                      setSelectedLocationIds(
                        locationList.map(({ id }) => {
                          return id;
                        })
                      );
                    }
                  }}
                />
                {locationList.map((location) => {
                  const isChecked = selectedLocationIds.includes(location.id);
                  return (
                    <CheckboxLabel
                      key={location.id}
                      containerClassName={Style.location_list_item}
                      id={location.id}
                      name={location.id}
                      isChecked={isChecked}
                      onClick={() => {
                        if (isChecked) {
                          setSelectedLocationIds((currentValue: string[]) => {
                            return currentValue.filter((id) => {
                              return id !== location.id;
                            });
                          });
                        } else {
                          setSelectedLocationIds((currentValue: string[]) => {
                            return [...currentValue, location.id];
                          });
                        }
                      }}
                      label={location.displayName}
                    />
                  );
                })}
              </div>
            ) : (
              <p>{` No locations found. `}</p>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};
