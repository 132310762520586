import React from "react";
import useKioskConfigContext from "../../contexts/kiosk-config-context";
import useKioskStateContext from "../../contexts/kiosk-state-context";
import useKioskSearchParams from "../../hooks/use-kiosk-search-params";
import { ACTION_TYPE_TO_COLOR, ACTION_TYPE_TO_ICON } from "./data/constants";
import GridView from "./components/grid-view";

export const ActionsPage = () => {
  const { setActionId } = useKioskSearchParams();
  const { actionsMap } = useKioskConfigContext();
  const { setLoading } = useKioskStateContext();

  return (
    <GridView
      cards={Object.values(actionsMap).map(({ actionType, ...rest }) => {
        return {
          ...rest,
          type: actionType
        };
      })}
      onOptionClick={(optionId) => {
        setActionId(optionId);
        setLoading(true);
      }}
      defaultColorMap={ACTION_TYPE_TO_COLOR}
      defaultIconMap={ACTION_TYPE_TO_ICON}
    />
  );
};
