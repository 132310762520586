import React, { useMemo } from "react";
import { QRCodeSVG } from "qrcode.react";
import { KioskItem } from "../../../../../../../../../../../../data/types";
import Style from "./ConfirmRemovalModal.module.css";
import Modal from "../../../../../../../../../../../../../../components/modal";

interface ConfirmRemovalModalProps {
  item: KioskItem;
  onConfirm: () => void;
  onCancel: () => void;
  showQRCode?: boolean;
  headers?: {
    id: string;
    label?: string;
  }[];
}

export const ConfirmRemovalModal = ({
  item,
  headers = [
    { id: "type.identifier", label: "SKU" },
    { id: "type.name", label: "Name" },
    { id: "type.description", label: "Description" },
    { id: "vid", label: "EPC" }
  ],
  onConfirm,
  onCancel,
  showQRCode = true
}: ConfirmRemovalModalProps) => {
  const processedItem = useMemo<Record<string, unknown>>(() => {
    const { type } = item;

    const itemTypeFields = Object.keys(type).reduce((acc, key) => {
      const newKey = `type.${key}`;
      acc[newKey] = type[key as keyof typeof type] || "";
      return acc;
    }, {} as Record<string, string>);

    return {
      ...item,
      ...itemTypeFields
    };
  }, [item]);

  return (
    <Modal
      title="Confirm Removal of Item"
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButtonText="I have removed the item"
      cancelButtonText="Back"
      modalDialogClassName={Style.modal_dialog}
    >
      <div className={Style.container}>
        <div className={Style.text}>
          Confirm that you have physically removed the item to continue.
          {showQRCode
            ? ` Scan the QR code in the "Search and Find" app using your handheld device to help locate the item.`
            : ""}
        </div>
        <div className={Style.bottom_container}>
          <div className={Style.item_info}>
            {headers.map((header) => {
              if (!processedItem[header.id]) {
                return null;
              }

              return (
                <div
                  key={header.id}
                  className={Style.item_info_text}
                >
                  <span className={Style.item_info_label}>{`${header.label}: `}</span>
                  {processedItem[header.id]}
                </div>
              );
            })}
          </div>
          {showQRCode && processedItem.vid && (
            <div className={Style.qr_code_containercode}>
              <QRCodeSVG
                value={String(processedItem.vid)}
                size={85}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
