import React, { useEffect, useMemo, useState } from "react";
import { TransferOrder } from "@xemelgo/x-client";
import useKioskConfigContext from "../../../../../../contexts/kiosk-config-context";
import useKioskStateContext from "../../../../../../contexts/kiosk-state-context";
import { STATUS_OPTIONS } from "../../../../../../../../components/status-popup-component/data/constants";
import { KIOSK, KIOSK_STEPS } from "../../../../../../../../constants/mixpanel-constant/kiosk";
import useMixpanelContext from "../../../../../../../../context/mixpanel-context";
import SubmissionConfirmationModal from "./features/submission-confirmation";
import usePrintService from "../../../../../../../../hooks/use-print-service";
import getTransferOrderUpdates from "./utils/get-transfer-order-updates";
import useTransferOrderActionsConfigContext from "../../contexts/transfer-order-actions-config-context";
import submitTransferOrderAction from "../../utils/submit-transfer-order-action";
import ContainerAssociationModal from "../container-association-modal";
import useKioskSearchParams from "../../../../../../hooks/use-kiosk-search-params";
import MissingScanModal from "./components/missing-scan-modal";
import { GPO_SCENARIOS } from "../../data/constants";
import UnexpectedItemsModal from "../../components/unexpected-items-modal";
import { ExistingTransferOrderContainer, NewTransferOrderContainer, TransferOrderStage } from "../../data/types";
import { KioskItem } from "../../../../../../data/types";

interface SubmissionWorkflowProps {
  onClose: (clearUnexpectedItems?: boolean) => void;
  transferOrders: TransferOrder[];
  newTransferOrderIdentifier?: string;
  stage: TransferOrderStage;
  existingContainer?: ExistingTransferOrderContainer;
  newContainer?: NewTransferOrderContainer;
  unexpectedItems?: KioskItem[];
  hasMissingItems?: boolean;
  handleGPO?: (scenario: string) => void;
  onClearItem: (itemTypeId: string, vid: string) => void;
}

export const SubmissionWorkflow = ({
  transferOrders,
  newTransferOrderIdentifier,
  stage,
  existingContainer,
  newContainer,
  unexpectedItems = [],
  hasMissingItems,
  handleGPO = () => {},
  onClearItem,
  onClose
}: SubmissionWorkflowProps) => {
  const { setActionId } = useKioskSearchParams();
  const { sendMixPanelEvent } = useMixpanelContext();
  const { setSubmitStatus, setStatusMessage, panelValues, itemTypeReports, itemByTag } = useKioskStateContext();
  const { action, skipSubmission, printOptions } = useKioskConfigContext();
  const { containerAssociationOptions, unexpectedScanTableHeaders, useMissingItemModal, GPOScenarioMap } =
    useTransferOrderActionsConfigContext();

  const [showMissingScanModal, setShowMissingScanModal] = useState(useMissingItemModal && hasMissingItems);
  const [showOverview, setShowOverview] = useState(true);

  const printService = usePrintService(
    printOptions.enabled,
    printOptions.printType,
    printOptions.customTemplate || printOptions.printConfig,
    "kiosk"
  );
  const { printItems, printTime } = printService;

  const containerIdentifier = newContainer?.identifier || existingContainer?.identifier;
  const printContainerTags = containerAssociationOptions.enabled && printOptions.enabled && containerIdentifier;

  const numItems = useMemo(() => {
    return itemTypeReports.reduce((acc: number, curr) => {
      return acc + curr.quantityScanned;
    }, 0);
  }, [itemTypeReports]);

  useEffect(() => {
    if (numItems === 0) {
      onClose();
    }
  }, [numItems]);

  useEffect(() => {
    if (unexpectedItems.length && GPOScenarioMap[GPO_SCENARIOS.UNEXPECTED_ITEM_ON_SUBMIT]) {
      handleGPO(GPO_SCENARIOS.UNEXPECTED_ITEM_ON_SUBMIT);
      return;
    }
    if (hasMissingItems && GPOScenarioMap[GPO_SCENARIOS.MISSING_ITEM_ON_SUBMIT]) {
      handleGPO(GPO_SCENARIOS.MISSING_ITEM_ON_SUBMIT);
      return;
    }

    handleGPO(GPO_SCENARIOS.SUBMIT_START);
  }, [hasMissingItems]);

  const itemsInOtherOrder = useMemo(() => {
    const currentTransferOrderIds = new Set(
      transferOrders.map((order) => {
        return order.id;
      })
    );
    return Object.values(itemByTag).filter((item) => {
      const { associatedWithTransferOrder } = item;
      return associatedWithTransferOrder && !currentTransferOrderIds.has(associatedWithTransferOrder.id);
    });
  }, [itemByTag]);

  const onConfirm = async () => {
    setSubmitStatus(STATUS_OPTIONS.LOADING);
    setStatusMessage(`Submitting ${action.label}`);

    const detectorId = panelValues.readerLocation?.detectorId;

    try {
      const params = {
        existingTransferOrders: transferOrders,
        newTransferOrderIdentifier,
        existingContainer,
        newContainer,
        stage,
        itemTypeReports,
        detectorId,
        printOptions,
        printTime,
        printItems,
        actionType: action.actionType,
        containerTypeIdentifier: containerAssociationOptions.containerTypeIdentifier,
        itemsInOtherOrder
      };

      if (!skipSubmission) {
        await submitTransferOrderAction(params);
      }

      setSubmitStatus(STATUS_OPTIONS.SUCCESS);
      handleGPO(GPO_SCENARIOS.SUBMIT_SUCCESS);
      setStatusMessage(`The items have been verified.`);
      sendMixPanelEvent(KIOSK, KIOSK_STEPS.SUBMISSION_SUCCESSFUL, { action: action.actionType });
    } catch (e) {
      setSubmitStatus(STATUS_OPTIONS.ERROR);
      handleGPO(GPO_SCENARIOS.SUBMIT_FAILURE);
      setStatusMessage(`Failed to verify the items. Please contact Xemelgo Support for assistance.`);
      sendMixPanelEvent(KIOSK, KIOSK_STEPS.SUBMISSION_FAILED, {
        action: action.actionType,
        error: JSON.stringify(e)
      });
    }

    setActionId();
  };

  return unexpectedItems.length ? (
    <UnexpectedItemsModal
      items={unexpectedItems}
      headers={unexpectedScanTableHeaders}
      onConfirm={() => {
        onClose(true);
      }}
    />
  ) : showMissingScanModal ? (
    <MissingScanModal
      title={action.label}
      onCancel={onClose}
      onConfirm={() => {
        setShowMissingScanModal(false);
      }}
    />
  ) : !showOverview && printContainerTags ? (
    <ContainerAssociationModal
      numItems={numItems}
      containerIdentifier={containerIdentifier}
      onConfirm={onConfirm}
      onCancel={() => {
        setShowOverview(true);
      }}
      stageActionLabel={stage.actionLabel}
      printService={printService}
    />
  ) : (
    <SubmissionConfirmationModal
      onCancel={onClose}
      confirmText={printContainerTags ? "Next" : "Submit"}
      onConfirm={() => {
        if (printContainerTags) {
          setShowOverview(false);
        } else {
          onConfirm();
        }
      }}
      stageStatus={stage.statusLabel}
      containerIdentifier={newContainer?.identifier || existingContainer?.identifier}
      transferOrderUpdates={getTransferOrderUpdates(transferOrders, itemTypeReports, stage.id)}
      itemTypeReports={itemTypeReports}
      itemsInOtherOrder={itemsInOtherOrder}
      onClearItem={onClearItem}
    />
  );
};
