import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { LocationTree } from "@xemelgo/x-client";
import useAssetTrackPageConfigContext from "../asset-track-page-config-context";
import useFetchAssets, { AssetType } from "./hooks/use-fetch-assets";
import useAssetTrackPageStateContext from "../asset-track-page-state-context";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import { useXemelgoAppsyncClient } from "../../../../services/xemelgo-appsync-service";

interface LocationHierarchy {
  [key: string]: {
    [key: string]: LocationHierarchy;
  };
}
type AssetTrackPageDataSourceContextType = {
  assetMap: Record<string, AssetType>;
  areLocationsLoading: boolean;
  locationMetricsLoading: boolean;
  assetsLoading: boolean;
  locationToAssets: Record<string, AssetType[]>;
  locationHierarchy: LocationHierarchy;
  locationTreeMap: LocationTree;
  setLocationTreeMap: (locationTree: LocationTree) => void;
  locationToMetricFieldsMap: Record<string, any>;
  setLocationToMetricFieldsMap: (locationToMetricFieldsMap: Record<string, any>) => void;
  locationToTypeMetricsMap: Record<string, any>;
  setLocationToTypeMetricsMap: (locationToTypeMetricsMap: Record<string, any>) => void;
  flatCategoryTrees: Record<string, { depth: number; identifier: string }>;
  setFlatCategoryTrees: (flatCategoryTrees: Record<string, { depth: number; identifier: string }>) => void;
};

const AssetTrackPageDataSourceContext = createContext<AssetTrackPageDataSourceContextType>(
  {} as AssetTrackPageDataSourceContextType
);

export const useAssetTrackPageDataSourceContext = () => {
  return useContext(AssetTrackPageDataSourceContext);
};

export const AssetTrackPageDataSourceContextProvider = ({ children }: { children: ReactNode }) => {
  const client = useXemelgoClient();
  const appsyncClient = useXemelgoAppsyncClient();
  const { selectedLocationId } = useAssetTrackPageStateContext();
  const {
    listTableOptions,
    ignoredItemStates,
    unknownLocationControl,
    locationCategories,
    locationMetricsFields,
    isLoading: isConfigLoading
  } = useAssetTrackPageConfigContext();
  const [locationTreeMap, setLocationTreeMap] = useState<LocationTree>({});
  const [locationHierarchy, setLocationHierarchy] = useState<LocationHierarchy>({});
  const [locationToMetricFieldsMap, setLocationToMetricFieldsMap] = useState({});
  const [locationToTypeMetricsMap, setLocationToTypeMetricsMap] = useState<Record<string, Record<string, any>>>({});
  const [flatCategoryTrees, setFlatCategoryTrees] = useState<Record<string, { depth: number; identifier: string }>>({});
  const [areLocationsLoading, setAreLocationsLoading] = useState(true);
  const [locationMetricsLoading, setLocationMetricsLoading] = useState(true);
  const [typeCategoriesUpdated, setTypeCategoriesUpdated] = useState(false);

  useEffect(() => {
    if (!isConfigLoading) {
      fetchLocationTree();
    }
  }, [isConfigLoading]);

  useEffect(() => {
    if (
      !typeCategoriesUpdated &&
      Object.keys(flatCategoryTrees).length &&
      Object.keys(locationToTypeMetricsMap).length
    ) {
      updateTypeCategoryInformation();
    }
  }, [typeCategoriesUpdated, flatCategoryTrees, locationToTypeMetricsMap]);

  const selection = useMemo(() => {
    return [
      "properties",
      "timestamp",
      "result",
      "param",
      "groupKey",
      "properties/id",
      "properties/itemTypeCategoryIds",
      "properties/trackingSessionId",
      ...listTableOptions.asset.headers.map((header) => {
        return `properties/${header.id}`;
      })
    ];
  }, [listTableOptions]);

  const {
    isLoading: assetsLoading,
    assetMap,
    locationToAssets
  } = useFetchAssets(locationTreeMap, selection, ignoredItemStates, flatCategoryTrees, selectedLocationId);

  const fetchLocationTree = async () => {
    setAreLocationsLoading(true);
    const locationAttributes = ["id", "name", "identifier"];

    const locationClient = client.getLocationClient();
    const newLocationTree = await locationClient.getLocationTree(locationAttributes, locationCategories);

    if (unknownLocationControl?.enabled && unknownLocationControl?.properties?.id) {
      newLocationTree[unknownLocationControl?.properties?.id] = {
        childLocations: [unknownLocationControl?.properties?.id],
        name: unknownLocationControl.properties?.name || "Unknown",
        category: "Asset",
        ...unknownLocationControl?.properties
      };
    }

    const newLocationHierarchy: LocationHierarchy = {};
    Object.keys(newLocationTree).forEach((locationId) => {
      const location = newLocationTree[locationId];
      if (!location.directParentId) {
        newLocationHierarchy[locationId] = getSubTree(locationId, newLocationTree);
      }
    });

    setLocationHierarchy(newLocationHierarchy);
    setLocationTreeMap(newLocationTree);
    setAreLocationsLoading(false);

    if (Object.keys(newLocationTree).length) {
      fetchLocationMetrics(newLocationTree);
      fetchCategories();
    }

    return newLocationTree;
  };

  const fetchLocationMetrics = async (locationTree: LocationTree) => {
    setLocationMetricsLoading(true);
    const assetAppsyncClient = appsyncClient.getAssetClient();

    const locationIds = Object.keys(locationTree);

    const itemTypeMetricsPromise = assetAppsyncClient.getMetricsByLocationIds("itemType", locationIds);
    const locationMetricsMap = await assetAppsyncClient.getMetricsByLocationIds("items", locationIds);

    const { aggregateRootMetrics, newLocationMetricsFields } = locationIds.reduce(
      (
        accumulator: { aggregateRootMetrics: Record<string, number>; newLocationMetricsFields: Record<string, any[]> },
        locationId
      ) => {
        const metrics: { value: any; id: string; label: string; hiddenOnTable?: boolean }[] = [];
        locationMetricsFields.forEach((metric) => {
          if (locationMetricsMap[locationId] && locationMetricsMap[locationId].counts[metric.id] !== undefined) {
            const metricValue = locationMetricsMap[locationId].counts[metric.id];
            metrics.push({
              ...metric,
              value: metricValue
            });

            if (!locationTree[locationId].directParentId) {
              accumulator.aggregateRootMetrics[metric.id] =
                (accumulator.aggregateRootMetrics[metric.id] || 0) + metricValue;
            }
          }
        });
        accumulator.newLocationMetricsFields[locationId] = metrics;
        return accumulator;
      },
      { aggregateRootMetrics: {}, newLocationMetricsFields: {} }
    );

    const rootMetrics = locationMetricsFields.map((metric) => {
      return {
        ...metric,
        value: aggregateRootMetrics[metric.id]
      };
    });
    newLocationMetricsFields.root = rootMetrics;

    setLocationToMetricFieldsMap(newLocationMetricsFields);
    setLocationToTypeMetricsMap(await itemTypeMetricsPromise);
    setLocationMetricsLoading(false);
  };

  const fetchCategories = async () => {
    const categoryClient = client.getCategoryClient();
    setFlatCategoryTrees(await categoryClient.getFlatCategoryTrees());
    // just do update type category here
  };

  const getSubTree = (locationId: string, locationTree: LocationTree) => {
    const location = locationTree[locationId];
    const subTree: LocationHierarchy = {};
    subTree[locationId] = {};
    for (const childLocationId of location.childLocations) {
      if (childLocationId !== locationId && locationTree[childLocationId].directParentId === locationId) {
        subTree[childLocationId] = getSubTree(childLocationId, locationTree);
      }
    }
    return subTree;
  };

  const updateTypeCategoryInformation = () => {
    if (Object.keys(locationToTypeMetricsMap).length) {
      const newTypeMap: Record<string, Record<string, any>> = { ...locationToTypeMetricsMap };

      Object.keys(newTypeMap).forEach((locationId) => {
        Object.keys(newTypeMap[locationId]).forEach((typeId) => {
          const type = { ...locationToTypeMetricsMap[locationId][typeId] };
          const { categoryIds = [] } = type.properties;

          categoryIds.forEach((categoryId: string) => {
            const category = flatCategoryTrees[categoryId] || {};
            if (category.depth === 0) {
              newTypeMap[locationId][typeId].properties.itemTypeCategory = category.identifier;
            }
            if (category.depth === 1) {
              newTypeMap[locationId][typeId].properties.itemTypeSubcategory = category.identifier;
            }
          });
        });
      });

      setTypeCategoriesUpdated(true);
      setLocationToTypeMetricsMap(newTypeMap);
    }
  };

  return (
    <AssetTrackPageDataSourceContext.Provider
      value={{
        assetMap,
        areLocationsLoading,
        locationMetricsLoading,
        assetsLoading,
        locationToAssets,
        locationHierarchy,
        locationTreeMap,
        setLocationTreeMap,
        locationToMetricFieldsMap,
        setLocationToMetricFieldsMap,
        locationToTypeMetricsMap,
        setLocationToTypeMetricsMap,
        flatCategoryTrees,
        setFlatCategoryTrees
      }}
    >
      {children}
    </AssetTrackPageDataSourceContext.Provider>
  );
};

AssetTrackPageDataSourceContextProvider.defaultProps = {
  children: null
};

AssetTrackPageDataSourceContextProvider.propTypes = {
  children: PropTypes.element
};
