import React, { useState } from "react";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav } from "reactstrap";
import PropTypes from "prop-types";
import Style from "./CollapsibleListView.module.css";
import { ListedItem } from "./listed-item";
import "./style.css";

export { ListedItem };

export const CollapsibleListView = ({ title, content, className, openAtStart, isAlwaysExpanded }) => {
  const [showItems, setShowItems] = useState(openAtStart || isAlwaysExpanded);

  const toggleItems = () => {
    if (!isAlwaysExpanded) {
      setShowItems(!showItems);
    }
  };

  return (
    <div className={`${Style.levels_navbar} ${className}`}>
      <Navbar
        color="faded"
        light
        className={Style.navbar}
      >
        <NavbarBrand className={`${Style.level_view_header} ${isAlwaysExpanded ? Style.no_pointer : ""}`}>
          <div className={Style.toggler_container}>
            {!isAlwaysExpanded && (
              <NavbarToggler
                onClick={toggleItems}
                className={showItems ? Style.active_toggler : Style.not_active_toggler}
              />
            )}
          </div>
          <div
            onClick={toggleItems}
            tabIndex="0"
            role="button"
            className={`${Style.toggler_title} ${!isAlwaysExpanded ? Style.toggler_title_with_padding : ""}`}
          >
            {title}
          </div>
        </NavbarBrand>
        <Collapse
          isOpen={showItems}
          navbar
        >
          <Nav navbar>{content}</Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

CollapsibleListView.defaultProps = {
  className: "",
  isAlwaysExpanded: false,
  openAtStart: false
};

CollapsibleListView.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  openAtStart: PropTypes.bool,
  isAlwaysExpanded: PropTypes.bool
};
