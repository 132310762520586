import React, { createContext, ReactNode, useContext, useState, useEffect, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { useStore } from "react-hookstore";
import { MULTI_SELECT_ACTION_ICONS, TAB_OPTION_MAP } from "../../data/constants";
import useAssetTrackPageConfigContext from "../asset-track-page-config-context";
import { AssetTrackPageHeader, MultiSelectOption } from "../asset-track-page-config-context/data/types";
import { Item } from "../../../../components/paginated-list-table-with-types";
import { LocationGroup } from "./data/types";
import { userProfileStore } from "../../../../state-managements/stores/user-profile-store";

type AssetTrackPageStateContextType = {
  selectedLocationId: string;
  setSelectedLocationId: (newSelectedLocationId: string) => void;
  selectedTab: string;
  setSelectedTab: (newSelectedTab: string) => void;
  searchInput: string;
  setSearchInput: (newSearchInput: string) => void;
  locationSortOrder: string;
  setLocationSortOrder: (newLocationSortOrder: string) => void;
  expandedLocations: Record<string, boolean>;
  setExpandedLocations: (newExpandedLocations: Record<string, boolean>) => void;
  selectedAssetIdMap: Record<string, boolean>;
  setSelectedAssetIdMap: (newSelectedAssetIdMap: Record<string, boolean>) => void;
  displayedData: LocationGroup[];
  setDisplayedData: (newDisplayedData: LocationGroup[]) => void;
  selectedColumns: Record<string, any[]>;
  setSelectedColumns: (newSelectedColumns: Record<string, any[]>) => void;
  applySideFilterFn: (item: Item) => boolean;
  setApplySideFilterFn: (newApplySideFilterFn: () => boolean) => void;
  sideFilterValue: Record<string, any>;
  setSideFilterValue: (newSideFilterValue: Record<string, any>) => void;
  locationsToDisplay: string[];
  setLocationsToDisplay: (newLocationsToDisplay: string[]) => void;
  enabledMultiSelectOptions: MultiSelectOption[];
};

const initialState: AssetTrackPageStateContextType = {
  selectedLocationId: "",
  setSelectedLocationId: () => {},
  selectedTab: TAB_OPTION_MAP.assetTab,
  setSelectedTab: () => {},
  searchInput: "",
  setSearchInput: () => {},
  applySideFilterFn: () => {
    return true;
  },
  setApplySideFilterFn: () => {},
  selectedAssetIdMap: {},
  setSelectedAssetIdMap: () => {},
  expandedLocations: {},
  setExpandedLocations: () => {},
  selectedColumns: {},
  setSelectedColumns: () => {},
  sideFilterValue: {},
  setSideFilterValue: () => {},
  displayedData: [],
  setDisplayedData: () => {},
  locationSortOrder: "asc",
  setLocationSortOrder: () => {},
  locationsToDisplay: [],
  setLocationsToDisplay: () => {},
  enabledMultiSelectOptions: []
};

const AssetTrackPageStateContext = createContext<AssetTrackPageStateContextType>(initialState);

export const useAssetTrackPageStateContext = () => {
  return useContext(AssetTrackPageStateContext);
};

export const AssetTrackPageStateContextProvider = ({ children }: { children: ReactNode }) => {
  const { listTableOptions, multiSelectOptions } = useAssetTrackPageConfigContext();

  const [userProfile] = useStore(userProfileStore);
  const [expandedLocations, setExpandedLocations] = useState(initialState.expandedLocations);
  const [searchInput, setSearchInput] = useState(initialState.searchInput);
  const [locationSortOrder, setLocationSortOrder] = useState(initialState.locationSortOrder);
  const [selectedAssetIdMap, setSelectedAssetIdMap] = useState(initialState.selectedAssetIdMap);
  const [selectedLocationId, setSelectedLocationIdState] = useState(initialState.selectedLocationId);
  const [selectedTab, setSelectedTabState] = useState(initialState.selectedTab);
  const [displayedData, setDisplayedData] = useState(initialState.displayedData);
  const [selectedColumns, setSelectedColumns] = useState(initialState.selectedColumns);
  const [sideFilterValue, setSideFilterValue] = useState(initialState.sideFilterValue);
  const [applySideFilterFn, setApplySideFilterFn] = useState(() => {
    return initialState.applySideFilterFn;
  });
  const [locationsToDisplay, setLocationsToDisplay] = useState(initialState.locationsToDisplay);

  const { search, pathname } = useLocation();
  const history = useHistory();

  const setSelectedLocationId = (newSelectedLocationId: string) => {
    const newParsedString = { ...queryString.parse(search), locationId: newSelectedLocationId };
    const newSearchString = queryString.stringify(newParsedString);
    setSelectedLocationIdState(newSelectedLocationId);
    history.push(`${pathname}${newSearchString ? `?${newSearchString}` : ""}`);
  };

  const setSelectedTab = (newSelectedTab: string) => {
    let newTab = newSelectedTab;
    if (newSelectedTab !== TAB_OPTION_MAP.assetTab && newSelectedTab !== TAB_OPTION_MAP.assetTypeTab) {
      newTab = TAB_OPTION_MAP.assetTab;
    }
    const newParsedString = { ...queryString.parse(search), tab: newTab };
    const newSearchString = queryString.stringify(newParsedString);
    setSelectedTabState(newTab);
    history.replace(`${pathname}${newSearchString ? `?${newSearchString}` : ""}`);
  };

  useEffect(() => {
    const newSelectedColumns: Record<string, AssetTrackPageHeader[]> = {};

    Object.values(TAB_OPTION_MAP).forEach((tabId) => {
      const tab = listTableOptions[tabId];

      if (!tab) {
        return;
      }

      const defaultColumns = tab.headers.filter((header) => {
        return header.defaultColumn;
      });

      newSelectedColumns[tabId] = defaultColumns;
    });

    setSelectedColumns(newSelectedColumns);
  }, [listTableOptions]);

  useEffect(() => {
    if (search) {
      const { locationId, tab: tabId } = queryString.parse(search);

      if (typeof tabId === "string" && tabId !== selectedTab) {
        setSelectedTab(tabId || initialState.selectedTab);
      }
      if (locationId !== selectedLocationId) {
        if (locationId === null) {
          setSelectedLocationIdState(initialState.selectedLocationId);
        } else if (typeof locationId === "string") {
          setSelectedLocationIdState(locationId);
        }
      }
    }
  }, [search]);

  useEffect(() => {
    setSearchInput(initialState.searchInput);
  }, [selectedTab, selectedLocationId]);

  const enabledMultiSelectOptions = useMemo(() => {
    const isAdmin = !!userProfile?.isUserSuperAdmin;
    return multiSelectOptions
      .filter((option) => {
        return !option.adminOnly || isAdmin;
      })
      .map((action) => {
        const IconComponent = MULTI_SELECT_ACTION_ICONS[action.id];
        return { ...action, IconComponent: IconComponent || null };
      });
  }, [multiSelectOptions, userProfile]);

  return (
    <AssetTrackPageStateContext.Provider
      value={{
        selectedLocationId,
        setSelectedLocationId,
        selectedTab,
        setSelectedTab,
        searchInput,
        setSearchInput,
        locationSortOrder,
        setLocationSortOrder,
        expandedLocations,
        setExpandedLocations,
        selectedAssetIdMap,
        setSelectedAssetIdMap,
        displayedData,
        setDisplayedData,
        selectedColumns,
        setSelectedColumns,
        applySideFilterFn,
        setApplySideFilterFn,
        sideFilterValue,
        setSideFilterValue,
        locationsToDisplay,
        setLocationsToDisplay,
        enabledMultiSelectOptions
      }}
    >
      {children}
    </AssetTrackPageStateContext.Provider>
  );
};
