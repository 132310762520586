// this function converts a string to a regex string
// for example, "abc" will be converted to ".*[Aa][Bb][Cc].*"
// this is useful for searching for a string in a case-insensitive way in solution metrics filter

export const convertToRegex = (str: string): string => {
  return `.*${str
    .split("")
    .map((char: string) => {
      if (/[a-zA-Z]/.test(char)) {
        return `[${char.toUpperCase()}${char.toLowerCase()}]`;
      }
      return `[${char}]`;
    })
    .join("")}.*`;
};
