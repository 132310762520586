import React, { useEffect } from "react";
import SideFilters from "./features/side-filters";
import Style from "./InventoryTrackPageFeatureV3.module.css";
import ScreenFrame from "../../components/screen-frame";
import xemelgoStyle from "../../styles/variable";
import { ReactComponent as InventoryIcon } from "../../assets/icons/inventory.svg";
import useInventoryTrackPageConfigContext, {
  InventoryTrackPageConfigContextProvider
} from "./contexts/inventory-track-page-config-context";
import useInventoryTrackPageDataSourceContext, {
  InventoryTrackPageDataSourceContextProvider
} from "./contexts/inventory-track-page-data-source-context";
import { InventoryTrackPageStateContextProvider } from "./contexts/inventory-track-page-state-context";
import TitleRightActions from "./features/title-right-actions";
import Breadcrumb from "./features/breadcrumb";
import ViewMode from "./features/view-mode";
import LocationOverview from "./features/location-overview";
import InventoryInfoTable from "./features/inventory-info-table";
import { useXemelgoClient } from "../../services/xemelgo-service";
import FilterOverviewControl from "./features/filter-overview-control";

const InventoryTrackPageV3 = () => {
  const xemelgoClient = useXemelgoClient();

  const { setLocationTreeMap, setIsLocationTreeMapLoading } = useInventoryTrackPageDataSourceContext();
  const {
    isLoading: isConfigLoading,
    locationCategories,
    locationRoles,
    unknownLocationControl
  } = useInventoryTrackPageConfigContext();

  const fetchLocationTree = async () => {
    setIsLocationTreeMapLoading(true);
    const categoryFilter = !locationCategories || locationCategories?.includes("all") ? [] : locationCategories;
    const supportedRoles = !locationRoles || locationRoles.includes("all") ? [] : locationRoles;

    const locationClient = xemelgoClient.getLocationClient();
    const newLocationTreeMap = await locationClient.getLocationTree([], categoryFilter, supportedRoles);

    if (unknownLocationControl?.enabled && unknownLocationControl?.properties?.id) {
      newLocationTreeMap[unknownLocationControl?.properties?.id] = {
        childLocations: [unknownLocationControl?.properties?.id],
        ...unknownLocationControl?.properties
      };
    }

    setLocationTreeMap(newLocationTreeMap);
    setIsLocationTreeMapLoading(false);
  };

  useEffect(() => {
    if (!isConfigLoading) {
      fetchLocationTree();
    }
  }, [locationCategories, isConfigLoading]);

  return (
    <ScreenFrame
      color={xemelgoStyle.theme.INVENTORY_PRIMARY}
      secondaryColor={xemelgoStyle.theme.INVENTORY_SECONDARY}
      titleIconComponent={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <InventoryIcon
          width={25}
          height={25}
          style={{ color: xemelgoStyle.theme.INVENTORY_PRIMARY }}
        />
      }
      title="Inventory"
      titleRightComponent={<TitleRightActions />}
    >
      <div className={Style.main_container}>
        <SideFilters />

        <div className={Style.right_side_container}>
          <Breadcrumb />
          <FilterOverviewControl />
          <ViewMode />
          <LocationOverview />
          <InventoryInfoTable />
        </div>
      </div>
    </ScreenFrame>
  );
};

export default () => {
  return (
    <InventoryTrackPageConfigContextProvider>
      <InventoryTrackPageStateContextProvider>
        <InventoryTrackPageDataSourceContextProvider>
          <InventoryTrackPageV3 />
        </InventoryTrackPageDataSourceContextProvider>
      </InventoryTrackPageStateContextProvider>
    </InventoryTrackPageConfigContextProvider>
  );
};
