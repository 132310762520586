import { useMemo } from "react";
import callEdgeOperation, { EDGE_OPERATION_MAP } from "../../utils/call-edge-operation";

type GPOPayload = {
  intervalSec?: string;
  settings: Record<string, boolean>;
};
export type PayloadMap = Record<string, GPOPayload>;
export type ScenarioMap = Record<string, string | { default: string; [deviceId: string]: string }>;

export const useGPO = (deviceId: string, edgeApiUrl: string, payloadMap: PayloadMap, scenarioMap: ScenarioMap) => {
  const edgeOperationParams = useMemo(() => {
    return {
      deviceId,
      edgeApiUrl
    };
  }, [deviceId, edgeApiUrl]);

  const sendGPOCommand = async (scenarioId: string) => {
    if (!deviceId || !scenarioId || !scenarioMap[scenarioId]) {
      return;
    }

    const scenario = scenarioMap[scenarioId];
    const payloadId = typeof scenario === "object" ? scenario[deviceId] || scenario.default : scenario;

    if (!payloadId || !payloadMap[payloadId]) {
      return;
    }

    await callEdgeOperation({
      ...edgeOperationParams,
      operation: EDGE_OPERATION_MAP.SET_GPO_SETTINGS,
      payload: payloadMap[payloadId]
    });
  };

  const getGPOSettings = async () => {
    const response = await callEdgeOperation({
      ...edgeOperationParams,
      operation: EDGE_OPERATION_MAP.GET_GPO_SETTINGS
    });

    return response;
  };

  return {
    sendGPOCommand,
    getGPOSettings
  };
};
