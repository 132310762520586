import React, { useState } from "react";
import OptionsIcon from "@material-ui/icons/MoreVert";
import PropTypes from "prop-types";
import Style from "./OptionsTooltip.module.css";
import Tooltip from "../../../tooltip";

export const OptionsTooltip = ({ item, rowOptions, onRowOptionClick }) => {
  const [tooltipInstance, setTooltipInstance] = useState(null);

  return (
    <Tooltip
      containerClassName={Style.tooltip_container}
      placement="bottom-end"
      animation={false}
      trigger="click"
      onMount={(instance) => {
        setTooltipInstance(instance);
      }}
      interactive
      interactiveDebounce={75}
      content={
        <div className={Style.option_menu}>
          {rowOptions?.map((option) => {
            return (
              <div
                key={option.id}
                className={Style.option_item}
                onClick={() => {
                  tooltipInstance.hide();
                  onRowOptionClick(option, item);
                }}
              >
                {option.label}
              </div>
            );
          })}
        </div>
      }
    >
      <div className={Style.option_icon_container}>
        {rowOptions.length > 0 && (
          <OptionsIcon
            className={Style.options_icon}
            data-cy={`${item.id}-table-option-button`}
          />
        )}
      </div>
    </Tooltip>
  );
};

OptionsTooltip.defaultProps = {
  rowOptions: [],
  onRowOptionClick: () => {}
};

OptionsTooltip.propTypes = {
  item: PropTypes.shape({ id: PropTypes.string, editRowEnabled: PropTypes.bool }).isRequired,
  rowOptions: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired, label: PropTypes.string.isRequired })
  ),
  onRowOptionClick: PropTypes.func
};
