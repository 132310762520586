import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import _ from "lodash";
import uuid from "uuid";
import Style from "./Slides.module.css";
import { ReactComponent as ChevronRight } from "../../assets/icons/chevron-right-blue.svg";
import { ReactComponent as ChevronLeft } from "../../assets/icons/chevron-left-blue.svg";

export const Slides = ({
  id,
  numOfSlidesPerPage,
  numOfRowsPerPage,
  renderSlideContent,
  renderEmptySLideContent,
  allowTouchMove,
  data
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const displayedSlides = useMemo(() => {
    const result = [];

    const clonedData = _.cloneDeep(data);

    while (clonedData.length) {
      const eachSlideData = clonedData.splice(0, numOfSlidesPerPage);
      result.push(
        <SwiperSlide
          key={uuid.v4()}
          className={Style.swiper_slide}
          style={{
            gridTemplateRows: [...new Array(numOfRowsPerPage)].reduce((accumulator) => {
              return `${accumulator} 1fr`;
            }, ""),
            gridTemplateColumns: [...new Array(Math.ceil(numOfSlidesPerPage / numOfRowsPerPage))].reduce(
              (accumulator) => {
                return `${accumulator} 1fr`;
              },
              ""
            )
          }}
        >
          {eachSlideData.map((eachData) => {
            return renderSlideContent ? renderSlideContent(eachData) : <div key={uuid.v4()}>{eachData}</div>;
          })}
          {eachSlideData.length < numOfSlidesPerPage &&
            [...new Array(numOfSlidesPerPage - eachSlideData.length)].map(() => {
              return renderEmptySLideContent ? renderEmptySLideContent() : <div key={uuid.v4()} />;
            })}
        </SwiperSlide>
      );
    }
    return result;
  }, [data, numOfSlidesPerPage, numOfRowsPerPage]);

  const { disablePrevButton, disableNextButton } = useMemo(() => {
    return {
      disablePrevButton: !displayedSlides.length || currentSlideIndex === 0,
      disableNextButton: displayedSlides.length - 1 <= currentSlideIndex
    };
  }, [displayedSlides, currentSlideIndex]);

  return (
    <div className={`${Style.flex_column} ${Style.metric_group}`}>
      <div className={`${Style.flex_row} ${Style.swiper_container}`}>
        {displayedSlides.length > 1 && (
          <div
            className={`${id}-swiper-button-prev  ${Style.carousel_navigation_button} ${
              disablePrevButton && Style.carousel_navigation_button_disabled
            }`}
          >
            <ChevronLeft />
          </div>
        )}
        <div className={Style.carousel_container}>
          <Swiper
            onActiveIndexChange={({ activeIndex }) => {
              setCurrentSlideIndex(activeIndex);
            }}
            allowTouchMove={allowTouchMove}
            resizeObserver
            navigation={
              displayedSlides.length > 1 && {
                prevEl: `.${id}-swiper-button-prev`,
                nextEl: `.${id}-swiper-button-next`
              }
            }
            pagination
            slidesPerGroup={1}
            slidesPerView={1}
            modules={[Navigation, Pagination]}
            className={`${Style.flex_row} ${Style.carousel}`}
          >
            {displayedSlides}
          </Swiper>
        </div>
        {displayedSlides.length > 1 && (
          <div
            className={`${id}-swiper-button-next ${Style.carousel_navigation_button} ${
              disableNextButton && Style.carousel_navigation_button_disabled
            }`}
          >
            <ChevronRight />
          </div>
        )}
      </div>
    </div>
  );
};

Slides.defaultProps = {
  data: [],
  numOfSlidesPerPage: 1,
  numOfRowsPerPage: 1,
  renderSlideContent: null,
  renderEmptySLideContent: null,
  allowTouchMove: true
};

Slides.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.any),
  numOfSlidesPerPage: PropTypes.number,
  numOfRowsPerPage: PropTypes.number,
  renderSlideContent: PropTypes.func,
  renderEmptySLideContent: PropTypes.func,
  allowTouchMove: PropTypes.bool
};
