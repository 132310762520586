import { TransferOrder } from "@xemelgo/x-client";
import { XemelgoService } from "../../../../../../../../../../services/XemelgoService";
import { ItemTypeReport } from "../../../../../../../../data/types";
import { TransferOrderStage } from "../../../../data/types";
import getTransferOrderUpdates from "../../../submission-workflow/utils/get-transfer-order-updates";

export const publishMultiTransferOrderDetectorEvents = async (
  detectorId: string,
  transferOrders: TransferOrder[],
  itemTypeReports: ItemTypeReport[],
  stage: TransferOrderStage
) => {
  const publishClient = XemelgoService.getClient().getPublishClient();

  const transferOrderEvents = getTransferOrderUpdates(transferOrders, itemTypeReports, stage.id);
  const publishPromises: Promise<void>[] = [];

  transferOrderEvents.forEach((transferOrderEvent) => {
    const { id, itemTypeEvents } = transferOrderEvent;

    itemTypeEvents.forEach((itemTypeEvent) => {
      const { sensorProfileIds, itemsEntryId } = itemTypeEvent;

      if (!sensorProfileIds.length) {
        return;
      }

      const context = {
        actions: {
          updateTransferOrder: {
            transfer_order_id: id,
            transfer_item_entry_id: itemsEntryId,
            transfer_status: stage.submitStatus
          }
        }
      };

      publishPromises.push(publishClient.publishDetectorEventInBatches(detectorId, sensorProfileIds, context));
    });
  });

  await Promise.all(publishPromises);
};
