import React, { useState } from "react";
import PropTypes from "prop-types";
import { PartnerGroupHeader } from "./partner-group-header/PartnerGroupHeader";
import { Div } from "../../../../components/div";
import { CollapsibleListView } from "../../../../components/collapsible-list-view";
import { PartnerRows } from "./partner-rows/PartnerRows";
import { getPartnerDisplayValue } from "../../ListPartnersUtil";
import "./style.css";

export const ListPartnerGroupsPanel = ({ partners, onPartnerSelected, onAddClicked, focus, displayProperty }) => {
  const [filteredPartners, setFilteredPartners] = useState(partners);

  const trimAndLower = (text) => {
    return text.toLowerCase().trim();
  };

  const filterPartners = (event) => {
    const filterText = event.currentTarget.value;
    const newFilteredList = partners.filter((p) => {
      return trimAndLower(getPartnerDisplayValue(p, displayProperty)).includes(trimAndLower(filterText));
    });

    setFilteredPartners(newFilteredList);
  };

  return (
    <Div className="my-facility-partners">
      <Div>
        <CollapsibleListView
          isAlwaysExpanded
          className="category-detail-view"
          title={
            <PartnerGroupHeader
              groupName="Customers"
              resourceCount={filteredPartners.length}
              onAddClick={onAddClicked}
            />
          }
          content={
            <>
              <Div className="filter_bar_container">
                <span className="fa fa-search filter_bar_icon icon_offset" />
                <input
                  type="text"
                  placeholder="Type to Filter"
                  onChange={filterPartners}
                  className="filter_bar"
                />
              </Div>
              <PartnerRows
                partners={filteredPartners}
                onPartnerSelected={onPartnerSelected}
                focus={focus}
                displayProperty={displayProperty}
              />
            </>
          }
        />
      </Div>
    </Div>
  );
};

ListPartnerGroupsPanel.defaultProps = {
  focus: false
};

ListPartnerGroupsPanel.propTypes = {
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      identifier: PropTypes.string.isRequired,
      description: PropTypes.string
    })
  ).isRequired,
  onPartnerSelected: PropTypes.func.isRequired,
  onAddClicked: PropTypes.func.isRequired,
  focus: PropTypes.bool
};
