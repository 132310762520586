import { getCurrentTimestamp, getFormattedDate } from "../../../../common/Utilities";
import { useXemelgoAppsyncClient } from "../../../../services/xemelgo-appsync-service";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import calculateDependentFormValue from "../../../../utils/calculate-dependent-form-value";
import formatText from "../../../../utils/format-text";
import { useAddAssetFeatureConfigContext } from "../../contexts/add-asset-feature-config-context";

const NO_LOCATION_ID = "no_location";
const TAG_BLOCK_SIZE = 8;

export const useAssetFormDataParser = () => {
  const xemelgoClient = useXemelgoClient();
  const locationClient = xemelgoClient.getLocationClient();
  const appsyncItemClient = useXemelgoAppsyncClient().getItemClient();
  const { generateIdentifierOptions, epcPrefix } = useAddAssetFeatureConfigContext();

  const generateTags = (timestamp, quantity = 1) => {
    const tags = [];

    const date = getFormattedDate(timestamp, "MMDD");

    const startingSerial = 1;
    for (let i = startingSerial; i <= quantity; i++) {
      const paddingLength = TAG_BLOCK_SIZE - (`${epcPrefix}${date}${timestamp}${i}`.length % TAG_BLOCK_SIZE);
      const paddedString = "0".repeat(paddingLength) + i;
      tags.push(`${epcPrefix}${date}${timestamp}${paddedString}`);
    }
    return tags;
  };

  const getFieldValue = (fieldData) => {
    return typeof fieldData === "object" ? fieldData?.value : fieldData;
  };

  const generatePayload = async (formDataList, formFields) => {
    const timestamp = getCurrentTimestamp();
    const payloadsByLocationIdMap = {};

    const augmentedFormDataList = formDataList.map((formData) => {
      const augmentedFormData = { ...formData };
      Object.keys(formFields).forEach((fieldId) => {
        const field = formFields[fieldId];
        // If the field has a value we do not need to fill it in
        if (formData[fieldId]) {
          return;
        }

        if (field.metaDataOf === "item_number") {
          augmentedFormData.item_number[fieldId] = formData.item_number[fieldId];
        }

        // The field can be filled in if it has a default value or a dependency
        if (field.defaultValue) {
          augmentedFormData[fieldId] = field.defaultValue.value ?? field.defaultValue;
        }

        if (field.dependency) {
          augmentedFormData[fieldId] = calculateDependentFormValue(field, formData, formFields);
        }
      });

      // Format text of field values based on configuration
      Object.keys(augmentedFormData).forEach((fieldId) => {
        const field = formFields[fieldId];

        if (field.textFormatOptions) {
          let fieldText = augmentedFormData[fieldId];
          if (typeof fieldText === "object") {
            fieldText = fieldText?.value;
            augmentedFormData[fieldId].value = formatText(fieldText, field.textFormatOptions);
          } else if (typeof fieldText === "string") {
            augmentedFormData[fieldId] = formatText(fieldText, field.textFormatOptions);
          }
        }
      });

      return augmentedFormData;
    });

    const locationIdentifierList = augmentedFormDataList.map((eachForm) => {
      const { location } = eachForm;
      return getFieldValue(location);
    });

    const locationInfoMap = (await locationClient.getLocationsByIdentifiers(locationIdentifierList)).reduce(
      (locationMap, eachLocation) => {
        const { identifier } = eachLocation;
        locationMap[identifier] = eachLocation;
        return locationMap;
      },
      {}
    );

    for (let rowIndex = 0; rowIndex < augmentedFormDataList.length; rowIndex++) {
      const formData = augmentedFormDataList[rowIndex];
      let payloads = [];
      const {
        item_number: itemNumber,
        item_quantity,
        location,
        tracker_serial: trackerSerial,
        item_identifier: itemIdentifier,
        ...additionalProperties
      } = formData;
      const itemIdentifierValue = getFieldValue(itemIdentifier);
      const itemNumberValue = getFieldValue(itemNumber);
      const trackerSerialValue = getFieldValue(trackerSerial);
      const locationValue = getFieldValue(location);
      const itemQuantity = getFieldValue(item_quantity) || 1;

      if (itemQuantity > 1 && trackerSerialValue) {
        throw new Error("Cannot create multiple assets with the same RFID tag");
      }

      let itemIdentifiers = [itemIdentifierValue];
      if (!itemIdentifierValue) {
        const { separator, prefix: customerPrefix, paddedLength } = generateIdentifierOptions;
        const prefixList = [itemNumberValue];
        if (customerPrefix) {
          prefixList.unshift(customerPrefix);
        }
        itemIdentifiers = await appsyncItemClient.createIdentifiers(
          itemNumberValue,
          itemQuantity,
          prefixList.join(separator),
          separator,
          paddedLength
        );
      }

      // use provided tracker serial or generate
      if (trackerSerialValue) {
        payloads.push({
          class: "Asset",
          item_number: itemNumberValue,
          item_identifier: itemIdentifiers[0],
          tracker_serial: trackerSerialValue,
          name: trackerSerialValue
        });
      } else {
        const newVids = generateTags(timestamp + rowIndex, itemQuantity);
        if (newVids.length !== itemIdentifiers.length) {
          throw new Error("Number of item identifiers must match the number of RFID tags");
        }
        newVids.forEach((tag, i) => {
          const itemPayload = {
            class: "Asset",
            item_number: itemNumberValue,
            item_identifier: itemIdentifiers[i],
            tracker_serial: tag,
            name: tag
          };

          payloads.push(itemPayload);
        });
      }
      // add other fields to the payload
      payloads = payloads.map((eachPayload) => {
        const newItemPayload = Object.keys(additionalProperties).reduce(
          (tempItemPayload, propName) => {
            const { id, type, numberOnly, metaDataOf } = formFields[propName] || {};
            const propertyValue = getFieldValue(additionalProperties[propName]);
            if (id && additionalProperties[propName] && !metaDataOf) {
              switch (type) {
                case "date":
                case "datepicker":
                  // parse date if it is not parsed
                  const date = typeof propertyValue !== "number" ? Date.parse(propertyValue) : propertyValue;

                  tempItemPayload[id] = date;
                  break;
                default:
                  const value = numberOnly ? parseFloat(propertyValue) : propertyValue;
                  // If the input field was a selector, get the value
                  if (id === "item_description") {
                    tempItemPayload.description = value;
                  } else {
                    tempItemPayload[id] = value;
                  }
              }
            }
            return tempItemPayload;
          },

          { ...eachPayload }
        );
        return { ...newItemPayload, itemType: itemNumber };
      });

      // group payload by location
      const locationId = locationInfoMap[locationValue]?.id || NO_LOCATION_ID;
      if (!payloadsByLocationIdMap[locationId]) {
        payloadsByLocationIdMap[locationId] = [];
      }
      payloadsByLocationIdMap[locationId].push(...payloads);
    }

    return Object.keys(payloadsByLocationIdMap).map((locationId) => {
      const payloads = payloadsByLocationIdMap[locationId];

      const newLocationId = locationId === NO_LOCATION_ID ? null : locationId;
      return { payloads, locationId: newLocationId };
    });
  };

  return {
    generatePayload
  };
};
