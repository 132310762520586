import { XemelgoService } from "../../../../../../../../../../services/XemelgoService";
import { ITEM_SCAN_STATUS_MAP } from "../../../../data/constants";
import { ProcessedItemTypeReport } from "../onboard-items/onboardItems";

export const updateContainer = async (
  itemTypeEntries: ProcessedItemTypeReport[],
  containerEntry: ProcessedItemTypeReport,
  containerVid: string
) => {
  const transferClient = XemelgoService.getClient().getTransferClient();

  const containerItem = containerEntry?.epcToItemMap?.[containerVid];
  const containerId = containerItem?.itemId;

  if (!containerId) {
    throw new Error(`Container not found in transfer order`);
  }

  const itemsIds = itemTypeEntries.reduce((acc: string[], report) => {
    const scannedItemIds = Object.values(report.epcToItemMap)
      .filter((item) => {
        // Do not include items that are associated with the order but not scanned (for picklist verification)
        return !item.isAssociatedWithOrder || item.scanStatus.id === ITEM_SCAN_STATUS_MAP.SCANNED.id;
      })
      .map((item) => {
        return item.itemId;
      });

    return [...acc, ...scannedItemIds];
  }, []);

  await transferClient.attachItemsToContainer(containerId, itemsIds);
};
