import { TransferOrder } from "@xemelgo/x-client";
import { ITEM_SCAN_STATUS_MAP, STAGES } from "../../../../data/constants";
import { ItemTypeReport, KioskItem } from "../../../../../../../../data/types";

type ItemTypeEvent = {
  itemsEntryId: string;
  itemTypeIdentifier: string;
  sensorProfileIds: string[];
  expectedCount: number;
  scannedCount: number;
};

export type TransferOrderUpdate = {
  id: string;
  identifier: string;
  itemTypeEvents: ItemTypeEvent[];
};

export const getTransferOrderUpdates = (
  transferOrders: TransferOrder[],
  itemTypeReports: ItemTypeReport[],
  stageId: string
): TransferOrderUpdate[] => {
  const itemTypeIdToEpcMap = itemTypeReports.reduce((acc: Record<string, Record<string, KioskItem>>, report) => {
    const { itemTypeId, epcToItemMap } = report || {};

    if (itemTypeId && epcToItemMap) {
      acc[itemTypeId] = epcToItemMap;
    }
    return acc;
  }, {});

  return transferOrders.map((transferOrder) => {
    const { id: transferOrderId, identifier: transferOrderIdentifier, hasItemsEntry } = transferOrder;

    const itemTypeEvents: ItemTypeEvent[] = [];
    hasItemsEntry.forEach((itemEntry) => {
      const {
        id: itemsEntryId,
        ofItemType = [],
        hasTransferItemState = [],
        quantity_total: quantityTotal,
        quantity_staged: quantityStaged = 0,
        quantity_in_progress: quantityInProgress = 0,
        quantity_received: quantityReceived = 0,
        quantity_verified: quantityVerified = 0
      } = itemEntry;

      const stageToCounts = {
        [STAGES.STAGE]: quantityStaged + quantityInProgress + quantityReceived + quantityVerified,
        [STAGES.SHIP]: quantityInProgress + quantityReceived + quantityVerified,
        [STAGES.RECEIVE]: quantityReceived + quantityVerified,
        [STAGES.VERIFY]: quantityVerified
      };
      const expectedCount = quantityTotal - stageToCounts[stageId];

      const itemType = ofItemType[0] || {};
      const { id: itemTypeId, identifier: itemTypeIdentifier } = itemType;

      const epcMap = itemTypeIdToEpcMap[itemTypeId] || {};

      let numItemsToAdd = Math.min(quantityTotal, Object.keys(epcMap).length);
      const sensorProfileIds: string[] = [];

      // Add scanned items that are already associated with this transfer order first
      hasTransferItemState.forEach((itemState) => {
        const { ofItem: items = [] } = itemState;
        const { vid, id: sensorProfileId } = items?.[0]?.hasSensorProfile?.[0] || {};

        if (epcMap[vid]?.scanStatus?.id === ITEM_SCAN_STATUS_MAP.SCANNED.id) {
          sensorProfileIds.push(sensorProfileId);
        }
      });

      // Add items that are not yet associated with any transfer order
      Object.values(epcMap).forEach((item) => {
        const { isAssociatedWithOrder, scanStatus, sensorProfileId } = item;
        if (
          sensorProfileId &&
          numItemsToAdd > 0 &&
          scanStatus?.id === ITEM_SCAN_STATUS_MAP.SCANNED.id &&
          !isAssociatedWithOrder
        ) {
          numItemsToAdd--;
          sensorProfileIds.push(sensorProfileId);
        }
      });

      // Remove items that have been added to a transfer order from epcMap
      const newEpcMap = Object.entries(epcMap).reduce((acc: Record<string, KioskItem>, [vid, item]) => {
        const { sensorProfileId } = item;
        if (sensorProfileId && !sensorProfileIds.includes(sensorProfileId)) {
          acc[vid] = item;
        }

        return acc;
      }, {});

      itemTypeIdToEpcMap[itemTypeId] = newEpcMap;

      itemTypeEvents.push({
        itemsEntryId,
        itemTypeIdentifier,
        sensorProfileIds,
        expectedCount,
        scannedCount: sensorProfileIds.length
      });
    });

    return {
      id: transferOrderId,
      identifier: transferOrderIdentifier,
      itemTypeEvents
    };
  });
};
