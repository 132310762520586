import React, { useMemo, useEffect } from "react";
import _ from "lodash";
import { SideFilter as SideFilterFeature, useSideFilterConfigBuilder } from "../../../side-filter";
import useTransferOrderTrackPageConfigContext from "../../contexts/transfer-order-track-page-config-context";
import Style from "./SideFilters.module.css";
import CollapsibleView from "../../../../components/collapsible-view";
import useTransferOrderTrackPageStateContext from "../../contexts/transfer-order-track-page-state-context";

export const SideFilters = () => {
  const { isSideFilterHidden, sideFilterValue, setSideFilterValue, isSearchParamReady } =
    useTransferOrderTrackPageStateContext();

  const { sideFilterControl } = useTransferOrderTrackPageConfigContext();

  // copy the existing value for the override filter and delete the unrelated ones for preserving the filter value
  useEffect(() => {
    if (isSearchParamReady) {
      const newSideFilterValue = _.cloneDeep(sideFilterValue);
      Object.keys(newSideFilterValue).forEach((eachFilterKey) => {
        const existInConfig = sideFilterControl.find(({ id }) => {
          return id === eachFilterKey;
        });

        if (!existInConfig) {
          delete newSideFilterValue[eachFilterKey];
        }
      });
      setSideFilterValue(newSideFilterValue);
    }
  }, [sideFilterControl, isSearchParamReady]);

  const {
    isLoading: isFilterLoading,
    filterConfiguration,
    initialFilterValue,
    getOnlyActiveValues,
    applyActiveValueOnInitialFilterValue
  } = useSideFilterConfigBuilder(sideFilterControl);

  const currentSideFilterValue = useMemo(() => {
    return applyActiveValueOnInitialFilterValue(sideFilterValue);
  }, [initialFilterValue, sideFilterValue]);

  return (
    <CollapsibleView
      width={190}
      isCollapsed={!sideFilterControl?.length ? true : isSideFilterHidden}
    >
      <div className={Style.container}>
        {sideFilterControl?.length ? (
          <SideFilterFeature
            isLoading={isFilterLoading}
            showHideFiltersControl={false}
            filterConfiguration={filterConfiguration}
            initialFilterValues={currentSideFilterValue}
            onFilterChange={({ allValues }) => {
              setSideFilterValue(getOnlyActiveValues(allValues));
            }}
          />
        ) : (
          <div className={Style.empty_filter_container}>
            <p className={Style.empty_filter_text}>Currently No Filters Available</p>
          </div>
        )}
      </div>
    </CollapsibleView>
  );
};
