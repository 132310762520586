import React from "react";
import { Route } from "react-router-dom";
import PackageTrackPageFeature from "../../features/package-track-page-feature";
import PackageDetailFeature from "../../features/package-detail-feature/PackageDetailFeature";
import PackageOrderDetailFeature from "../../features/package-order-detail-feature/PackageOrderDetailFeature";

export const PackageStackNavigation = () => {
  return (
    <>
      <Route
        exact
        path="/package"
        component={PackageTrackPageFeature}
      />
      <Route
        path="/package/detail"
        component={PackageDetailFeature}
      />
      <Route
        path="/package/order-detail"
        component={PackageOrderDetailFeature}
      />
    </>
  );
};
