import React from "react";
import Style from "./TransferOrderInfoTable.module.css";
import TableControlActions from "./features/table-control-actions";
import TransferOrderTable from "./features/transfer-order-table";

export const TransferOrderInfoTable = () => {
  return (
    <div className={Style.container}>
      <TableControlActions />
      <TransferOrderTable />
    </div>
  );
};
