import React from "react";
import PropTypes from "prop-types";
import xemelgoStyle from "../../../../styles/variable";
import Style from "../../PrinterSelectionComponent.module.css";
import { PRINT_TIMES } from "../../../../data/constants";

export const PrintTimeSelection = ({ selectedPrintTime, onPrintTimeSelect }) => {
  return (
    <div className={Style.body_child_container}>
      <div className={`${xemelgoStyle.subtextBold} ${Style.sub_header}`}>
        Print Time
        <span className={Style.asterisk}> *</span>
        <div className={Style.print_time_container}>
          {[PRINT_TIMES.now, PRINT_TIMES.later].map((printTime) => {
            const { id, label } = printTime;
            return (
              <div
                key={id}
                className={Style.print_time_option_container}
                onClick={() => {
                  onPrintTimeSelect(id);
                }}
              >
                <input
                  type="radio"
                  checked={selectedPrintTime === id}
                />
                <span className={Style.print_option_label}>{label}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

PrintTimeSelection.propTypes = {
  selectedPrintTime: PropTypes.string.isRequired,
  onPrintTimeSelect: PropTypes.func.isRequired
};
