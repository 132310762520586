import React, { useContext, useState, useEffect } from "react";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";

const GlobalSearchConfigContext = React.createContext();

const initialState = {
  assetStatusFlagMap: {},
  daysForHotOrder: 7,
  inventoryStatusFlagMap: {},
  orderPartStatusFlagMap: {},
  orderStatusFlagMap: {},
  queryTransferOrders: false,
  resultDisplayControl: {
    itemType: [
      {
        id: "identifier",
        label: "Item Type",
        labelTemplate: "getLabelByItemTypeClass",
        type: "string",
        isSearchable: true,
        isQueryable: true,
        bold: true
      },
      { id: "name", label: "Name", type: "string", isSearchable: true, isQueryable: true },
      { id: "description", label: "Description", type: "string" }
    ],
    item: [
      {
        id: "identifier",
        label: "Item",
        labelTemplate: "getLabelByItemClass",
        type: "string",
        isSearchable: true,
        isQueryable: true,
        bold: true
      },
      { id: "sensorProfile", label: "Tag", type: "string", defaultValue: "-Not Provided-" },
      { id: "parts", label: "Contains Part(s)", type: "array", bold: true, autoHide: true }
    ]
  },
  useV2Order: false
};

const ASSET_SOLUTION = "asset";
const ORDER_SOLUTION = "order";
const INVENTORY_SOLUTION = "inventory";
const STATUS_FLAG_MAP_FEATURE = "statusFlagMap";
const ORDER_PART_FEATURE = "part";
const USE_V2_ORDER = "useV2";
const DAYS_FOR_HOT_ORDER = "daysForHot";
const GLOBAL_SEARCH_APP = "globalSearch";
const RESULT_DISPLAY_CONTROL = "resultDisplayControl";
const QUERY_TRANSFER_ORDERS = "queryTransferOrders";

export const useGlobalSearchConfigContext = () => {
  return useContext(GlobalSearchConfigContext);
};

export const GlobalSearchConfigContextProvider = ({ children }) => {
  const workOrderSolutionConfigProvider = useAppConfigProvider(ORDER_SOLUTION);
  const assetSolutionConfigProvider = useAppConfigProvider(ASSET_SOLUTION);
  const inventorySolutionConfigProvider = useAppConfigProvider(INVENTORY_SOLUTION);
  const globalSearchConfigProvider = useAppConfigProvider(GLOBAL_SEARCH_APP);

  const [isLoading, setIsLoading] = useState(true);

  const [orderStatusFlagMap, setOrderStatusFlagMap] = useState(initialState.orderStatusFlagMap);
  const [assetStatusFlagMap, setAssetStatusFlagMap] = useState(initialState.assetStatusFlagMap);
  const [inventoryStatusFlagMap, setInventoryStatusFlagMap] = useState(initialState.inventoryStatusFlagMap);
  const [orderPartStatusFlagMap, setOrderPartStatusFlagMap] = useState(initialState.orderPartStatusFlagMap);
  const [useV2Order, setUseV2Order] = useState(initialState.useV2Order);
  const [daysForHotOrder, setDaysForHotOrder] = useState(initialState.daysForHotOrder);
  const [resultDisplayControl, setResultDisplayControl] = useState(initialState.resultDisplayControl);
  const [queryTransferOrders, setQueryTransferOrders] = useState(initialState[QUERY_TRANSFER_ORDERS]);

  useEffect(() => {
    setIsLoading(true);
    const newOrderStatusFlagMap = workOrderSolutionConfigProvider.getValue(STATUS_FLAG_MAP_FEATURE, "object");
    const newAssetStatusFlagMap = assetSolutionConfigProvider.getValue(STATUS_FLAG_MAP_FEATURE, "object");
    const newInventoryStatusFlagMap = inventorySolutionConfigProvider.getValue(STATUS_FLAG_MAP_FEATURE, "object");
    const newOrderPartStatusFlagMap = workOrderSolutionConfigProvider.getValue(ORDER_PART_FEATURE, "object")?.[
      STATUS_FLAG_MAP_FEATURE
    ];

    const newUseV2Order = workOrderSolutionConfigProvider.getValue(USE_V2_ORDER, "boolean");
    const newDaysForHotOrder = workOrderSolutionConfigProvider.getValue(DAYS_FOR_HOT_ORDER, "number");

    const newResultDisplayControl = globalSearchConfigProvider.getValue(RESULT_DISPLAY_CONTROL, "object");
    const newQueryTransferOrders = globalSearchConfigProvider.getValue(QUERY_TRANSFER_ORDERS, "boolean");

    setOrderStatusFlagMap(newOrderStatusFlagMap || initialState.orderStatusFlagMap);
    setAssetStatusFlagMap(newAssetStatusFlagMap || initialState.assetStatusFlagMap);
    setInventoryStatusFlagMap(newInventoryStatusFlagMap || initialState.inventoryStatusFlagMapStatusFlagMap);
    setOrderPartStatusFlagMap(newOrderPartStatusFlagMap || initialState.orderPartStatusFlagMapStatusFlagMap);
    setUseV2Order(newUseV2Order || initialState.useV2Order);
    setDaysForHotOrder(newDaysForHotOrder || initialState.daysForHotOrder);
    setResultDisplayControl(newResultDisplayControl || initialState.resultDisplayControl);
    setQueryTransferOrders(newQueryTransferOrders ?? initialState[QUERY_TRANSFER_ORDERS]);

    setIsLoading(false);
  }, [workOrderSolutionConfigProvider]);

  return (
    <GlobalSearchConfigContext.Provider
      value={{
        isLoading,
        orderStatusFlagMap,
        assetStatusFlagMap,
        inventoryStatusFlagMap,
        orderPartStatusFlagMap,
        useV2Order,
        daysForHotOrder,
        resultDisplayControl,
        queryTransferOrders
      }}
    >
      {children}
    </GlobalSearchConfigContext.Provider>
  );
};
