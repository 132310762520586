import React from "react";
import moment, { MomentInput } from "moment";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import AutoSizeTextArea from "../AutoSizeTextArea/AutoSizeTextArea";
import { getStackedXemelgoLogo } from "../../common/Utilities";
import CheckBoxGroup from "../add-page-component/add-page-inputs/CheckBoxGroup";
import SearchDropdown from "../SearchDropdown/SearchDropdown";
import DateTimePicker, { DatePicker } from "../DateTimePicker/DateTimePicker";
import Style from "./MultiInputForm.module.css";
import Selector from "../selector/Selector";
import { AddPageInputTypeMap } from "../../data/constants";
import formatText, { TextFormatOption } from "../../utils/format-text";
import MultiSelectDropown from "../multi-select-dropdown";

export type DropdownValue = {
  id: string;
  label: string;
  value: unknown;
};

export type FieldValue = string | number | DropdownValue | Array<DropdownValue>;

export type FormField = {
  id: string;
  label?: string;
  value?: FieldValue;
  type: string;
  isHidden?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  textFormatOptions?: TextFormatOption[];
  options?: Array<{ label: string; value: string; [property: string]: number | string | boolean | null }>;
  numberOnly?: boolean;
  subFieldsMap?: { [key: string]: { label: string; value: string } };
  searchFn?: (
    query: string
  ) =>
    | Promise<{ label: string; value: string }[]>
    | Promise<{ result: any; canceled?: undefined } | { canceled: boolean; result?: undefined }>;
  errorMessage?: string;
  timeFormat?: string;
  placeholder?: string;
  optionName?: string;
  autoFocus?: boolean;
  index: number;
  renderOption?: (item: Record<string, unknown>) => React.ReactNode;
};

interface MultiInputFormProps {
  formFields: FormField[];
  onChange: (id: string, value: any) => void;
  isLoading: boolean;
  isFormDisabled: boolean;
  containerClassName?: string;
  fieldContainerClassName?: string;
  numSkeletons?: number;
  loadingInputClassName?: string;
  loadingTextClassName?: string;
}

export const MultiInputForm = ({
  formFields,
  onChange,
  isLoading,
  isFormDisabled,
  containerClassName,
  fieldContainerClassName,
  loadingInputClassName,
  loadingTextClassName,
  numSkeletons = 3
}: MultiInputFormProps): JSX.Element => {
  const defaultXemelgoLogo = getStackedXemelgoLogo("dark");

  /**
   * This function converts a field value from the single form data or CSV data to an object that can
   * be use in a corresponding component
   */
  const getFormattedFieldValue = (newValue: FieldValue) => {
    if (typeof newValue === "string" && newValue) {
      return { label: newValue };
    }
    if (typeof newValue === "object" && newValue !== null && "label" in newValue) {
      return { label: newValue?.label };
    }

    return {};
  };

  const inputTypeControl = (field: FormField) => {
    const {
      id,
      type,
      value = "",
      isDisabled = isFormDisabled,
      isReadOnly,
      errorMessage,
      timeFormat,
      options,
      searchFn,
      textFormatOptions = [],
      placeholder,
      optionName,
      autoFocus,
      renderOption
    } = field;

    switch (type) {
      case AddPageInputTypeMap.CHECK_BOX_GROUP:
        return (
          <CheckBoxGroup
            value={
              typeof value === "object" && value !== null
                ? { ...value, disabled: isReadOnly || isDisabled }
                : { disabled: isReadOnly || isDisabled }
            }
            options={options}
            onChange={(_, newValue) => {
              onChange(field.id, newValue);
            }}
            error={!!errorMessage}
          />
        );
      case AddPageInputTypeMap.DATE_TIME_PICKER:
        if (isDisabled || isReadOnly) {
          return (
            <AutoSizeTextArea
              value={moment(value as MomentInput).format(timeFormat || "MM/DD/yyyy hh:mm a")}
              disabled={isDisabled}
              readOnly={isReadOnly}
              backgroundColor={isReadOnly ? "transparent" : ""}
              error={!!errorMessage}
              errorMessage={errorMessage}
            />
          );
        }
        return (
          <DateTimePicker
            format={timeFormat}
            value={value}
            disabled={isDisabled}
            readOnly={isReadOnly}
            error={!!errorMessage}
            errorMessage={errorMessage}
            onTimeChange={(newTime: number) => {
              onChange(field.id, newTime);
            }}
          />
        );
      case AddPageInputTypeMap.DATE_PICKER:
        if (isDisabled || isReadOnly) {
          return (
            <AutoSizeTextArea
              id={id}
              value={moment(value as MomentInput).format(timeFormat || "MM/DD/yyyy")}
              disabled={isDisabled}
              readOnly={isReadOnly}
              backgroundColor={isReadOnly ? "transparent" : ""}
              error={!!errorMessage}
              errorMessage={errorMessage}
            />
          );
        }
        return (
          <DatePicker
            format={timeFormat}
            value={value}
            disabled={isDisabled}
            readOnly={isReadOnly}
            error={!!errorMessage}
            errorMessage={errorMessage}
            onTimeChange={(newTime: number) => {
              onChange(field.id, newTime);
            }}
          />
        );
      case AddPageInputTypeMap.COMMENT:
        return (
          <AutoSizeTextArea
            maxRows={4}
            minRows={4}
            newLineAllowed
            value={formatText(value as string, textFormatOptions)}
            onChangeText={(newText) => {
              onChange(field.id, newText);
            }}
            disabled={isDisabled}
            readOnly={isReadOnly}
            backgroundColor={isReadOnly ? "transparent" : ""}
            error={!!errorMessage}
            errorMessage={errorMessage}
          />
        );
      case AddPageInputTypeMap.SEARCH_DROP_DOWN:
      case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
        return (
          <SearchDropdown
            selectedItem={getFormattedFieldValue(value)}
            options={options}
            disabled={isDisabled}
            error={!!errorMessage}
            errorMessage={errorMessage}
            onItemSelected={(newItem) => {
              onChange(field.id, newItem);
            }}
            autoFocus={autoFocus}
            showIcon
          />
        );
      case AddPageInputTypeMap.SEARCH:
        return (
          <Selector
            options={options}
            onSelect={(item) => {
              onChange(field.id, item);
            }}
            searchFn={searchFn}
            value={getFormattedFieldValue(value)}
            error={!!errorMessage}
            errorMessage={errorMessage}
            renderOption={renderOption}
          />
        );
      case AddPageInputTypeMap.MULTI_SELECT_DROP_DOWN:
        return (
          <MultiSelectDropown
            options={options}
            selectedOptions={Array.isArray(value) ? value : []}
            disabled={isDisabled}
            placeholder={placeholder}
            optionName={optionName}
            onOptionClick={(newSelectedOptions) => {
              onChange(field.id, newSelectedOptions);
            }}
          />
        );
      case AddPageInputTypeMap.INPUT:
      default:
        const { numberOnly } = field;
        return (
          <AutoSizeTextArea
            value={formatText(value as string, textFormatOptions)}
            onChangeText={(newText) => {
              onChange(field.id, newText);
            }}
            numberOnly={numberOnly}
            disabled={isDisabled}
            readOnly={isReadOnly}
            backgroundColor={isReadOnly ? "transparent" : ""}
            error={!!errorMessage}
            errorMessage={errorMessage}
          />
        );
    }
  };

  /**
   * Display sub-fields along with the image under the main field
   * @param {*} fieldsMap
   * @returns
   */
  const renderSubFields = (fieldsMap: { [key: string]: { label: string; value: string } }) => {
    const clonedFieldsMap = _.cloneDeep(fieldsMap);
    const { image_path: imagePath } = clonedFieldsMap;
    const { value: imageUrl } = imagePath || {};
    if (imagePath) {
      delete clonedFieldsMap.image_path;
    }

    return (
      <div className={Style.extra_info_container}>
        <div className={Style.left_container}>
          <img
            alt="item-type"
            className={Style.item_type_image}
            src={imageUrl || defaultXemelgoLogo}
          />
        </div>
        <div className={Style.right_container}>
          {Object.values(clonedFieldsMap).map((field) => {
            const { label, value } = field;
            if (label && value) {
              return (
                <div
                  key={label}
                  className={Style.subfields_container}
                >
                  <p className={Style.subfields_text_bold}>{`${label}:`}</p>
                  <p className={Style.subfields_text}>{value}</p>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    );
  };

  const renderSkeleton = () => {
    const itemSkeletons = [];
    for (let i = 0; i < numSkeletons; i++) {
      itemSkeletons.push(
        <div
          key={`skeleton-${i}`}
          className={fieldContainerClassName}
        >
          <Skeleton className={`${Style.loading_input_text} ${loadingTextClassName}`} />
          <Skeleton className={`${Style.loading_input} ${loadingInputClassName}`} />
        </div>
      );
    }

    return <div className={`${Style.form_section_container} ${containerClassName}`}>{itemSkeletons}</div>;
  };

  const renderField = (field: FormField) => {
    if (field.isHidden) {
      return null;
    }

    const { subFieldsMap = {}, value } = field;

    return (
      <div
        key={`${field.id}-${field.type}`}
        className={fieldContainerClassName}
        data-cy={`multi-input-form-field-${field.id}`}
      >
        <div className={Style.input_text_container}>
          <p className={Style.input_title}>{field.label}</p>
          {field.isRequired && <p className={Style.required_asterisk}>*</p>}
        </div>
        <div className={Style.input_container}>{inputTypeControl(field)}</div>
        {!!value &&
          Object.keys(value).length > 0 &&
          Object.keys(subFieldsMap).length > 0 &&
          renderSubFields(subFieldsMap)}
      </div>
    );
  };

  if (isLoading) {
    return renderSkeleton();
  }

  return (
    <div className={`${Style.form_section_container} ${containerClassName}`}>
      {formFields
        .sort((a, b) => {
          return a.index - b.index;
        })
        .map((field) => {
          return renderField(field);
        })}
    </div>
  );
};
