import { useReducer, useEffect, useRef } from "react";
import { useXemelgoAppsyncClient } from "../../../../services/xemelgo-appsync-service";

export const ACTION_TYPE = {
  RESET: "reset",
  FETCHING: "fetching",
  FETCHING_NEXT_PAGE: "fetchingNextPage",
  PUBLISH_DATA: "publishData"
};

const initialState = {
  dataList: [],
  isLoading: true,
  hasNextPage: false,
  lastUpdatedTime: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.RESET:
      return {
        ...state,
        dataList: [],
        isLoading: initialState.isLoading,
        hasNextPage: initialState.hasNextPage,
        lastUpdatedTime: initialState.lastUpdatedTime
      };
    case ACTION_TYPE.FETCHING:
      return { ...state, dataList: [], isLoading: true, hasNextPage: false };
    case ACTION_TYPE.FETCHING_NEXT_PAGE:
      return { ...state, hasNextPage: true };
    case ACTION_TYPE.PUBLISH_DATA:
      return {
        ...state,
        isLoading: false,
        dataList: [...state.dataList, ...action.payload.dataList],
        hasNextPage: false,
        lastUpdatedTime: action.payload.lastUpdatedTime
      };
    default:
      return state;
  }
};

export const useFetchTransferOrderCountsHook = ({
  locationIds: locationIdsParam,
  filters: filtersParam,
  minTime: minTimeParam,
  maxTime: maxTimeParam,
  locationTreeMap: locationTreeMapParam
}) => {
  const xemelgoAppSyncClient = useXemelgoAppsyncClient();
  const startTimeRef = useRef();

  const [state, dispatch] = useReducer(reducer, {
    dataList: initialState.dataList,
    isLoading: initialState.isLoading,
    hasNextPage: initialState.hasNextPage,
    lastUpdatedTime: initialState.lastUpdatedTime
  });

  const fetchTransferOrderCountsByLocationIds = async (locationIds) => {
    const startTime = Date.now();
    startTimeRef.current = startTime;

    const transferOrderClient = xemelgoAppSyncClient.getTransferClient();

    dispatch({ type: ACTION_TYPE.FETCHING });

    let nextTokens = [];
    do {
      dispatch({ type: ACTION_TYPE.FETCHING_NEXT_PAGE });

      const { transferOrders, nextTokens: newNextTokens } =
        await transferOrderClient.getTransferOrdersCountsByLocationIds(
          locationIds,
          filtersParam || [],
          minTimeParam,
          maxTimeParam,
          nextTokens
        );

      if (startTimeRef.current === startTime) {
        dispatch({
          type: ACTION_TYPE.PUBLISH_DATA,
          payload: {
            dataList: transferOrders.map((eachItem) => {
              const { locationId } = eachItem || {};
              return {
                ...eachItem,
                locationName: locationTreeMapParam?.[locationId]?.name,
                locationIdentifier: locationTreeMapParam?.[locationId]?.identifier
              };
            }),
            lastUpdatedTime: Date.now()
          }
        });
      }
      nextTokens = newNextTokens;
    } while (
      startTimeRef.current === startTime &&
      nextTokens.filter((eachToken) => {
        return !!eachToken;
      }).length !== 0
    );
  };

  useEffect(() => {
    if (locationTreeMapParam) {
      fetchTransferOrderCountsByLocationIds(locationIdsParam);
    }
  }, [locationIdsParam, filtersParam, locationTreeMapParam]);

  return state;
};
