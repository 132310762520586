import React from "react";
import { useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { ReactComponent as ChevronLeft } from "../../assets/icons/chev-left.svg";
import Style from "./ScreenFrame.module.css";

interface ScreenFrameProps {
  title: string;
  subTitle?: string;
  titleIconComponent: React.ReactNode | Function;
  color?: string;
  secondaryColor?: string;
  children: React.ReactNode;
  titleRightComponent?: React.ReactNode | Function;
  verticalExpandDisabled?: boolean;
  hasBackButton?: boolean;
  isLoading?: boolean;
}

export const ScreenFrame = ({
  title,
  subTitle,
  titleIconComponent,
  color = "#C8CBD3",
  secondaryColor,
  children,
  titleRightComponent,
  verticalExpandDisabled,
  hasBackButton,
  isLoading
}: ScreenFrameProps) => {
  const history = useHistory();

  const onBackButtonClick = () => {
    history.goBack();
  };

  const renderTitleIcon = () => {
    return isLoading ? (
      <Skeleton
        width={36}
        height={36}
      />
    ) : (
      <div
        className={Style.title_icon}
        style={{ color: secondaryColor || color }}
      >
        {typeof titleIconComponent === "function" ? titleIconComponent() : titleIconComponent}
      </div>
    );
  };

  const renderTitleText = () => {
    return isLoading ? (
      <Skeleton
        width={175}
        height={30}
      />
    ) : (
      <div className={Style.title}>{title}</div>
    );
  };

  const renderSubTitleText = () => {
    return isLoading ? (
      <Skeleton
        width={175}
        height={30}
      />
    ) : (
      <p className={Style.sub_title}>{subTitle}</p>
    );
  };

  const renderTitleRightComponent = () => {
    return isLoading ? (
      <div className={Style.title_right_skeleton}>
        <Skeleton
          width={175}
          height={30}
        />
      </div>
    ) : (
      <div className={Style.title_right_container}>
        {typeof titleRightComponent === "function" ? titleRightComponent() : titleRightComponent}
      </div>
    );
  };

  return (
    <div
      className={`${Style.grid} ${Style.page_frame} ${verticalExpandDisabled && Style.fixed_height}`}
      style={{ borderTopColor: color }}
      data-cy="screen-frame"
    >
      <div className={Style.title_container}>
        {hasBackButton && (
          <ChevronLeft
            className={Style.back_button}
            onClick={onBackButtonClick}
            color={color}
          />
        )}
        {titleIconComponent && renderTitleIcon()}
        {renderTitleText()}
        {subTitle && renderSubTitleText()}
        {titleRightComponent && renderTitleRightComponent()}
      </div>
      <div className={`${Style.flex_column} ${verticalExpandDisabled && Style.overflow_hidden}`}>{children}</div>
    </div>
  );
};
