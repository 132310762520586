import React, { useState, useEffect, useMemo } from "react";
import queryString from "query-string";
import { useXemelgoClient } from "../../services/xemelgo-service";
import usePackageOrderDetailConfigContext, {
  PackageOrderDetailConfigContextProvider
} from "./contexts/package-order-detail-config-context";
import { getLogo } from "../../common/Utilities";
import { ReactComponent as PackageIcon } from "../../assets/icons/package.svg";
import DetailScreen from "../../components/DetailScreen";
import xemelgoStyle from "../../styles/variable";
import { getDetailScreenData } from "./utils/utils";
import useMixpanelContext from "../../context/mixpanel-context";
import {
  PACKAGE_ORDER_DETAIL_PAGE_EVENT,
  PACKAGE_ORDER_DETAIL_PAGE_EVENT_STEPS
} from "../../constants/mixpanel-constant/packageOrderDetailPage";
import PackageTable from "./features/package-table";
import PackageOrderStatusTable from "./features/package-order-status-table";
import { PackageOrderData, PackageOrderDetailCardAttributes } from "./data/types";

export const PackageOrderDetailFeature = () => {
  const [transferClient] = useState(useXemelgoClient().getTransferClient());
  const [orderData, setOrderData] = useState<PackageOrderData | null>(null);
  const [images, setImages] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { sendMixPanelEvent } = useMixpanelContext();

  const {
    isLoading: isConfigLoading,
    orderAttributes = [],
    transferOrderStatusToDisplayedStatusMap,
    additionalTransferOrderFieldToQuery
  } = usePackageOrderDetailConfigContext();

  const onLoad = async (orderId: string) => {
    const logo = await getLogo();
    const additionalItemFieldIdToQuery = additionalTransferOrderFieldToQuery.map(
      (eachAttribute: PackageOrderDetailCardAttributes) => {
        return eachAttribute.id;
      }
    );

    const additionalQueryAttributesMap = {
      transferOrder: [...additionalItemFieldIdToQuery]
    };
    const fetchedOrderData = await transferClient.getTransferOrdersByIds([orderId], additionalQueryAttributesMap, true);
    setOrderData(fetchedOrderData[0]);
    setImages([logo]);
    setLoading(false);
  };

  useEffect(() => {
    if (window.location.search && !isConfigLoading) {
      const values = queryString.parse(window.location.search);
      const { itemId: orderId } = values;
      sendMixPanelEvent(PACKAGE_ORDER_DETAIL_PAGE_EVENT, PACKAGE_ORDER_DETAIL_PAGE_EVENT_STEPS.ENTRY);
      onLoad(orderId as string);
    }
  }, [window.location.search, isConfigLoading]);

  const orderStatusPillList = useMemo(() => {
    const { transfer_status: transferStatus = "" } = orderData || {};
    if (!transferStatus || !transferOrderStatusToDisplayedStatusMap[transferStatus]) {
      return [];
    }

    return [transferOrderStatusToDisplayedStatusMap[transferStatus]];
  }, [orderData, transferOrderStatusToDisplayedStatusMap]);

  const detailScreenData = useMemo(() => {
    if (orderData) {
      return getDetailScreenData(orderData, orderAttributes);
    }
    return {};
  }, [orderData, orderAttributes]);

  return (
    <DetailScreen
      title="Order Details"
      borderColor={xemelgoStyle.theme.PACKAGE_PRIMARY}
      iconBorderColor={xemelgoStyle.theme.PACKAGE_SECONDARY}
      data={detailScreenData}
      TitleIconComponent={() => {
        return (
          <PackageIcon
            width={25}
            height={25}
            style={{ color: xemelgoStyle.theme.PACKAGE_PRIMARY }}
          />
        );
      }}
      isLoading={isConfigLoading || loading}
      disableEdit
      images={images}
      statusList={orderStatusPillList}
    >
      {!(isConfigLoading || loading) && orderData && (
        <div>
          <PackageOrderStatusTable orderData={orderData} />
          <PackageTable orderData={orderData} />
        </div>
      )}
    </DetailScreen>
  );
};

export default () => {
  return (
    <PackageOrderDetailConfigContextProvider>
      <PackageOrderDetailFeature />
    </PackageOrderDetailConfigContextProvider>
  );
};
