import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { useXemelgoClient } from "services/xemelgo-service";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import Style from "./WorkCenter.module.css";
import { WorkOrderTable } from "../work-order-table/WorkOrderTable";
import FlashingDots from "../../../../components/flashing-dots";
import { useScheduleManagementContext } from "../../context/ScheduleManagementContext.context";
import { CollapsibleButton } from "./features/CollapsibleButton";

/**
 *
 * @param {string} workCenterId
 * @param {string} label
 * @param {bool} isExpanded
 * @param {string} reloadRequestToggle
 * @param {func} onClick
 *
 * @returns {JSX.Element}
 */
export const WorkCenter = ({ workCenterId, label, isExpanded, reloadRequestToggle, onClick }) => {
  const OUTPUT_PART_PREFIX = "outputPart_";
  // state variables
  const [workOrderMap, setWorkOrderMap] = useState({});
  const [metricsData, setMetricsData] = useState({});
  const [loadingMetricsData, setLoadingMetricsData] = useState(true);
  const [loading, setLoading] = useState(true);
  // services
  const xemelgoClient = useXemelgoClient();
  const [scheduleOrderClient] = useState(xemelgoClient.getScheduleOrderClient());
  // context variables
  const configContext = useScheduleManagementContext();
  const { currentWorkCenterMap, setCurrentWorkCenterMap } = configContext;
  const { workOrderTableHeaders, workCenterMetrics } = configContext.getTrackPageConfiguration();
  // extract out item type fields to query from table headers
  const outputItemTypeFieldsToQuery = [];
  workOrderTableHeaders.forEach((header) => {
    const key = header.id;
    if (key.startsWith(OUTPUT_PART_PREFIX)) {
      outputItemTypeFieldsToQuery.push(key.substring(OUTPUT_PART_PREFIX.length, key.length));
    }
  });
  const SECONDARY_COLOR = configContext.secondaryColor;

  const onLoad = async () => {
    setLoading(true);
    try {
      const workOrderList = await scheduleOrderClient.getScheduledWorkOrders(workCenterId, outputItemTypeFieldsToQuery);
      const formattedDisplayWorkOrderMap = workOrderList.reduce((acc, curr) => {
        return { ...acc, [curr.id]: { ...curr, isChecked: false } };
      }, {});

      setWorkOrderMap(formattedDisplayWorkOrderMap);
    } catch {
      setWorkOrderMap({});
    }

    setLoading(false);
  };

  const fetchMetricsData = async () => {
    const workOrderCount = await scheduleOrderClient.getScheduledWorkOrderCount(workCenterId);
    setMetricsData({ totalWorkOrders: workOrderCount });
    setLoadingMetricsData(false);
  };

  useEffect(() => {
    if (reloadRequestToggle) {
      setLoadingMetricsData(true);
      fetchMetricsData().catch(setMetricsData({}));
    }
  }, [workCenterId, reloadRequestToggle]);

  useEffect(() => {
    if (isExpanded && reloadRequestToggle) {
      onLoad();
    }
  }, [isExpanded, reloadRequestToggle]);

  const handleOnReload = (newWorkCenter) => {
    const clonedCurrentWorkCenterMap = _.cloneDeep(currentWorkCenterMap);
    if (newWorkCenter) {
      clonedCurrentWorkCenterMap[newWorkCenter].reloadRequestToggle = Date.now();
    }
    clonedCurrentWorkCenterMap[workCenterId].reloadRequestToggle = Date.now();
    setCurrentWorkCenterMap(clonedCurrentWorkCenterMap);
  };

  const LoadingTable = () => {
    return (
      <div>
        {[...Array(5)].map((i) => {
          return (
            <div className={Style.skeleton_row}>
              <Skeleton
                className={Style.skeleton_cols}
                count={11}
                width={70}
                height={20}
                borderRadius={10}
                inline
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div
        className={`${Style.accordion_container}`}
        style={{
          backgroundColor: SECONDARY_COLOR
        }}
      >
        <div className={`${Style.accordion}`}>
          <CollapsibleButton
            onStateChanged={(collapsed) => {
              onClick(workCenterId, !collapsed);
            }}
            expanded={isExpanded}
            color={SECONDARY_COLOR}
          />
          <div className={Style.accordion_title}>{label}</div>
        </div>

        <div className={`${Style.metrics_group}`}>
          {Object.keys(workCenterMetrics).map((workCenterMetric) => {
            const { index, label } = workCenterMetrics[workCenterMetric];
            const value = metricsData[workCenterMetric];
            return (
              <div
                key={index}
                className={Style.metric_container}
              >
                <p className={Style.metric_label}>{`${label}:`}</p>
                {loadingMetricsData ? <FlashingDots /> : <p className={Style.metric_value}>{value || "0"}</p>}
              </div>
            );
          })}
        </div>

        <div
          className={`${Style.accordtion_btn}`}
          onClick={() => {
            onClick(workCenterId, !isExpanded);
          }}
        >
          {isExpanded ? "Collapse" : "Expand"}
        </div>
      </div>
      {isExpanded &&
        (loading ? (
          <LoadingTable />
        ) : (
          <WorkOrderTable
            workCenterId={workCenterId}
            workOrderMap={workOrderMap}
            onReload={handleOnReload}
          />
        ))}
    </>
  );
};

WorkCenter.propTypes = {
  workCenterId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool,
  reloadRequestToggle: PropTypes.number,
  onClick: PropTypes.func
};

WorkCenter.defaultProps = {
  isExpanded: false,
  onClick: () => {},
  reloadRequestToggle: Date.now()
};
