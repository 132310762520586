import React, { useState, useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import SearchDropdown from "../SearchDropdown/SearchDropdown";
import Style from "./Selector.module.css";
import useDebounce from "../../hooks/use-debounce";
import useOnClickOutside from "../../hooks/use-on-click-outside";

const Selector = ({
  disableTabFocus,
  autoFocus,
  disabled,
  options,
  onSelect,
  searchFn,
  value,
  placeholder,
  error,
  errorMessage,
  renderRow,
  renderOption,
  resetOnClose,
  textareaClassName
}) => {
  const selectorRef = useRef(null);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [isFocused, setIsFocused] = useState(autoFocus);

  const debouncedValue = useDebounce(input, 250);

  const handleOnSearch = async () => {
    if (debouncedValue.length >= 3) {
      const { result, canceled } = await searchFn(debouncedValue);
      if (!canceled && isFocused) {
        setSearchResult(result || []);
        setIsLoading(false);
      }
    } else {
      // clear options when there is no input
      setSearchResult([]);
      setIsLoading(false);
    }
  };

  useOnClickOutside(selectorRef, () => {
    setIsFocused(false);
  });

  useEffect(() => {
    if (searchFn) {
      setIsLoading(true);
      handleOnSearch();
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (resetOnClose && !isFocused) {
      setSearchResult([]);
      setInput("");
    }
  }, [isFocused]);

  // clear search result when no option selected
  useEffect(() => {
    if (!Object.values(value || {})?.length) {
      setSearchResult([]);
    }
  }, [value]);

  const filteredOptions = useMemo(() => {
    return searchFn
      ? searchResult
      : options.filter((option) => {
          return option.label.toLowerCase().includes(input.toLowerCase());
        });
  }, [options, input, searchResult]);

  const renderSkeleton = () => {
    const skeletonsToRender = [];
    for (let index = 0; index < 3; index++) {
      skeletonsToRender.push(<Skeleton key={index} />);
    }
    return skeletonsToRender;
  };
  return (
    <div
      onFocus={() => {
        setIsFocused(true);
      }}
      ref={selectorRef}
    >
      <SearchDropdown
        disabled={disabled}
        disableTabFocus={disableTabFocus}
        autoFocus={autoFocus}
        options={filteredOptions}
        selectedItem={value}
        onItemSelected={onSelect}
        error={error}
        errorMessage={errorMessage}
        inputValue={input}
        onChangeText={setInput}
        showIcon
        placeholder={placeholder}
        renderContent={(data, blurFunction) => {
          if (isLoading) {
            return <div className={Style.loading_container}>{renderSkeleton()}</div>;
          }
          if (data.length) {
            return data.map((item, index) => {
              if (renderRow) {
                return renderRow(item);
              }

              const { id, label } = item;
              return (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <div
                  key={`${id}_${index}`}
                  className={Style.dropdown_item}
                  data-cy={`selector-${id}`}
                  onClick={() => {
                    onSelect(item);
                    setInput("");
                    blurFunction();
                  }}
                >
                  {renderOption ? renderOption(item) : <pre className={Style.dropdown_label_container}>{label}</pre>}
                </div>
              );
            });
          }
          return (
            <li className={Style.dropdown_no_result_message}>
              {(!debouncedValue || debouncedValue.length < 3) && searchFn
                ? `Please enter at least 3 characters to search`
                : "No matching results"}
            </li>
          );
        }}
        textareaClassName={textareaClassName}
      />
    </div>
  );
};

Selector.defaultProps = {
  disableTabFocus: false,
  autoFocus: false,
  disabled: false,
  options: [],
  onSelect: () => {},
  placeholder: "",
  error: false,
  errorMessage: "",
  searchFn: null,
  value: {},
  renderRow: null,
  renderOption: null,
  resetOnClose: false,
  textareaClassName: ""
};

Selector.propTypes = {
  disableTabFocus: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, label: PropTypes.string })),
  onSelect: PropTypes.func,
  value: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any
  }),
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  searchFn: PropTypes.func,
  renderRow: PropTypes.func,
  renderOption: PropTypes.func,
  resetOnClose: PropTypes.bool,
  textareaClassName: PropTypes.string
};

export default Selector;
