import React, { useState } from "react";
import Style from "../../FeatureButtons.module.css";
import AddTransferOrderFeature from "../../../../../../../../../inventory-track-page-feature/features/add-transfer-order-feature";

interface AddTransferOrderButtonProps {
  label?: string;
}

export const AddTransferOrderButton: React.FC<AddTransferOrderButtonProps> = ({ label = "Add Transfer Orders" }) => {
  const [showAddTransferOrdersModal, setShowAddTransferOrdersModal] = useState(false);

  return (
    <div>
      <button
        type="button"
        className={Style.button}
        onClick={() => {
          setShowAddTransferOrdersModal(true);
        }}
      >
        {label}
      </button>
      {showAddTransferOrdersModal && (
        <AddTransferOrderFeature
          onClose={() => {
            setShowAddTransferOrdersModal(false);
          }}
        />
      )}
    </div>
  );
};
