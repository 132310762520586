import { Header } from "../../../../../components/paginated-list-table-with-types";
import { VALUE_TYPE_MAP } from "../../../../../components/paginated-list-table-with-types/data/constants";
import { ITEM_CLASSES } from "../../../../../data/constants";

export const DEFAULT_TO_ITEM_TYPE_TABLE_HEADERS: Header[] = [
  {
    id: "identifier",
    type: VALUE_TYPE_MAP.text,
    label: "SKU"
  },
  {
    id: "quantityTotal",
    label: "Total",
    type: VALUE_TYPE_MAP.number
  },
  {
    id: "quantityCreated",
    label: "Created",
    type: VALUE_TYPE_MAP.number
  },
  {
    id: "quantityVerified",
    label: "Verified",
    type: VALUE_TYPE_MAP.number
  },
  {
    id: "quantityShipped",
    label: "Shipped",
    type: VALUE_TYPE_MAP.number
  },
  {
    id: "node_last_updated_time",
    label: "Last Updated",
    type: VALUE_TYPE_MAP.date,
    timeFormat: "MM/DD/YYYY"
  }
];

export const DEFAULT_ITEM_TABLE_HEADERS: Header[] = [
  {
    id: "identifier",
    label: "Serial #",
    type: VALUE_TYPE_MAP.text,
    isNavLink: true
  },
  {
    id: "container_identifier",
    label: "Pallet #",
    type: VALUE_TYPE_MAP.text,
    isNavLink: true
  },
  {
    id: "vid",
    label: "Item Tag #",
    type: VALUE_TYPE_MAP.text
  },
  {
    id: "status",
    label: "Status",
    type: VALUE_TYPE_MAP.status
  },
  {
    id: "node_last_updated_time",
    label: "Last Updated",
    type: VALUE_TYPE_MAP.date,
    timeFormat: "MM/DD/YYYY"
  }
];

export const ITEM_CLASS_TO_URL_PATH = {
  [ITEM_CLASSES.Asset]: "asset",
  [ITEM_CLASSES.Container]: "inventory",
  [ITEM_CLASSES.Inventory]: "inventory"
};
