import React from "react";
import { ReactComponent as ReportsIcon } from "../../../../assets/icons/reports.svg";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import Style from "./ReportPageScreenFrame.module.css";
import ScreenFrame from "../../../../components/screen-frame";
import xemelgoStyle from "../../../../styles/variable";

const ReportPageScreenFrame = ({ title, titleIcon, sideContent, mainContent, isLoading, ...restProps }) => {
  const sideContentElement = sideContent();

  const renderSideContent = () => {
    return isLoading ? (
      <Skeleton
        containerClassName={Style.skeleton_sidebar_container}
        width={250}
      />
    ) : (
      sideContentElement && <div className={`${Style.side_column}`}>{sideContentElement}</div>
    );
  };

  const renderMainContent = () => {
    return isLoading ? (
      <div className={Style.skeleton_main_content}>
        <Skeleton
          width={350}
          height={30}
        />
        <Skeleton
          width={200}
          height={20}
        />
        <Skeleton
          containerClassName={Style.skeleton_sidebar_container}
          width={1200}
          style={{ marginTop: 50 }}
        />
      </div>
    ) : (
      <div className={`${Style.flex_column} ${Style.content}`}>{mainContent()}</div>
    );
  };

  return (
    <ScreenFrame
      titleIconComponent={titleIcon}
      title={title || "Reports"}
      color={xemelgoStyle.theme.XEMELGO_BLUE}
      secondaryColor={xemelgoStyle.theme.XEMELGO_LIGHTBLUE}
      isLoading={isLoading}
      {...restProps}
    >
      <div className={`${Style.flex_row} ${Style.container}`}>
        {renderSideContent()}
        {renderMainContent()}
      </div>
    </ScreenFrame>
  );
};

ReportPageScreenFrame.defaultProps = {
  sideContent: () => {},
  mainContent: () => {},
  isLoading: false,
  title: "",
  titleIcon: (
    <ReportsIcon
      width={23}
      height={23}
      style={{ color: xemelgoStyle.theme.XEMELGO_BLUE }}
    />
  )
};

ReportPageScreenFrame.propTypes = {
  sideContent: PropTypes.func,
  mainContent: PropTypes.func,
  isLoading: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  titleIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

export default ReportPageScreenFrame;
