import React, { useState } from "react";
import Style from "../../FeatureButtons.module.css";
import UpdateTransferOrdersFeature from "../../../../../../../../../update-transfer-order-feature";

interface UpdateTransferOrdersButtonProps {
  label?: string;
}

export const UpdateTransferOrdersButton: React.FC<UpdateTransferOrdersButtonProps> = ({
  label = "Update Transfer Orders"
}) => {
  const [showUpdateTransferOrdersModal, setShowUpdateTransferOrdersModal] = useState(false);

  return (
    <div>
      <button
        type="button"
        className={Style.button}
        onClick={() => {
          setShowUpdateTransferOrdersModal(true);
        }}
      >
        {label}
      </button>
      {showUpdateTransferOrdersModal && (
        <UpdateTransferOrdersFeature
          onClose={() => {
            setShowUpdateTransferOrdersModal(false);
          }}
        />
      )}
    </div>
  );
};
