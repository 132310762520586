import React from "react";
import { Route } from "react-router-dom";
import TransferOrderTrackPageFeature from "../../features/transfer-order-track-page-feature";
import TransferOrderDetailsPage from "../../features/transfer-order-details-page";

export const TransferOrderStackNavigation = () => {
  return (
    <>
      <Route
        exact
        path="/transfer-order"
        component={TransferOrderTrackPageFeature}
      />
      <Route
        path="/transfer-order/detail"
        component={(props) => {
          return <TransferOrderDetailsPage path={props.location.pathname} />;
        }}
      />
    </>
  );
};
