import React, { createContext, ReactNode, useContext, useMemo } from "react";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";
import useKioskSearchParams from "../../hooks/use-kiosk-search-params";
import { ACTION_DEFAULT_CONFIGS, DEFAULT_CONFIG } from "./data/constants";
import { KioskConfigType } from "./data/types";
import getDefaultedConfig from "../../../../utils/get-defaulted-config";

const SOLUTION_ID = "kiosk";
const KioskConfigContext = createContext<KioskConfigType>({
  ...DEFAULT_CONFIG,
  action: {
    id: "",
    label: "",
    actionType: "",
    index: 0
  }
});

export const useKioskConfigContext = () => {
  return useContext(KioskConfigContext);
};

export const KioskConfigContextProvider = ({ children }: { children: ReactNode }) => {
  const { actionId } = useKioskSearchParams();

  const kioskConfigProvider = useAppConfigProvider(SOLUTION_ID);

  const configValues = useMemo(() => {
    const { config = {} } = kioskConfigProvider;
    const { actionsMap = {} } = config;
    const actionConfig = actionsMap[actionId] || {};
    const { actionType } = actionConfig;

    const defaultedKioskConfig = getDefaultedConfig(DEFAULT_CONFIG, config);

    const solutionDefaultConfig = {
      ...defaultedKioskConfig,
      ...ACTION_DEFAULT_CONFIGS[actionType]
    };
    const defaultedActionConfig = getDefaultedConfig(solutionDefaultConfig, actionConfig);

    const defaultedConfig = {
      ...defaultedKioskConfig,
      ...defaultedActionConfig,
      actionsMap,
      action: actionConfig
    };

    // support legacy config where itemTypeQueryAttributes is a top level key
    const { additionalQueryAttributes } = defaultedConfig;
    const { itemTypeQueryAttributes = [] } = config;

    if (itemTypeQueryAttributes.length) {
      additionalQueryAttributes.itemType = itemTypeQueryAttributes;
    }

    return defaultedConfig;
  }, [kioskConfigProvider, actionId]);

  return <KioskConfigContext.Provider value={configValues}>{children}</KioskConfigContext.Provider>;
};
