import { TIME_IN_MS } from "../../../common/Utilities";
import { QUICK_FILTER_OPTIONS } from "../../../components/data-range-picker/data/constants";
import { Header } from "../../../components/paginated-list-table-with-types";
import { VALUE_TYPE_MAP } from "../../../components/paginated-list-table-with-types/data/constants";
import { RouteOptions } from "./types";

export const DEFAULT_ROUTE_TABLE_HEADERS: Header[] = [
  {
    id: "locationName",
    label: "Location",
    type: "text"
  },
  {
    id: "startDate",
    label: "Entry Time",
    type: VALUE_TYPE_MAP.dateTime,
    timeFormat: "MM/DD/YYYY h:mm A",
    defaultSort: true,
    defaultDirection: "desc"
  },
  {
    id: "endDate",
    label: "Exit Time",
    type: VALUE_TYPE_MAP.dateTime,
    timeFormat: "MM/DD/YYYY h:mm A"
  },
  { id: "duration", label: "Time at Location", type: VALUE_TYPE_MAP.duration }
];

export const DEFAULT_ROUTE_OPTIONS: RouteOptions = {
  defaultRouteTimeframeMs: TIME_IN_MS.WEEKS,
  timeFormat: "MMM D, YYYY h:mm A",
  routeTableHeaders: DEFAULT_ROUTE_TABLE_HEADERS,
  dateRangeQuickFilterOptions: QUICK_FILTER_OPTIONS,
  locationCategories: [],
  allowAddLocation: true
};
