import { Container, ContainerItem } from "@xemelgo/x-client";
import { XemelgoService } from "../../../../services/XemelgoService";
import {
  AdditionalAttributes,
  DetailsCardConfig,
  DetailsCardField
} from "../../../details-page/contexts/details-page-config-context/data/types";
import { DetailCardItemProperty, DetailsCardData } from "../../../details-page/features/details-card/data/types";
import { TRANSFER_STATUS_MAP } from "../../../kiosk/features/selected-kiosk-feature/features/transfer-order-actions/data/constants";
import { TransferOrderItem } from "../../../transfer-order-details-page/data/types";

export const fetchContainerItem = async (
  containerId: string,
  additionalAttributes: AdditionalAttributes,
  detailsCardConfig: DetailsCardConfig
) => {
  const transferClient = XemelgoService.getClient().getTransferClient();

  const container = (await transferClient.getContainersByIds([containerId], additionalAttributes))[0];

  if (!container) {
    throw new Error(`Container item with id "${containerId}" not found`);
  }

  return {
    id: container.id,
    identifier: container.identifier,
    items: parseContainerItems(container.contains),
    detailsCardData: parseDetailCardDetails(container, detailsCardConfig)
  };
};

const parseDetailCardDetails = (details: Container, detailsCardConfig: DetailsCardConfig): DetailsCardData => {
  // eslint-disable-next-line camelcase
  const transferStatus = details.associatedWithTransferItemState[0]?.transfer_status;
  const status = TRANSFER_STATUS_MAP[transferStatus];
  const transferOrder = details.itemInTransfer?.[0]?.ofTransferOrder?.[0];

  const augmentedDetails = {
    ...details,
    totalItems: details.contains.length,
    trackerSerial: details.hasSensorProfile[0]?.vid,
    transferOrder,
    transferOrderIdentifier: transferOrder?.identifier
  };

  return {
    id: details.id,
    name: details.name,
    identifier: details.identifier,
    statuses: status ? [status] : [],
    imageUrls: details.images,
    defaultImage: details.ofType[0]?.image_path,
    displayedProperties: parseProperties(augmentedDetails, detailsCardConfig.fields)
  };
};

const parseContainerItems = (items: ContainerItem[]): TransferOrderItem[] => {
  return items.map((item) => {
    const transferItemState = item.associatedWithTransferItemState?.[0];
    const { transfer_status: transferStatus } = transferItemState || {};
    const status = TRANSFER_STATUS_MAP[transferStatus];

    const location = item.associatedWithSession?.[0]?.lastDetectedLocation?.[0] || {};
    const locationProperties = Object.entries(location).reduce((acc: Record<string, unknown>, [key, value]) => {
      acc[`location_${key}`] = value;
      return acc;
    }, {});

    const itemType = item.ofType?.[0] || {};
    const itemTypeProperties = Object.entries(itemType).reduce((acc: Record<string, unknown>, [key, value]) => {
      acc[`itemType_${key}`] = value;
      return acc;
    }, {});

    return {
      ...item,
      ...itemTypeProperties,
      ...locationProperties,
      transferStatus,
      status: status ? [status] : [],
      statusSortValue: status?.sortIndex,
      vid: item.hasSensorProfile[0]?.vid
    };
  });
};

const parseProperties = (
  details: Record<string, unknown>,
  propertiesToDisplay: DetailsCardField[]
): DetailCardItemProperty[] => {
  return propertiesToDisplay.reduce((acc: DetailCardItemProperty[], property) => {
    const { key, defaultValue = "-" } = property;

    acc.push({
      ...property,
      name: property.label,
      value: details[key] || defaultValue
    });

    return acc;
  }, []);
};
