export const getLabelByItemClass = (itemClass, transferOrderClass) => {
  switch (itemClass) {
    case "Traveller":
      return "Order";
    case "Part":
    case "Asset":
      return itemClass;
    case "TransferOrder":
      switch (transferOrderClass) {
        case "Package":
          return "Package Order";
        default:
          return "Inventory Report";
      }
    case "Inventory":
      return "Item";
    default:
      return "";
  }
};
