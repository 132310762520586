export const ADD_ITEM_ASSOCIATION = "Add Item Association";

export const ADD_ITEM_ASSOCIATION_STEPS = {
  ENTRY: "entry",
  SUBMISSION_SUCCESSFUL: "Submission Successful",
  SUBMISSION_FAILED: "Submission Failed"
};

export const BULK_UPDATE_ITEM_ASSOCIATION = "Bulk Update Item Association";

export const BULK_UPDATE_ITEM_ASSOCIATION_STEPS = {
  ENTRY: "entry",
  SUBMISSION_SUCCESSFUL: "Submission Successful",
  SUBMISSION_FAILED: "Submission Failed"
};
