import React, { useState, useMemo } from "react";
import {
  VictoryChart,
  VictoryZoomContainer,
  VictoryAxis,
  VictoryBrushContainer,
  VictoryBar,
  VictoryTooltip,
  VictoryTheme,
  VictoryLabel,
  VictoryStack
} from "victory";
import Style from "./BarChart.module.css";
import ChartLoadingIcon from "../chart-loading-icon";
import xemelgoStyle from "../../styles/variable";

const MAX_NUMBER_PER_VIEW = 9;

interface Value {
  value: number;
  color?: string;
  title?: string;
}

interface ListItem {
  title?: string;
  x: string | number;
  values: Value[];
}

interface BarChartProps {
  dataList?: ListItem[];
  width?: number;
  isLoading?: boolean;
}

interface BarListItem {
  x: string | number;
  y: number;
  color?: string;
  [key: string]: any;
}

type BarDataList = BarListItem[];

interface SummaryListItem extends ListItem {
  totalValue: number;
  maxY: number;
}

export const BarChart: React.FC<BarChartProps> = ({ dataList = [], width = 600, isLoading }) => {
  const [zoomDomain, setZoomDomain] = useState<any>({
    x: [0.5, dataList.length <= MAX_NUMBER_PER_VIEW ? dataList.length + 0.5 : 9.5]
  });

  const handleZoom = (domain: any) => {
    setZoomDomain(domain);
  };

  const barDataList = useMemo<BarDataList[]>(() => {
    const labelToValuesMap = dataList.reduce((accumulator: { [key: string]: BarListItem[] }, { x, values }) => {
      values.forEach(({ value, ...rest }, index) => {
        accumulator[index] = [...(accumulator[index] || []), { x, y: value, ...rest }];
      });

      return accumulator;
    }, {});

    return Object.values(labelToValuesMap);
  }, [dataList]);

  const summaryDataList = useMemo<SummaryListItem[]>(() => {
    const dataWithTotal = dataList.map((item) => {
      const totalValue = item.values.reduce((sum, v) => {
        return sum + v.value;
      }, 0);
      return { ...item, totalValue };
    });
    const maxY =
      Math.max(
        ...dataWithTotal.map((item) => {
          return item.totalValue;
        })
      ) || 1;
    return dataWithTotal.map((item) => {
      return {
        ...item,
        maxY
      };
    });
  }, [dataList]);

  if (isLoading) {
    return (
      <div className={Style.loading_container}>
        <ChartLoadingIcon />
      </div>
    );
  }

  return (
    <div className={Style.outer_container}>
      <div className={Style.inner_container}>
        {dataList.length > MAX_NUMBER_PER_VIEW && (
          <VictoryChart
            domainPadding={25}
            padding={{ top: 0, left: 50, right: 50, bottom: 10 }}
            width={width}
            height={75}
            containerComponent={
              <VictoryBrushContainer
                allowDraw={false}
                allowResize={false}
                brushDimension="x"
                brushDomain={dataList.length <= MAX_NUMBER_PER_VIEW ? null : { x: [0, 9.5] }}
                onBrushDomainChange={handleZoom}
              />
            }
          >
            <VictoryAxis
              tickFormat={(x) => {
                return x;
              }}
            />
            <VictoryStack>
              {barDataList.map((eachBarDataList, index) => {
                return (
                  <VictoryBar
                    key={`zoom-window-${JSON.stringify(eachBarDataList)}-${index}`}
                    labelComponent={<></>}
                    data={eachBarDataList}
                    style={{
                      data: {
                        fill: ({ datum }) => {
                          return datum.color || xemelgoStyle.theme.XEMELGO_DEEP_BLUE;
                        }
                      }
                    }}
                    x="x"
                    y="y"
                  />
                );
              })}
            </VictoryStack>
          </VictoryChart>
        )}
        <VictoryChart
          padding={{ top: 20, left: 50, right: 50, bottom: 30 }}
          theme={VictoryTheme.material}
          width={width}
          height={325}
          domainPadding={{ x: [15, 15], y: [30, 30] }}
          containerComponent={
            <VictoryZoomContainer
              zoomDomain={zoomDomain}
              disable
            />
          }
        >
          <VictoryAxis
            dependentAxis
            tickFormat={(t) => {
              return Number.isInteger(t) ? t : null;
            }}
            style={{
              tickLabels: { fill: xemelgoStyle.theme.TEXT_SECONDARY, fontWeight: "bold" },
              ticks: { strokeWidth: 1.25, stroke: xemelgoStyle.theme.TEXT_SECONDARY },
              axis: { strokeWidth: 1, stroke: xemelgoStyle.theme.APP_BORDER_GREY }
            }}
          />
          <VictoryAxis
            tickFormat={(x) => {
              if (dataList.length > MAX_NUMBER_PER_VIEW) {
                return x.length > 5 ? `${x.substring(0, 5)}...` : x;
              }
              return x;
            }}
            style={{
              tickLabels: {
                fill: xemelgoStyle.theme.TEXT_SECONDARY,
                fontWeight: "500",
                fontSize: 14,
                verticalAnchor: "middle"
              },
              ticks: { strokeWidth: 1.25, stroke: xemelgoStyle.theme.TEXT_SECONDARY },
              axis: { strokeWidth: 1, stroke: xemelgoStyle.theme.APP_BORDER_GREY }
            }}
          />
          <VictoryStack>
            {barDataList.map((eachBarDataList, index) => {
              return (
                <VictoryBar
                  key={`${JSON.stringify(eachBarDataList)}-${index}`}
                  labelComponent={<></>}
                  barWidth={40}
                  cornerRadius={2}
                  data={eachBarDataList}
                  style={{
                    data: {
                      fill: ({ datum }) => {
                        return datum.color || xemelgoStyle.theme.XEMELGO_DEEP_BLUE;
                      }
                    }
                  }}
                  x="x"
                  y="y"
                />
              );
            })}
          </VictoryStack>
          <VictoryBar
            events={[
              {
                target: "data",
                eventHandlers: {
                  onMouseEnter: () => {
                    return [
                      {
                        eventKey: "all",
                        target: "data",
                        mutation: () => {
                          return {
                            style: {
                              fill: "transparent"
                            }
                          };
                        }
                      },
                      {
                        target: "data",
                        mutation: () => {
                          return {
                            style: {
                              fill: xemelgoStyle.theme.TEXT_SECONDARY,
                              opacity: 0.3
                            }
                          };
                        }
                      }
                    ];
                  },
                  onMouseLeave: () => {
                    return [
                      {
                        eventKey: "all",
                        target: "data",
                        mutation: () => {
                          return {
                            style: {
                              fill: "transparent"
                            }
                          };
                        }
                      },
                      {
                        target: "data",
                        mutation: () => {
                          return {
                            style: {
                              fill: "transparent"
                            }
                          };
                        }
                      }
                    ];
                  }
                }
              }
            ]}
            barWidth={50}
            cornerRadius={2}
            data={summaryDataList}
            style={{
              data: {
                fill: "transparent"
              }
            }}
            x="x"
            y="maxY"
            labels={({ datum }) => {
              return [
                [`${datum.title}: `, datum.x],
                ...datum.values.map((item: Value) => {
                  return [`${item.title}: `, item.value];
                }),
                ["Total: ", datum.totalValue]
              ];
            }}
            labelComponent={
              <VictoryTooltip
                cornerRadius={5}
                constrainToVisibleArea
                centerOffset={{ y: -10 }}
                flyoutPadding={10}
                pointerLength={0}
                flyoutStyle={{
                  stroke: xemelgoStyle.theme.TEXT_SECONDARY,
                  strokeWidth: 0.5,
                  fill: "white"
                }}
                style={{ fill: xemelgoStyle.theme.TEXT_PRIMARY, fontWeight: 400 }}
                labelComponent={<VictoryLabel lineHeight={1.5} />}
              />
            }
          />
        </VictoryChart>
      </div>
    </div>
  );
};
