import React, { useCallback, useState } from "react";
import PaginatedListTableWithTypes, { Header, Item } from "../../../../components/paginated-list-table-with-types";
import Style from "./DetailsPageTable.module.css";
import { PaginatedListTableWithTypesProps } from "../../../../components/paginated-list-table-with-types/PaginatedListTableWithTypes";
import FreeTextSearchInput from "../../../../components/free-text-search-input";
import { ReactComponent as ExportIcon } from "../../../../assets/icons/export.svg";
import useFilteredTableData from "../../../../hooks/use-filtered-table-data";
import createCsv from "../../../../components/paginated-list-table-with-types/utils/create-csv";
import MultiSelectActionsBar, { MultiSelectOption } from "../../../../components/multi-select-actions-bar";

interface DetailsPageTableProps extends PaginatedListTableWithTypesProps {
  dataList: Item[];
  isLoading?: boolean;
  headers: Header[];
  isExpandable?: boolean;
  searchPlaceholder?: string;
  onExport?: (filteredData: Item[], event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  csvFileName?: string;
  pageSize?: number;
  textSearchEnabled?: boolean;
  exportEnabled?: boolean;
  additionalButtons?: AdditionalButtonType[];
  multiSelectActionsBarEnbaled?: boolean;
  numOfSelectedItem?: number;
  multiSelectOptions?: MultiSelectOption[];
  headerContainerClassName?: string;
  rowClassName?: string;
  rowContainerClassName?: string;
  onOptionClick?: (option: MultiSelectOption) => void;
  onDeselectAllClick?: () => void;
  [key: string]: any;
}

type AdditionalButtonType = {
  label: string;
  onClick: () => void;
};

export const DetailsPageTable = ({
  dataList,
  isLoading,
  headers,
  isExpandable,
  searchPlaceholder = "Type to filter",
  onExport,
  csvFileName = "Details",
  pageSize,
  textSearchEnabled = true,
  exportEnabled = true,
  additionalButtons = [],
  multiSelectActionsBarEnbaled,
  numOfSelectedItem = 0,
  multiSelectOptions = [],
  headerContainerClassName = Style.header_row_container,
  rowClassName,
  rowContainerClassName = Style.table_item_row,
  onOptionClick = () => {},
  onDeselectAllClick = () => {},
  ...props
}: DetailsPageTableProps) => {
  const [searchInput, setSearchInput] = useState("");
  const { filteredData } = useFilteredTableData(dataList, headers, searchInput);

  const defaultExportCsv = useCallback(() => {
    createCsv(filteredData, headers, csvFileName);
  }, [filteredData, headers, csvFileName]);

  const renderMultiSelectActionsBar = () => {
    return (
      <MultiSelectActionsBar
        numOfSelectedItem={numOfSelectedItem}
        onOptionClick={onOptionClick}
        onDeselectAllClick={onDeselectAllClick}
        multiSelectOptions={multiSelectOptions}
      />
    );
  };

  return (
    <div className={Style.container}>
      {(exportEnabled || textSearchEnabled || additionalButtons.length > 0) && (
        <div className={Style.options_container}>
          {additionalButtons.map((button: AdditionalButtonType) => {
            return (
              <button
                key={button.label}
                type="button"
                className={Style.blue_button}
                onClick={button.onClick}
              >
                {button.label}
              </button>
            );
          })}
          {textSearchEnabled && (
            <div className={Style.free_text_search_container}>
              <FreeTextSearchInput
                value={searchInput}
                onChangeText={setSearchInput}
                searchInputClassName={Style.free_text_search_input}
                placeholder={searchPlaceholder}
              />
            </div>
          )}
          {exportEnabled && (
            <button
              type="button"
              className={`${Style.button} ${Style.white_button} ${Style.export_button}`}
              data-cy="details-page-table-export-button"
              onClick={(event) => {
                if (onExport) {
                  onExport(filteredData, event);
                } else {
                  defaultExportCsv();
                }
              }}
            >
              <ExportIcon className={Style.export_icon} />
              Export
            </button>
          )}
        </div>
      )}
      <PaginatedListTableWithTypes
        dataList={filteredData}
        isLoading={isLoading}
        headers={headers}
        isExpandable={isExpandable}
        headerRowContainerClassName={headerContainerClassName}
        headerCellContainerClassName={Style.table_header}
        itemCellContainerClassName={Style.table_item_container}
        rowClassName={rowClassName}
        itemRowContainerClassName={isLoading ? Style.loading_table_item_row : rowContainerClassName}
        emptyListContainerClassName={Style.empty_list_container}
        numItemsPerPage={pageSize}
        renderPaginatorLeftContent={multiSelectActionsBarEnbaled ? renderMultiSelectActionsBar : undefined}
        paginatorLocation="top"
        {...props}
      />
    </div>
  );
};
