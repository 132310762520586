import { ITEM_SCAN_STATUS_MAP } from "../../features/selected-kiosk-feature/features/transfer-order-actions/data/constants";

const generateItemTypeReport = (items) => {
  return items.reduce((map, item) => {
    const { type, firstSeenTimestamp } = item;
    const itemTypeKey = type.identifier;

    const clonedMap = { ...map };
    const itemTypeReport = clonedMap[itemTypeKey] || {
      ...type,
      epcToItemMap: {},
      quantityScanned: 0,
      lastUpdatedTimestamp: 0
    };

    itemTypeReport.epcToItemMap[item.vid] = {
      id: item.itemId,
      ...item,
      scanStatus: ITEM_SCAN_STATUS_MAP.SCANNED,
      rowOptions: [
        {
          id: "clear",
          label: "Clear"
        }
      ]
    };
    itemTypeReport.lastUpdatedTimestamp =
      itemTypeReport.lastUpdatedTimestamp < firstSeenTimestamp
        ? firstSeenTimestamp
        : itemTypeReport.lastUpdatedTimestamp;
    itemTypeReport.quantityScanned = Object.keys(itemTypeReport.epcToItemMap).length;
    itemTypeReport.qty = itemTypeReport.quantityScanned;

    clonedMap[itemTypeKey] = itemTypeReport;

    return clonedMap;
  }, {});
};

export const analyzeItemsForItemTypeReport = (items) => {
  const itemTypeReportMap = generateItemTypeReport(items);
  const sorted = Object.values(itemTypeReportMap).sort((a, b) => {
    return a.lastUpdatedTimestamp - b.lastUpdatedTimestamp;
  });
  return sorted;
};
