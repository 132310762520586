import React from "react";
import Style from "./TableControlActions.module.css";
import FeatureButtons from "./features/feature-buttons";
import FreeTextSearch from "./features/free-text-search";
import ExportCsv from "./features/export-csv";
import TableViewTypeControl from "./features/table-view-type-control";
import DisplayColumnControl from "./features/display-column-control";

export const TableControlActions = () => {
  return (
    <div className={Style.container}>
      <div className={Style.row}>
        <TableViewTypeControl />
      </div>
      <div className={Style.row}>
        <FeatureButtons />
        <FreeTextSearch />
        <DisplayColumnControl />
        <ExportCsv />
      </div>
    </div>
  );
};
