export const RETRY_DELAY_MS = 250;

const OPERATIONS_PATH_SEGMENT = "operations";
const DETAILS_PATH_SEGMENT = "details";

export const EDGE_OPERATION_MAP = {
  START: {
    operationName: "start",
    pathSegment: OPERATIONS_PATH_SEGMENT,
    isTenantSpecific: false,
    method: "POST"
  },
  STOP: {
    operationName: "stop",
    pathSegment: OPERATIONS_PATH_SEGMENT,
    isTenantSpecific: false,
    method: "POST"
  },
  ENABLE_INGESTION_RULE: {
    operationName: "enable-ingestion-rule",
    pathSegment: OPERATIONS_PATH_SEGMENT,
    isTenantSpecific: true,
    method: "POST"
  },
  DISABLE_INGESTION_RULE: {
    operationName: "disable-ingestion-rule",
    pathSegment: OPERATIONS_PATH_SEGMENT,
    isTenantSpecific: true,
    method: "POST"
  },
  SET_GPO_SETTINGS: {
    operationName: "gpo-settings",
    pathSegment: OPERATIONS_PATH_SEGMENT,
    isTenantSpecific: false,
    method: "POST"
  },
  GET_GPO_SETTINGS: {
    operationName: "gpo-settings",
    pathSegment: DETAILS_PATH_SEGMENT,
    isTenantSpecific: false,
    method: "GET"
  }
};
