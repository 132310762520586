import React, { useMemo } from "react";
import PropTypes from "prop-types";
import MultiInputForm from "../../../../../../../../components/multi-input-form";
import Style from "./TopPanel.module.css";
import useKioskConfigContext from "../../../../../../contexts/kiosk-config-context";
import useKioskStateContext from "../../../../../../contexts/kiosk-state-context";
import { READ_STATUS_OPTIONS } from "../../../../../../../../hooks/use-mounted-reader";

export const TopPanel = ({ onClear, readStatus, onReadStatusUpdate }) => {
  const { topPanelAttributes, autoStartScanning } = useKioskConfigContext();
  const { locationOptions, panelValues, setPanelValues, itemByTag, availableReaders, loading } = useKioskStateContext();

  const topPanelForm = useMemo(() => {
    return topPanelAttributes.map((eachField) => {
      const { id } = eachField;
      if (id === "location") {
        return { ...eachField, value: panelValues[id], options: locationOptions };
      }

      if (id === "readerLocation") {
        return { ...eachField, value: panelValues[id], options: availableReaders };
      }
      return { ...eachField, value: panelValues[id] };
    });
  }, [topPanelAttributes, availableReaders, locationOptions, panelValues]);

  const onTopPanelChange = (id, value) => {
    const clonedTopPanelValueMap = { ...panelValues };

    if (id === "readerLocation" && readStatus.id !== READ_STATUS_OPTIONS.NOT_READING.id) {
      // Call onReadStatusUpdate so that the abort signal is sent
      onReadStatusUpdate(READ_STATUS_OPTIONS.STOPPING, panelValues.readerLocation?.detectorSerial);
    }

    setPanelValues({ ...clonedTopPanelValueMap, [id]: value });
  };

  const itemCount = useMemo(() => {
    return Object.keys(itemByTag).length;
  }, [itemByTag]);

  return (
    <div className={Style.top_panel_container}>
      <div className={Style.status_count_section}>
        <p className={Style.status_count_label}>Items Scanned: </p>
        <p className={Style.status_count_value}>{itemCount}</p>
      </div>
      <MultiInputForm
        formFields={topPanelForm}
        onChange={onTopPanelChange}
        fieldContainerClassName={Style.top_panel_field_container}
        containerClassName={Style.input_fields_container}
        isLoading={loading}
        loadingInputClassName={Style.loading_input}
        loadingTextClassName={Style.loading_input_text}
        numSkeletons={2}
      />
      <div className={Style.buttons_container}>
        <button
          type="button"
          disabled={!Object.keys(itemByTag).length}
          className={Style.clear_all_button}
          onClick={onClear}
        >
          Clear All
        </button>
        {!autoStartScanning && (
          <button
            type="button"
            className={Style.scan_button}
            onClick={() => {
              onReadStatusUpdate(readStatus);
            }}
            disabled={readStatus.buttonIsDisabled || !panelValues.readerLocation?.detectorSerial}
          >
            {readStatus.buttonLabel}
          </button>
        )}
      </div>
    </div>
  );
};

TopPanel.defaultProps = {
  onClear: () => {}
};

TopPanel.propTypes = {
  onClear: PropTypes.func,
  readStatus: PropTypes.shape({
    id: PropTypes.string,
    buttonLabel: PropTypes.string,
    buttonIsDisabled: PropTypes.bool
  }).isRequired,
  onReadStatusUpdate: PropTypes.func.isRequired
};
