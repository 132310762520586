export const SIDE_FILTER_CATEGORIES = {
  dateTime: "DateTimeFilters"
};

export const TEMPLATE_OPTIONS = {
  location: "location",
  itemType: "itemType",
  status: "status",
  itemTypeCategory: "itemTypeCategory",
  inventoryItemTypeMetrics: "inventoryItemTypeMetrics"
};

export const STATUS_MAP = {
  expedited: "Expedited",
  lateToStart: "Delayed",
  timeExceeded: "Time Exceeded",
  inProgress: "In Progress",
  planned: "Planned"
};

export const STATUS_TITLE_MAP = {
  [STATUS_MAP.expedited]: "Expedited",
  [STATUS_MAP.lateToStart]: "Late To Start",
  [STATUS_MAP.timeExceeded]: "Time Exceeded",
  [STATUS_MAP.inProgress]: "In Progress",
  [STATUS_MAP.planned]: "Released"
};

export const STATUS_SEVERITY_MAP = {
  warning: "Warning",
  critical: "Critical",
  expedited: "Expedited",
  healthy: "Healthy"
};
