import React, { useState, useEffect } from "react";
import { useDisplayBannerContext } from "context/DisplayBannerContext/DisplayBannerContext";
import Spinner from "react-bootstrap/Spinner";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import { useSubFeatureConfigProvider } from "../../../../services/soft-cache-service";
import DisplayBanner from "../../../../components/display-banner/DisplayBanner";
import InputGroup from "../../../../components/my-facility-v2-components/InputGroup";
import EditLocationFormV2Style from "./EditLocationFormV2.module.css";
import { getPartnerDisplayValue, getPartnerPropertyFromConfig } from "../../../list-partners/ListPartnersUtil";
import { ReactComponent as MyFacilityIcon } from "../../../../assets/icons/my-facility.svg";
import OnboardItemModal from "components/onboard-item-modal";
import xemelgoStyle from "../../../../styles/variable";
import "./EditLocationFormV2.css";

const SubFeatureId = "editLocationForm";

const getProvidedOptionsMaps = async (xClient, partnerDisplayProperty) => {
  const partners = await getPartners(xClient);
  return {
    partner: partners.map((partner) => {
      return { key: partner.id, value: getPartnerDisplayValue(partner, partnerDisplayProperty) };
    })
  };
};

const getPartners = async (xClient) => {
  // fetch the data
  const partnerClient = xClient.getPartnerClient();
  const results = await partnerClient.listPartners();
  return results;
};

export const EditLocationFormV2 = ({ appId, featureId, location, show, onFormClose, onSave, modelId }) => {
  const { setShowBanner, setBannerTitle, setBannerHasError } = useDisplayBannerContext();
  const [loading, setLoading] = useState(true);
  const [innerLocations, setInnerLocations] = useState([]);
  const [showModalBanner, setShowModalBanner] = useState(false);
  const [modalBannerMessage, setModalBannerMessage] = useState("");
  const [properties, setProperties] = useState([]);
  const [defaultValues, setDefaultValues] = useState({});
  const [editedProperties, setEditedProperties] = useState({});
  const [xemelgoClient] = useState(useXemelgoClient());
  const [configProvider] = useState(useSubFeatureConfigProvider(appId, featureId, SubFeatureId));

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    const tempDefaultValues = { ...location };
    const model = configProvider.getModel(modelId);
    const displayName = model.getValue("displayName", "string", tempDefaultValues.category);
    const modelConfig = model.getDefinitionObject();
    const { roles } = modelConfig;
    const partnerDisplayProperty = getPartnerPropertyFromConfig(configProvider, "displayProperty", "string", "name");
    const optionsMap = await getProvidedOptionsMaps(xemelgoClient, partnerDisplayProperty);
    const tempProperties = model
      .getProperties()
      .map((property) => {
        const resolvedProperty = {
          ...property,
          name: property.id,
          displayName: `${displayName} ${property.displayName}`,
          optional: true,
          disabled: !property.__updatable
        };

        let { options, optionsProvided } = resolvedProperty;
        if (optionsProvided) {
          options = optionsMap[property.id];
        }

        const formattedOptions = options?.map((option) => {
          return {
            ...option,
            id: option.key,
            label: option.value,
            value: option.value
          };
        });

        resolvedProperty.options = formattedOptions;

        return resolvedProperty;
      })
      .filter((prop) => {
        return !prop.hidden;
      });

    if (roles && roles.options) {
      let rolesOptions;
      rolesOptions = roles.options.map((option) => {
        return {
          id: option.name,
          label: option.name,
          value: option.name
        };
      });

      tempProperties.push({
        name: "role",
        displayName: `${displayName} Role`,
        options: rolesOptions,
        optional: true
      });

      tempDefaultValues.role =
        tempDefaultValues?.roles
          ?.map((role) => {
            return role.name;
          })
          .join(", ") || "";
    }

    if (tempDefaultValues.parent) {
      tempProperties.unshift({
        name: "parent",
        displayName: `${tempDefaultValues.parent.category} Name`,
        optional: true,
        disabled: true
      });
      tempDefaultValues.parent = tempDefaultValues.parent.name;
    }

    if (tempDefaultValues.managedForPartner) {
      tempDefaultValues.partnerId = tempDefaultValues.managedForPartner.id;
      tempDefaultValues.partner = getPartnerDisplayValue(tempDefaultValues.managedForPartner, partnerDisplayProperty);
    }

    const locationClient = xemelgoClient.getLocationClient();
    const locations = await locationClient.getLocationChildrenByParentId(tempDefaultValues.id);
    const sorted = locations.sort((loc1, loc2) => {
      return loc1.name.localeCompare(loc2.name);
    });

    setInnerLocations(sorted);
    setLoading(false);
    setDefaultValues(tempDefaultValues);
    setProperties(tempProperties);
  };

  // getLocationChildrenByParentId to query and show children

  const handleEdit = (id, value) => {
    const tempEditedProperties = { ...editedProperties };
    tempEditedProperties[id] = value;
    setEditedProperties(tempEditedProperties);
  };

  const handleUpdateLocation = async () => {
    const locationClient = xemelgoClient.getLocationClient();
    const { role = {} } = editedProperties;
    const { id: roleName } = role;
    const editedPartner = editedProperties.partner;
    let roleId;
    let partnerToAttachId;

    delete editedProperties.role;
    delete editedProperties.partner;

    if (roleName) {
      const roleResult = await locationClient.getRolesByNames([roleName]);
      const { locationRoles = [{}] } = roleResult;
      roleId = locationRoles[0]?.id;
    }

    if (editedPartner) {
      const { id: partnerId } = editedPartner;
      if (partnerId !== location.managedForPartner?.id) {
        partnerToAttachId = partnerId;
      }
    }

    await locationClient.updateLocationV2(
      defaultValues.id,
      editedProperties,
      roleId
        ? location.roles.map((role) => {
            return role.id;
          })
        : [],
      roleId ? [roleId] : [],
      partnerToAttachId ? location.managedForPartner?.id : null,
      partnerToAttachId
    );
  };

  const verifyPayload = async () => {
    const tempProperties = [...properties];
    let canSubmit = true;
    if (editedProperties.name !== undefined && editedProperties.name !== defaultValues.name) {
      if (!editedProperties.name) {
        canSubmit = false;
        setModalBannerMessage("Location name cannot be empty!");
      } else {
        const locationClient = xemelgoClient.getLocationClient();
        const locationResult = await locationClient.getLocationsByNames([editedProperties.name]);
        if (locationResult.length > 0) {
          canSubmit = false;
          setModalBannerMessage(`A location with name ${editedProperties.name} already exists!`);
        }
        if (!canSubmit) {
          const name = tempProperties.find((property) => {
            return property.name === "name";
          });
          name.error = true;
        }
      }
    }
    setProperties(tempProperties);
    return canSubmit;
  };

  const renderLoading = () => {
    return (
      <div className={EditLocationFormV2Style.loading_circle}>
        <Spinner animation="border" />
      </div>
    );
  };

  return (
    <OnboardItemModal
      scrollable
      show={show}
      prefix="location"
      className={EditLocationFormV2Style.location_modal}
      titleIconComponent={
        <MyFacilityIcon
          width={42}
          height={42}
          className={EditLocationFormV2Style.title_icon}
        />
      }
      submitDisabled={false}
      submitLoading={loading}
      onSubmit={async () => {
        setLoading(true);
        const canSubmit = await verifyPayload();
        if (canSubmit) {
          await handleUpdateLocation();
          setBannerHasError(false);
          setBannerTitle(`Successfully updated ${location.name}!`);
          setShowBanner(true);
          onSave();
        } else {
          setShowModalBanner(true);
        }
        setLoading(false);
      }}
      onClose={onFormClose}
      modalContainerClassName={EditLocationFormV2Style.modal_container}
      title={`Edit ${location.name}`}
    >
      {loading ? (
        renderLoading()
      ) : (
        <div>
          {showModalBanner && (
            <DisplayBanner
              onCloseBanner={() => {
                setShowModalBanner(false);
              }}
              bannerMessage={modalBannerMessage}
              bannerError
            />
          )}
          <InputGroup
            title="Location Information"
            properties={properties}
            onChange={handleEdit}
            values={editedProperties}
            placeholders={defaultValues}
          />
          {innerLocations.length > 0 && (
            <div className={EditLocationFormV2Style.inner_location_container}>
              <div className={EditLocationFormV2Style.inner_location_title_text}>{innerLocations[0].category}</div>
              <div className={EditLocationFormV2Style.inner_locations_list_container}>
                {innerLocations.map((innerLocation, index) => {
                  return (
                    <div
                      key={innerLocation.name}
                      className={EditLocationFormV2Style.inner_location_item}
                      style={{
                        borderBottom: index < innerLocations.length - 1 && `0.1em solid ${xemelgoStyle.APP_BORDER_GREY}`
                      }}
                    >
                      {innerLocation.name}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </OnboardItemModal>
  );
};
