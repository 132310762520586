import React from "react";
import queryString from "query-string";
import "./AlertsPage.css";
import RuleConfigurationSection from "./RuleConfigurationSection";
import TabbedSectionComponent from "../../components/vertical-tab-with-contents/TabbedSectionComponent";
import NotificationSettings from "./NotificationSettings";
import { ReactComponent as AlertIcon } from "../../assets/icons/alerts.svg";
import ScreenFrame from "../../components/screen-frame";
import xemelgoStyle from "../../styles/variable";
import { MixpanelContext } from "../../context/mixpanel-context/MixpanelContext";
import { ALERTS_APP_EVENT, ALERTS_APP_EVENT_STEPS } from "../../constants/mixpanel-constant/alertsApp";

export default class AlertsPage extends React.Component {
  static contextType = MixpanelContext;

  componentDidMount() {
    const { sendMixPanelEvent } = this.context;
    sendMixPanelEvent(ALERTS_APP_EVENT, ALERTS_APP_EVENT_STEPS.ENTRY);
  }

  render() {
    /*
     * to navigate to Notifications page from
     * "See all Notifications" button under the 'bell' icon
     */

    let activeTab = 0;
    if (window.location.search) {
      activeTab = +queryString.parse(window.location.search).activetab;
    }

    const tabContents = [
      {
        name: "Rules",
        component: RuleConfigurationSection
      },
      {
        name: "Notification Settings",
        component: NotificationSettings
      }
    ];

    return (
      <ScreenFrame
        title="Rules"
        color={xemelgoStyle.theme.XEMELGO_BLUE}
        secondaryColor={xemelgoStyle.theme.XEMELGO_LIGHTBLUE}
        titleIconComponent={
          <AlertIcon
            width={25}
            height={25}
            style={{
              color: xemelgoStyle.theme.XEMELGO_BLUE
            }}
          />
        }
      >
        <div className="alerts_page_main_container">
          <TabbedSectionComponent
            className="rule-tab-selection"
            tabContents={tabContents}
            activetab={activeTab}
          />
        </div>
      </ScreenFrame>
    );
  }
}
