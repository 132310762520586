import React, { useCallback, useMemo, useState } from "react";
import DetailsPage from "../details-page";
import { ReactComponent as InventoryIcon } from "../../assets/icons/inventory.svg";
import { SOLUTIONS } from "../../data/constants";
import fetchContaineritem from "./utils/fetch-container-item";
import {
  AdditionalAttributes,
  DetailsCardConfig
} from "../details-page/contexts/details-page-config-context/data/types";
import { ContainerDetails } from "./contants/types";
import xemelgoStyle from "../../styles/variable";
import useDetailsPageConfigContext, {
  DetailsPageConfigContextProvider
} from "../details-page/contexts/details-page-config-context";
import TransferOrderItemsTable from "../transfer-order-details-page/features/transfer-order-items-table";
import TransferOrderContainerOverviewTable from "./features/transfer-order-container-overview-table";
import ItemRouteFeature from "../item-route-feature";

interface ContainerDetailsPageProps {
  solutionId?: string;
  path: string;
}

const ContainerDetailsPage = ({ path }: { path: string }) => {
  const [details, setDetails] = useState<ContainerDetails | null>(null);
  const { subfeatureOptions, moreOptions } = useDetailsPageConfigContext();

  const onQueryDetails = useCallback(
    async (id: string, additionalAttributes: AdditionalAttributes, detailsCardConfig: DetailsCardConfig) => {
      if (!id) {
        throw new Error("No id provided");
      }

      const transferOrder = await fetchContaineritem(id, additionalAttributes, detailsCardConfig);

      setDetails(transferOrder);
      return transferOrder.detailsCardData;
    },
    []
  );

  const transferOrderSubfeatureMap = useMemo(() => {
    if (!details) {
      return {} as Record<string, JSX.Element>;
    }

    return {
      transferOrderItemsTable: (
        <TransferOrderItemsTable
          items={details.items}
          transferOrderIdentifier={details?.identifier}
          options={subfeatureOptions.transferOrderItems}
        />
      ),
      transferOrderContainerOveriewTable: (
        <TransferOrderContainerOverviewTable
          items={details.items}
          options={subfeatureOptions.transferOrderContainerOveriewTable}
        />
      ),
      inventoryItemRoute: (
        <ItemRouteFeature
          solution={SOLUTIONS.INVENTORY}
          itemId={details.id}
          itemIdentifier={details.identifier}
          options={subfeatureOptions.inventoryItemRoute}
        />
      )
    };
  }, [details, subfeatureOptions]);

  return (
    <DetailsPage
      defaultTitle="Pallet Details"
      featureMap={transferOrderSubfeatureMap}
      onQueryDetails={onQueryDetails}
      editImagesEnabled
      moreOptions={moreOptions}
      primaryColor={xemelgoStyle.theme.INVENTORY_PRIMARY}
      secondaryColor={xemelgoStyle.theme.INVENTORY_SECONDARY}
      iconComponent={InventoryIcon}
      path={path}
    />
  );
};

export default ({ solutionId = SOLUTIONS.CONTAINER, path }: ContainerDetailsPageProps) => {
  return (
    <DetailsPageConfigContextProvider solutionId={solutionId}>
      <ContainerDetailsPage path={path} />
    </DetailsPageConfigContextProvider>
  );
};
