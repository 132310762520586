import React, { useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep } from "lodash";
import { TransferOrder, TransferOrderBySearch } from "@xemelgo/x-client";
import MainPage from "../../../main-page";
import PicklistVerificationSetup from "./features/picklist-verification-setup";
import useKioskStateContext from "../../../../../../contexts/kiosk-state-context";
import UnexpectedItemsModal from "../../components/unexpected-items-modal";
import useKioskConfigContext from "../../../../../../contexts/kiosk-config-context";
import { ITEM_SCAN_STATUS_MAP, TRANSFER_ORDER_STAGES, GPO_SCENARIOS, ITEM_TYPE_STATUS_MAP } from "../../data/constants";
import { createItemTypeToReportMap } from "./utils/create-item-type-reports";
import { fetchReaderOptions } from "./utils/fetch-reader-options";
import useTransferOrderActionsConfigContext from "../../contexts/transfer-order-actions-config-context";
import getReportStatus from "./utils/get-report-status";
import handleItemScans from "./utils/handle-item-scan";
import useGPO from "../../../../../../../../hooks/use-gpo";
import SubmissionWorkflow from "../submission-workflow";
import { ExistingTransferOrderContainer, NewTransferOrderContainer, TransferOrderStage } from "../../data/types";
import { ItemTypeReport, KioskItem } from "../../../../../../data/types";

export const PicklistVerification = () => {
  const processedItemRef = useRef<Record<string, boolean>>({});
  const itemTypeToReportRef = useRef<Record<string, ItemTypeReport>>({});
  const unexpectedItemMapRef = useRef<Record<string, KioskItem>>({});
  const [transferOrders, setTransferOrders] = useState<TransferOrder[]>([]);
  const [initialItemTypeToReport, setInitialItemTypeToReport] = useState<Record<string, ItemTypeReport>>({});
  const [unexpectedItemMap, setUnexpectedItemMap] = useState<Record<string, KioskItem>>({});
  const [hasMissingItems, setHasMissingItems] = useState<boolean>(false);
  const [selectedStage, setSelectedStage] = useState<TransferOrderStage>();
  const [existingContainer, setExistingContainer] = useState<ExistingTransferOrderContainer>();
  const [newContainer, setNewContainer] = useState<NewTransferOrderContainer>();

  const {
    itemByTag,
    setItemByTag,
    setItemTypeReports,
    isSubmitting,
    setIsSubmitting,
    setAvailableReaders,
    panelValues,
    setPanelValues,
    setLoading
  } = useKioskStateContext();
  const { additionalQueryAttributes, edgeApiUrl } = useKioskConfigContext();
  const { unexpectedScanTableHeaders, customText, allowUnexpectedItems, GPOScenarioMap, GPOPayloadMap } =
    useTransferOrderActionsConfigContext();

  const deviceId = useMemo(() => {
    return panelValues.readerLocation?.detectorSerial;
  }, [panelValues.readerLocation]);

  const { sendGPOCommand } = useGPO(deviceId, edgeApiUrl, GPOPayloadMap, GPOScenarioMap);

  useEffect(() => {
    unexpectedItemMapRef.current = unexpectedItemMap;
  }, [unexpectedItemMap]);

  useEffect(() => {
    if (deviceId) {
      sendGPOCommand(GPO_SCENARIOS.START);
    }

    return () => {
      sendGPOCommand(GPO_SCENARIOS.STOP);
    };
  }, [deviceId]);

  const initializePicklist = async (
    selectedTransferOrders: TransferOrderBySearch[],
    newStage: { label: string; value: string },
    container?: ExistingTransferOrderContainer,
    containerToCreate?: NewTransferOrderContainer
  ) => {
    setLoading(true);
    setExistingContainer(container);
    setNewContainer(containerToCreate);

    const { label: stageLabel, value: stageId } = newStage;

    const stage = TRANSFER_ORDER_STAGES[stageId];
    setSelectedStage(stage);

    const fetchReadersPromise = fetchReaderOptions(stageId);
    const createReportsPromise = createItemTypeToReportMap(selectedTransferOrders, additionalQueryAttributes, stageId);

    const [availableReaders, { itemTypeToReport: newItemTypeToReport, transferOrders: newTransferOrders }] =
      await Promise.all([fetchReadersPromise, createReportsPromise]);

    const transferOrderIdentifiersString = selectedTransferOrders
      .map((transferOrder) => {
        return transferOrder.identifier;
      })
      .sort()
      .join(", ");

    setPanelValues({
      ...panelValues,
      stage: stageLabel,
      transferOrderIdentifiersString,
      containerIdentifier: container?.identifier || containerToCreate?.identifier
    });

    itemTypeToReportRef.current = cloneDeep(newItemTypeToReport);
    setAvailableReaders(availableReaders);
    setTransferOrders(newTransferOrders);
    setInitialItemTypeToReport(cloneDeep(newItemTypeToReport));
    updateItemTypeReports();
    setLoading(false);
  };

  const updateItemTypeReports = () => {
    const newReports = Object.values(itemTypeToReportRef.current)
      .filter((report) => {
        return report.quantityTotal > 0;
      })
      .sort((a, b) => {
        if (a.status.sortIndex === b.status.sortIndex) {
          return a.identifier?.localeCompare(b.identifier);
        }

        return a.status.sortIndex - b.status.sortIndex;
      });

    const hasInvalidItems = newReports.some((report) => {
      return report.status.id === ITEM_TYPE_STATUS_MAP.extraScanned.id;
    });

    if (hasInvalidItems) {
      sendGPOCommand(GPO_SCENARIOS.UNEXPECTED_ITEM);
    }

    const hasMissingScans = newReports.some((report) => {
      return report.quantityScanned < report.quantityTotal;
    });

    setHasMissingItems(hasMissingScans);
    if (hasMissingScans) {
      sendGPOCommand(GPO_SCENARIOS.MISSING_ITEM);
    } else if (!hasInvalidItems) {
      sendGPOCommand(GPO_SCENARIOS.ALL_ITEMS_SCANNED);
    }

    setItemTypeReports(newReports);
  };

  const handleScannedItems = (newItemByTag: Record<string, KioskItem>) => {
    const newUnexpectedItemMap = handleItemScans(
      Object.values(newItemByTag),
      processedItemRef.current,
      itemTypeToReportRef.current,
      customText,
      allowUnexpectedItems
    );

    setUnexpectedItemMap({
      ...unexpectedItemMapRef.current,
      ...newUnexpectedItemMap
    });

    updateItemTypeReports();
  };

  const onClearItem = (itemTypeId: string, epc: string) => {
    const report = itemTypeToReportRef.current[itemTypeId];
    const { quantityTotal, epcToItemMap } = report;

    if (!epcToItemMap[epc]) {
      return;
    }

    processedItemRef.current[epc] = false;

    const item = epcToItemMap[epc];

    if (item?.isAssociatedWithOrder) {
      item.scanStatus = ITEM_SCAN_STATUS_MAP.NOT_SCANNED;
      item.rowOptions = [];
    } else {
      delete epcToItemMap[epc];
    }

    const scannedItems = Object.values(report.epcToItemMap).filter((reportItem) => {
      return reportItem.scanStatus?.id !== ITEM_SCAN_STATUS_MAP.NOT_SCANNED.id;
    });
    report.quantityScanned = scannedItems.length;
    report.status = getReportStatus(Object.values(report.epcToItemMap), scannedItems.length, quantityTotal);

    const updatedUnexpectedItemMap = { ...unexpectedItemMap };
    const updatedItemByTag = { ...itemByTag };

    delete updatedItemByTag[epc];
    delete updatedUnexpectedItemMap[epc];

    setUnexpectedItemMap(updatedUnexpectedItemMap);
    setItemByTag(updatedItemByTag);
    updateItemTypeReports();
  };

  const handleClearRow = (itemTypeId: string) => {
    const epcs = Object.keys(itemTypeToReportRef.current[itemTypeId].epcToItemMap);
    const updatedUnexpectedItemMap = { ...unexpectedItemMap };

    epcs.forEach((epc) => {
      processedItemRef.current[epc] = false;
      delete updatedUnexpectedItemMap[epc];
    });
    itemTypeToReportRef.current[itemTypeId] = cloneDeep(initialItemTypeToReport[itemTypeId]);

    setUnexpectedItemMap(updatedUnexpectedItemMap);
    updateItemTypeReports();
  };

  return (
    <>
      {selectedStage ? (
        <MainPage
          handleScannedItems={handleScannedItems}
          onClearReport={() => {
            itemTypeToReportRef.current = cloneDeep(initialItemTypeToReport);
            processedItemRef.current = {};
            setUnexpectedItemMap({});
            updateItemTypeReports();
          }}
          onClearReportRow={handleClearRow}
          onClearItem={onClearItem}
        />
      ) : (
        <PicklistVerificationSetup onConfirm={initializePicklist} />
      )}
      {Object.keys(unexpectedItemMap).length && !allowUnexpectedItems ? (
        <UnexpectedItemsModal
          items={Object.values(unexpectedItemMap)}
          headers={unexpectedScanTableHeaders}
          onConfirm={() => {
            const newItemByTag = { ...itemByTag };

            Object.keys(unexpectedItemMap).forEach((vid) => {
              delete newItemByTag[vid];
              processedItemRef.current[vid] = false;
            });

            setItemByTag(newItemByTag);
            setUnexpectedItemMap({});
          }}
        />
      ) : (
        isSubmitting && (
          <SubmissionWorkflow
            transferOrders={transferOrders}
            onClose={(removeUnknownItemTypes) => {
              setIsSubmitting(false);

              if (removeUnknownItemTypes) {
                const updatedItemByTag = { ...itemByTag };
                const updatedUnexpectedItemMap = { ...unexpectedItemMap };

                Object.entries(updatedUnexpectedItemMap).forEach(([vid, item]) => {
                  if (!item.type.id || !itemTypeToReportRef.current[item.type.id]) {
                    delete updatedItemByTag[vid];
                    delete updatedUnexpectedItemMap[vid];
                  }
                });

                setItemByTag(updatedItemByTag);
                setUnexpectedItemMap(updatedUnexpectedItemMap);
              }
            }}
            stage={selectedStage!}
            existingContainer={existingContainer}
            newContainer={newContainer}
            unexpectedItems={Object.values(unexpectedItemMap)}
            hasMissingItems={hasMissingItems}
            handleGPO={sendGPOCommand}
            onClearItem={onClearItem}
          />
        )
      )}
    </>
  );
};
