import React, { createContext, useContext, useMemo } from "react";
import { useAppConfigProvider } from "../../../services/soft-cache-service";
import { StatusFlag, RelationshipConfig, FormSection } from "../data/types";
import { FEATURES } from "../../../data/constants";
import { Header } from "../../../components/paginated-list-table-with-types/data/types";
import xemelgoStyle from "../../../styles/variable";

type ItemAssociationConfigContext = {
  bulkUpdateItemAssociation: FormSection[];
  itemLabel: string;
  itemQueryFields: string[];
  itemTypeQueryFields: string[];
  statusFlagMap: Record<string, StatusFlag>;
  relationshipMap: Record<string, RelationshipConfig>;
  tableControl: Header[];
  updateItemAssociation: FormSection[];
};

type ItemAssociationConfigContextProviderProps = {
  children: React.ReactNode;
  solutionId: string;
};

const defaultConfig = {
  bulkUpdateItemAssociation: [
    {
      id: "fromItem",
      index: 0,
      isRequired: true,

      label: "From Item",
      type: "search",
      options: []
    },
    {
      id: "toItem",
      index: 1,
      isRequired: true,
      label: "To Item",
      type: "search",
      options: []
    },
    {
      id: "relationship",
      index: 2,
      isRequired: true,
      label: "Relationship",
      type: "dropdown",
      options: [
        {
          value: "partOf",
          label: "Parent Item"
        },
        {
          value: "hasPart",
          label: "Sub-Item"
        }
      ]
    }
  ],
  itemLabel: "Item",
  itemQueryFields: ["id", "identifier", "name"],
  itemTypeQueryFields: ["id", "identifier"],
  relationshipMap: {
    partOf: {
      label: "Parent",
      maxConnections: 1
    },
    contains: {
      label: "Contains"
    },
    isContainedIn: {
      label: "Is Contained In"
    },
    hasPart: {
      label: "Sub-Item"
    }
  },
  statusFlagMap: {
    healthy: {
      label: "Healthy",
      color: xemelgoStyle.theme.STATUS_GREEN
    }
  },
  tableControl: [
    {
      id: "identifier",
      label: "Part #",
      isNavLink: true
    },
    {
      id: "itemTypeIdentifier",
      label: "Item Type"
    },
    {
      id: "relationship",
      label: "Relationship"
    },
    {
      id: "status",
      label: "Status",
      type: "status"
    },
    {
      id: "last_updated_time",
      label: "Last Updated",
      type: "date",
      timeFormat: "MM/DD/YYYY h:mm A"
    }
  ],
  updateItemAssociation: [
    {
      id: "itemType",
      index: 0,
      label: "Item Type",
      type: "search",
      options: [],
      optionsControl: {
        imageProperty: "image_path",
        subText: "description",
        fieldsToQuery: {
          item: [],
          itemType: ["image_path", "description"]
        }
      }
    },
    {
      id: "item",
      index: 1,
      isRequired: true,
      label: "Item",
      type: "search",
      options: [],
      optionsControl: {
        imageProperty: "itemTypeImagePath",
        subText: "description",
        fieldsToQuery: {
          item: ["description"],
          itemType: ["image_path"]
        }
      }
    },
    {
      id: "relationship",
      index: 2,
      isRequired: true,
      label: "Relationship",
      type: "dropdown",
      options: [
        {
          value: "partOf",
          label: "Parent Item"
        },
        {
          value: "hasPart",
          label: "Sub-Item"
        }
      ]
    }
  ]
};

const ItemAssociationConfigContext = createContext<ItemAssociationConfigContext>(defaultConfig);

export const useItemAssociationConfigContext = () => {
  return useContext(ItemAssociationConfigContext);
};

export const ItemAssociationConfigContextProvider = ({
  children,
  solutionId
}: ItemAssociationConfigContextProviderProps) => {
  const configProvider = useAppConfigProvider(solutionId);

  const configValues = useMemo(() => {
    const itemAssociationConfig = configProvider.getValue(FEATURES.ITEM_ASSOCIATION, "object") || {};
    return { ...defaultConfig, ...itemAssociationConfig };
  }, [configProvider]);

  return <ItemAssociationConfigContext.Provider value={configValues}>{children}</ItemAssociationConfigContext.Provider>;
};
