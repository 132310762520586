import { ScannedTag } from "../../../../hooks/use-mounted-reader/data/types";
import { XemelgoService } from "../../../../services/XemelgoService";
import { KioskItem } from "../../data/types";
import { ITEM_SCAN_STATUS_MAP } from "../../features/selected-kiosk-feature/features/transfer-order-actions/data/constants";

export const queryItemsFromParsedTags = async (
  newTags: ScannedTag[],
  existingItemByTag: Record<string, KioskItem>,
  itemTypeQueryAttributes: string[],
  upcNumberOfCharactersToTrim: number,
  showNewUPCs: boolean
): Promise<Record<string, KioskItem>> => {
  const itemTypeClient = XemelgoService.getClient().getItemTypeClient();

  if (!newTags.length) {
    return Promise.resolve({});
  }

  const upcsToQuery = [
    ...new Set(
      newTags.reduce((acc: string[], tag) => {
        const upc = tag.sgtinInfo?.upc;
        if (upc) {
          acc.push(upc.slice(upcNumberOfCharactersToTrim));
        }
        return acc;
      }, [])
    )
  ];

  const queriedUpcs = Object.values(existingItemByTag).map((item) => {
    return item.type;
  });
  const queriedTypeIdSet = new Set(
    queriedUpcs.map((type) => {
      return type.id;
    })
  );
  const newUpcsToQuery = upcsToQuery.filter((upc) => {
    return !queriedTypeIdSet.has(upc);
  });

  if (!newUpcsToQuery.length) {
    return existingItemByTag;
  }

  const newlyQueriedUpcs = await itemTypeClient.getItemTypeByIdentifiers(newUpcsToQuery, [
    "id",
    ...itemTypeQueryAttributes
  ]);

  // Process all tags based on newly retrieved UPC data
  return processItems(newTags, newlyQueriedUpcs, existingItemByTag, upcNumberOfCharactersToTrim, showNewUPCs);
};

export const processItems = (
  tagData: ScannedTag[],
  itemTypes: { identifier: string }[],
  initialData: Record<string, KioskItem>,
  upcNumberOfCharactersToTrim: number,
  showNewUPCs: boolean
) => {
  const firstSeenTimestamp = Date.now();
  return tagData.reduce((itemTypeMap, tag) => {
    const clonedMap = { ...itemTypeMap };

    const { upc, serialNumber = "", epc = "" } = tag.sgtinInfo || {};

    const upcIdentifier = upc?.slice(upcNumberOfCharactersToTrim) || "";
    const itemType = itemTypes.find((type) => {
      return type?.identifier === upcIdentifier;
    });

    // If upc doesn't exist, display just the identifier without the metadata
    if (itemType || (upcIdentifier && showNewUPCs)) {
      clonedMap[epc] = {
        id: "",
        type: itemType || { identifier: upcIdentifier, id: "" },
        vid: epc,
        firstSeenTimestamp,
        identifier: serialNumber,
        scanStatus: ITEM_SCAN_STATUS_MAP.SCANNED
      };
    }

    return clonedMap;
  }, initialData);
};
