export const VALUE_TYPE_MAP = {
  boolean: "boolean",
  dateTime: "dateTime",
  date: "date",
  status: "status",
  duration: "duration",
  comment: "comment",
  number: "number",
  imageWithText: "imageWithText",
  progressBar: "progressBar",
  text: "text"
};

export const BUTTON_HEADER_ID = "buttonHeader";
