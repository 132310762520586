import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ChartSlide from "./components/chart-slide";
import ChartSwiper from "../../../../components/chartSwiper";
import useWorkOrderTrackPageDataSourceContext from "../../contexts/work-order-track-page-data-source-context";
import useWorkOrderTrackPageStateContext from "../../contexts/work-order-track-page-state-context";
import useWorkOrderTrackPageConfigContext from "../../contexts/work-order-track-page-config-context";
import useChartBuilderFactory from "./hooks/use-chart-builder-factory";
import ChartLoadingIcon from "../../../../components/chart-loading-icon";

export const Charts = ({ slidesPerPage }) => {
  const { childrenLocationMetricMap, locationTreeMap } = useWorkOrderTrackPageDataSourceContext();
  const { isMetricsLoading, selectedLocationId } = useWorkOrderTrackPageStateContext();
  const { chartsGroup, isLoading: isConfigLoading } = useWorkOrderTrackPageConfigContext();

  const currentLocationCategory = useMemo(() => {
    return selectedLocationId ? locationTreeMap[selectedLocationId]?.category : "root";
  }, [locationTreeMap, selectedLocationId]);

  const filteredChartsGroup = useMemo(() => {
    return chartsGroup.filter(({ locationCategories }) => {
      return (
        !locationCategories || locationCategories === "all" || locationCategories.includes(currentLocationCategory)
      );
    });
  }, [chartsGroup, currentLocationCategory]);

  const { chartsList, isChartLoading } = useChartBuilderFactory(
    filteredChartsGroup,
    locationTreeMap,
    childrenLocationMetricMap,
    currentLocationCategory
  );

  const ChartSlides = useMemo(() => {
    return chartsList.map((eachChart, index) => {
      const { id, title, dataList, component: ChartComponent } = eachChart;
      return (
        <ChartSlide
          // eslint-disable-next-line react/no-array-index-key
          key={`${title}_${id}_${index}`}
          title={title}
        >
          <ChartComponent dataList={dataList} />
        </ChartSlide>
      );
    });
  }, [chartsList]);

  if (!isConfigLoading && !filteredChartsGroup.length) {
    return null;
  }

  return (
    <ChartSwiper slidesPerPage={slidesPerPage}>
      {isMetricsLoading || isChartLoading ? (
        <ChartSlide>
          <ChartLoadingIcon />
        </ChartSlide>
      ) : (
        ChartSlides
      )}
    </ChartSwiper>
  );
};

Charts.defaultProps = { slidesPerPage: 1 };

Charts.propTypes = { slidesPerPage: PropTypes.number };
