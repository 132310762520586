import { XemelgoService } from "../../../../services/XemelgoService";

type DetailsForm = {
  type?: { identifier: string };
  item_number?: string;
  sensor_profile_vid?: string;
  [key: string]: unknown;
};

export const updateAssetDetails = async (
  id: string,
  identifier: string,
  newDetailsForm: DetailsForm,
  reuseTrackerSerial = true
) => {
  const assetAppsyncClient = XemelgoService.getAppsyncClient().getAssetClient();
  const assetClient = XemelgoService.getClient().getAssetClient();

  const { type, item_number: itemNumber, sensor_profile_vid: sensorProfileVid, ...itemFields } = newDetailsForm;

  const typeIdentifier = type?.identifier || itemNumber;

  if (typeIdentifier || sensorProfileVid) {
    await assetAppsyncClient.updateAssets([
      {
        identifier,
        typeIdentifier,
        trackerSerial: sensorProfileVid,
        reuseTrackerSerial
      }
    ]);
  }

  // Make non-appsync update call second in case the item identifier has changed
  if (Object.keys(itemFields).length) {
    await assetClient.updateAsset(id, itemFields);
  }
};
