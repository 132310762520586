import React from "react";
import PropTypes from "prop-types";
import Style from "./TabBar.module.css";
import xemelgoStyle from "../../styles/variable";

export const TabBar = ({ tabList, currentTabId, onTabClick, containerClassName, labelClassName }) => {
  return (
    <div className={`${Style.tab_bar_container} ${containerClassName}`}>
      {tabList.map((tab, index) => {
        const { id, label } = tab;
        return (
          <div
            className={Style.tab_container}
            key={id}
          >
            <div>
              <button
                type="button"
                className={`${Style.tab_button}  ${xemelgoStyle.header} ${labelClassName} ${
                  currentTabId === id ? Style.selected_button : ""
                } `}
                onClick={() => {
                  onTabClick(id, index);
                }}
              >
                {label}
              </button>
              {currentTabId === id && <div className={Style.selected_line} />}
            </div>
            {index !== tabList.length - 1 && <div className={Style.separator} />}
          </div>
        );
      })}
    </div>
  );
};

TabBar.defaultProps = {
  containerClassName: "",
  labelClassName: ""
};

TabBar.propTypes = {
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  currentTabId: PropTypes.string.isRequired,
  onTabClick: PropTypes.func.isRequired
};
