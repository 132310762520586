import { useMemo } from "react";
import { getValueByType, Header } from "../../components/paginated-list-table-with-types";
import useDebounce from "../use-debounce";

const DEBOUNCE_MS = 100;
export const useFilteredTableData = <T extends Record<string, unknown>>(
  data: T[],
  headers: Header[],
  searchInput?: string,
  applySideFilterFn?: (item: T) => boolean
) => {
  const debouncedSearchInput: string = useDebounce(searchInput, DEBOUNCE_MS);

  const filterStringList = useMemo(() => {
    return debouncedSearchInput
      .toLowerCase()
      .split(" ")
      .filter((eachString) => {
        return eachString;
      });
  }, [debouncedSearchInput]);

  const { filteredData, filterApplied }: { filteredData: T[]; filterApplied: boolean } = useMemo(() => {
    let newFilterApplied = false;
    if (!debouncedSearchInput) {
      return {
        filteredData: data,
        filterApplied: newFilterApplied
      };
    }

    const filtered = data.filter((item) => {
      // Filter results by side filters
      if (applySideFilterFn && !applySideFilterFn(item)) {
        return false;
      }

      if (filterStringList.length === 0) {
        return true;
      }

      // Filter results by search input
      const displayString = headers.reduce((accumulator, header) => {
        return accumulator + getValueByType(item, header, true);
      }, "");

      return filterStringList.some((eachFilterString) => {
        return displayString.toString().toLowerCase().includes(eachFilterString);
      });
    });

    newFilterApplied = filtered.length !== data.length;

    return {
      filteredData: filtered,
      filterApplied: newFilterApplied
    };
  }, [data, debouncedSearchInput]);

  return {
    filteredData,
    filterApplied
  };
};
