import React, { useMemo } from "react";
import useAssetTrackPageConfigContext from "../../../../contexts/asset-track-page-config-context";
import useAssetTrackPageDataSourceContext from "../../../../contexts/asset-track-page-data-source-context";
import useAssetTrackPageStateContext from "../../../../contexts/asset-track-page-state-context";
import { TAB_OPTION_MAP } from "../../../../data/constants";
import GroupedAssetListTable from "../grouped-asset-list-table";

export const AssetViewTable = () => {
  const { listTableOptions } = useAssetTrackPageConfigContext();
  const { locationTreeMap, assetsLoading, assetMap, locationToAssets } = useAssetTrackPageDataSourceContext();
  const { selectedAssetIdMap, setSelectedAssetIdMap, selectedColumns, locationsToDisplay, enabledMultiSelectOptions } =
    useAssetTrackPageStateContext();

  const { pageSize = 10 } = listTableOptions[TAB_OPTION_MAP.assetTab] || {};
  const headers = selectedColumns[TAB_OPTION_MAP.assetTab] || [{ id: "identifier", label: "Asset Number" }];

  const groups = useMemo(() => {
    return locationsToDisplay.map((locationId) => {
      // Aggregate assets or asset types at a location
      let dataList = locationTreeMap[locationId].childLocations.reduce((acc, childLocationId) => {
        return acc.concat(locationToAssets[childLocationId] || []);
      }, []);

      if (selectedAssetIdMap.length !== 0) {
        dataList = dataList.map((asset) => {
          if (selectedAssetIdMap[asset.id]) {
            return {
              ...asset,
              isChecked: true
            };
          }
          return asset;
        });
      }

      return {
        id: locationId,
        dataList
      };
    });
  }, [locationsToDisplay, selectedAssetIdMap, assetMap, locationToAssets, locationTreeMap]);

  return (
    <GroupedAssetListTable
      headers={headers}
      groups={groups}
      pageSize={pageSize}
      selectable={enabledMultiSelectOptions.length > 0}
      isDataLoading={assetsLoading}
      onSelect={(ids, isSelected) => {
        const newSelectedAssetIdMap = { ...selectedAssetIdMap };
        ids.forEach((id) => {
          if (isSelected) {
            newSelectedAssetIdMap[id] = assetMap[id];
          } else {
            delete newSelectedAssetIdMap[id];
          }
        });
        setSelectedAssetIdMap(newSelectedAssetIdMap);
      }}
    />
  );
};
