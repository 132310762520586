import React, { useCallback, useMemo } from "react";
import { DEFAULT_ITEM_TABLE_HEADERS, DEFAULT_TO_ITEM_TYPE_TABLE_HEADERS } from "./data/constants";
import TransferItemsEntryTable from "./features/transfer-item-entry-table";
import Style from "./TransferOrderItemTypesTable.module.css";
import { DetailsPageTable } from "../../../details-page";
import createCsv from "../../../../components/paginated-list-table-with-types/utils/create-csv";
import { TransferOrderItemType } from "../../data/types";
import { Header, Item } from "../../../../components/paginated-list-table-with-types";

export type TODetailsTableOptions = {
  itemTypeTableHeaders?: Header[];
  itemTableHeaders?: Header[];
  showItems?: boolean;
  pageSize?: number;
  csvFileName?: string;
};

export const TransferOrderItemTypesTable = ({
  itemTypes,
  transferOrderIdentifier,
  options
}: {
  itemTypes: TransferOrderItemType[];
  transferOrderIdentifier: string;
  options: TODetailsTableOptions;
}) => {
  const {
    showItems = true,
    itemTypeTableHeaders = DEFAULT_TO_ITEM_TYPE_TABLE_HEADERS,
    itemTableHeaders = DEFAULT_ITEM_TABLE_HEADERS,
    pageSize,
    csvFileName = "Shipment"
  } = options || {};

  const itemTypeDetails: TransferOrderItemType[] = useMemo(() => {
    if (!itemTypes) {
      return [];
    }

    return itemTypes
      .filter((itemType) => {
        const {
          items: [firstItem]
        } = itemType;

        // Some container/pallet items may have the container class on the item type, some on the item
        return itemType.class !== "Container" && firstItem?.class !== "Container";
      })
      .map((itemType) => {
        const { items } = itemType;

        return {
          ...itemType,
          expandedRowComponent: () => {
            return (
              <div className={Style.nested_table_container}>
                <TransferItemsEntryTable
                  headers={itemTableHeaders}
                  items={items}
                />
              </div>
            );
          }
        };
      });
  }, [itemTypes]);

  const exportCsv = useCallback(
    (filteredData: Item[]) => {
      createCsv(filteredData, itemTypeTableHeaders, `${csvFileName} Overview - ${transferOrderIdentifier}`);

      if (showItems) {
        const items = (filteredData as TransferOrderItemType[]).flatMap((item) => {
          return item.items;
        });

        createCsv(items, itemTableHeaders, `${csvFileName} Items - ${transferOrderIdentifier}`);
      }
    },
    [options, transferOrderIdentifier]
  );

  return (
    <DetailsPageTable
      headers={itemTypeTableHeaders}
      dataList={itemTypeDetails}
      onExport={exportCsv}
      isExpandable={showItems}
      pageSize={pageSize}
      sortIdReplacementMap={{
        identifierWithImage: "identifier",
        nameWithImage: "name"
      }}
    />
  );
};
